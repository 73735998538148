import WTEntity from "../entities/WTEntity";
import Worldtrix from "../Worldtrix";
import TerrainObjects from "../data/TerrainObjects";
import Terrain from "../entities/Terrain";
import WorldObject from "../entities/WorldObject/WorldObject";
import WTEntitiesManager from "./WTEntitiesManager";

export default class WTEWorldManager {
  wt: Worldtrix;
  em: WTEntitiesManager;

  // ++++++++ Lazy access world entities ++++++++ //
  terrain: Array<Terrain> = [];
  terrainObjects: Array<TerrainObjects> = [];

  worldObjects: Array<WorldObject> = [];

  constructor(em: WTEntitiesManager) {
    this.em = em;
    this.wt = em.wt;
    //this.logw(" created")
  }

  removeTerrain(wtid: number): boolean {
    let lcheck = this.terrain.length;
    this.terrain = this.terrain.filter((t) => t.wtid !== wtid);
    this.terrainObjects = this.terrainObjects.filter(
      (obj) => obj.twtid !== wtid
    );
    if (lcheck - 1 === this.terrain.length) return true;
    console.warn(`deleteTerrain(${wtid}) unable to remove terrain`);
    return false;
  }

  removeWorldObject(wtid: number): boolean {
    let found = false;
    let index = this.worldObjects.findIndex((entity) => entity.wtid == wtid);
    if (index !== -1) {
      found = true;
      //let wObject = this.worldObjects[index]
      this.worldObjects.filter((e) => e.wtid !== wtid);
      this.em.entities = this.em.entities.filter((e) => e.wtid !== wtid);
    }
    return found;
  }

  remove(rObject: WorldObject | Terrain): boolean {
    if (rObject instanceof WorldObject) {
      return this.removeWorldObject(rObject.wtid);
    } else if (rObject instanceof Terrain) {
      return this.removeTerrain(rObject.wtid);
    }
    return false;
  }

  logl(msg?: string): void {
    if (msg) console.log("WTEWorldManager.logl(): " + msg);
    else console.log("WTEWorldManager.logl()");
  }

  logw(msg?: string): void {
    if (msg) console.info("WTEWorldManager.logw(): " + msg);
    else console.info("WTEWorldManager.logw()");
    console.info("----------------------------------");
    let count = 0;
    for (let item of this.terrain) {
      item.logWarn();
      count++;
    }
    console.info("----------------------------------");
    console.info(`count: ${count} terrains`);
    console.info("----------------------------------");
    console.info(this);
    console.info("------------------------------END-");
  }
}
