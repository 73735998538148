import Worldtrix from "../../Worldtrix";
import Inventory from "../Inventory";
import * as WT from "@/ts/wt/declaration/WTEnums";

export default class WTItem {
  static typesMappingTable: { [x: string]: WT.Inventory.Type } = {};

  type: WT.Inventory.Type;
  item: WT.Inventory.Item;
  amount: number;

  constructor(item: WT.Inventory.Item, amount: number) {
    this.item = item;
    this.amount = amount;
    this.type = this.getItemType();
  }

  merge(sourceItem: WTItem): boolean {
    if (this.item === sourceItem.item) {
      this.amount += sourceItem.amount;
      return true;
    } else {
      return false;
    }
  }

  getItemType(): WT.Inventory.Type {
    if (this.type) return this.type;
    let lookupType = WTItem.typesMappingTable[this.item];
    if (!lookupType) {
      throw new Error(`Item Type (${this.item}) does not exist`);
    }
    return lookupType;
  }

  removeAmount(amount: number): void {
    this.amount -= amount;
  }

  static initItemTypesMappingTable() {
    WTItem.typesMappingTable[WT.Inventory.Item.WATER] = WT.Inventory.Type.WATER;
    WTItem.typesMappingTable[WT.Inventory.Item.WATER_FRESH] =
      WT.Inventory.Type.WATER;
    WTItem.typesMappingTable[WT.Inventory.Item.WATER_DIRTY] =
      WT.Inventory.Type.WATER;
    WTItem.typesMappingTable[WT.Inventory.Item.MEAT] = WT.Inventory.Type.FOOD;
    WTItem.typesMappingTable[WT.Inventory.Item.NUTS] = WT.Inventory.Type.FOOD;
    WTItem.typesMappingTable[WT.Inventory.Item.BERRIES] =
      WT.Inventory.Type.FOOD;
    WTItem.typesMappingTable[WT.Inventory.Item.ANIMAL] = WT.Inventory.Type.FOOD;
    WTItem.typesMappingTable[WT.Inventory.Item.FISH] = WT.Inventory.Type.FOOD;
    WTItem.typesMappingTable[WT.Inventory.Item.FISH_TRAP] =
      WT.Inventory.Type.TOOL;
    WTItem.typesMappingTable[WT.Inventory.Item.SPEAR_WOOD] =
      WT.Inventory.Type.TOOL;
    WTItem.typesMappingTable[WT.Inventory.Item.KNIFE_BONE] =
      WT.Inventory.Type.TOOL;
    WTItem.typesMappingTable[WT.Inventory.Item.KNIFE_STONE] =
      WT.Inventory.Type.TOOL;
    WTItem.typesMappingTable[WT.Inventory.Item.BRANCHES_WOOD] =
      WT.Inventory.Type.MATERIAL;
    WTItem.typesMappingTable[WT.Inventory.Item.LEAVES] =
      WT.Inventory.Type.MATERIAL;
    WTItem.typesMappingTable[WT.Inventory.Item.GRASS_LONG] =
      WT.Inventory.Type.MATERIAL;
    WTItem.typesMappingTable[WT.Inventory.Item.BASKET] = WT.Inventory.Type.TOOL;
    WTItem.typesMappingTable[WT.Inventory.Item.FUR] =
      WT.Inventory.Type.MATERIAL;
    WTItem.typesMappingTable[WT.Inventory.Item.BONES] =
      WT.Inventory.Type.MATERIAL;
  }

  logl(msg?: string): void {
    console.log(
      `Item.logl(): ${this.constructor.name} #${this.amount} ${this.item} : ${msg}`
    );
  }

  logw(msg?: string): void {
    console.warn(
      `Item.logw(): ${this.constructor.name} #${this.amount} ${this.item} : ${msg}`
    );
  }

  logd(msg?: string, obj?: boolean): void {
    if (msg)
      console.debug(
        `Item.logd(): ${this.constructor.name} #${this.amount} ${this.item} : ${msg}`
      );
    else
      console.debug(
        `Item.logd(): ${this.constructor.name} #${this.amount} ${this.item}`
      );
    if (obj) console.debug(this);
  }
}
