import Vue from "vue";
import Router from "vue-router";

// static router-views import to gurantee availability after inital load, e. g. about section
import News from "./views/News.vue";
import About from "./views/About.vue";
import Editor from "./views/Editor.vue";
import Panorama from "./views/Panorama.vue";
import Play from "./views/Play.vue";
import Time from "./views/Time.vue";
import Debug from "./views/Debug.vue";
import NotFound from "@/views/NotFound.vue";

Vue.use(Router);

const routes = [
  {
    path: "/about",
    name: "about",
    component: About,
    alias: ["/impressum", "/about-us"],
  },
  {
    path: "/account",
    name: "account",
    component: () =>
      import(/* webpackChunkName: "licenses" */ "./views/Account.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "./views/Contact.vue"),
  },
  {
    path: "/datacookies",
    name: "datacookies",
    component: () =>
      import(/* webpackChunkName: "datacookies" */ "./views/Datacookies.vue"),
    alias: ["/data-privacy", "/cookies"],
  },

  {
    path: "/donations",
    name: "donations",
    component: () =>
      import(/* webpackChunkName: "donations" */ "./views/Donations.vue"),
  },
  {
    path: "/editor",
    name: "editor",
    component: Editor,
  },
  {
    path: "/debug",
    name: "debug",
    component: Debug,
  },
  {
    path: "/feedback",
    name: "feedback",
    component: () =>
      import(/* webpackChunkName: "feedback" */ "./views/Feedback.vue"),
  },
  {
    path: "/panorama",
    name: "panorama",
    component: Panorama,
  },
  {
    path: "/play",
    name: "play",
    component: Play,
  },
  {
    path: "/time",
    name: "time",
    component: Time,
  },
  {
    path: "/game",
    name: "game",
    component: () => import(/* webpackChunkName: "game" */ "./views/Game.vue"),
  },
  {
    path: "/scores",
    name: "scores",
    component: () =>
      import(/* webpackChunkName: "scores" */ "./views/Scores.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: News,
  },
  {
    path: "/licenses",
    name: "licenses",
    component: () =>
      import(/* webpackChunkName: "licenses" */ "./views/Licenses.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    component: () =>
      import(/* webpackChunkName: "settings" */ "./views/Settings.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: NotFound,
  },
  {
    path: "*",
    redirect: { name: "404" },
  },
  {
    path: "/",
    redirect: { name: "news" },
  },
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((routeTo, routeFrom, next) => {
  //console.log("routing from " + //routeFrom + " to " + routeTo);
  next();
});

router.afterEach(() => {
  //console.log("After each route triggered");
});

export default router;
