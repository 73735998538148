import WTEntity from "../WTEntity";
import WTEntitiesManager from "../../managers/WTEntitiesManager";
import Worldtrix from "../../Worldtrix";
import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEAbility from "../../interaction/WTEAbility";
export default class WorldObject extends WTEntity {
  woType: WT.WorldObject.Type;

  static randomInventoryBaseValue = [
    [WT.Inventory.Item.FISH, 100],
    [WT.Inventory.Item.ANIMAL, 25],
    [WT.Inventory.Item.BERRIES, 100],
    [WT.Inventory.Item.NUTS, 100],
    [WT.Inventory.Item.SPEAR_WOOD, 3],
  ];

  constructor(wt: Worldtrix, data?: any) {
    super(wt, data);

    if (data) {
      this.woType = data.woType;
      this.gModel = this.woType;
    } else {
      // create a random world Event
      let typeRoll = Math.round(Math.random() * 12);
      let inventoryRoll = (Math.random() + 0.5) / 1.5;
      switch (typeRoll) {
        case 0:
          this.woType = WT.WorldObject.Type.ABANDONED_SHELTERS;
          this.inventory.addItemAmount(
            WT.Inventory.Item.BERRIES,
            Math.round(15 * inventoryRoll)
          );
          break;
        case 1:
        case 9:
        case 10:
        case 11:
          this.woType = WT.WorldObject.Type.ANIMAL_DEN;
          this.inventory.addItemAmount(
            WT.Inventory.Item.ANIMAL,
            Math.round(15 * inventoryRoll)
          );
          break;
        case 3:
          this.woType = WT.WorldObject.Type.CAVES;
          this.inventory.addItemAmount(
            WT.Inventory.Item.NUTS,
            Math.round(15 * inventoryRoll)
          );
          this.inventory.addItemAmount(
            WT.Inventory.Item.KNIFE_STONE,
            Math.round(3 * inventoryRoll)
          );
          break;
        case 4:
          this.woType = WT.WorldObject.Type.LEDGE;
          this.inventory.addItemAmount(
            WT.Inventory.Item.NUTS,
            Math.round(15 * inventoryRoll)
          );
          this.inventory.addItemAmount(
            WT.Inventory.Item.SPEAR_WOOD,
            Math.round(3 * inventoryRoll)
          );
          break;
        case 5:
        case 7:
          this.woType = WT.WorldObject.Type.FISH_SWARM;
          this.inventory.addItemAmount(
            WT.Inventory.Item.FISH,
            Math.round(100 * inventoryRoll)
          );
          break;
        case 6:
        case 8:
        default:
          this.woType = WT.WorldObject.Type.STOCKPILE;
          this.inventory.addItemAmount(
            WT.Inventory.Item.BERRIES,
            Math.round(15 * inventoryRoll)
          );
          this.inventory.addItemAmount(
            WT.Inventory.Item.NUTS,
            Math.round(15 * inventoryRoll)
          );
          this.inventory.addItemAmount(
            WT.Inventory.Item.SPEAR_WOOD,
            Math.round(3 * inventoryRoll)
          );
          break;
      }
    }

    this.title = this.woType;
    wt.wm.worldObjects.push(this);
    //this.logw("constructed")
  }

  initAbilities(): WTEntity {
    return this;
  }

  getAbilities(): WTEAbility[] {
    return this.abilities;
  }

  checkWOType(woType: WT.WorldObject.Type) {
    if (woType === this.woType) return true;
    return false;
  }

  update(tStamp: number) {
    this.updateEntity(tStamp);
    return this;
  }
}
