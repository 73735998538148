import TaskForce from "./TaskForce";
import Worldtrix from "../../Worldtrix";
import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEntity from "../WTEntity";
import WTItem from "../../Inventory/Item/WTItem";
import TimeManager from "@/ts/wt/managers/TimeManager";
import WTEAction from "../../interaction/WTEAction";
import WGS from "../../lib/WGS";
import WTHelpers from "../../lib/WTHelpers";
import EntityAbility from "../../interaction/WTEAbility";
import WorldObject from "../WorldObject/WorldObject";
import WTValue from "../../lib/WTValue/WTValue";
import WTVLinear from "../../lib/WTValue/WTVLinear";
import Hunters from "./Hunters";
import WTEAbility from "../../interaction/WTEAbility";

export default class Fishermen extends TaskForce {
  constructor(wt: Worldtrix, fData?: any) {
    super(wt, fData);
    this.wt.em.pm.fishermen.push(this);
    //this.logw("constructed");
  }

  initAbilities(): WTEntity {
    this.addAbility(
      new WTEAbility(
        WT.Entity.Action.TASKFORCE_FISH,
        this,
        "Fish",
        "Try to catch fish from a fish swarm."
      )
    );
    return this;
  }

  getAbilities(): WTEAbility[] {
    return this.abilities;
  }

  update(tStamp: number): WTEntity {
    this.updateEntity(tStamp);
    this.updateTaskForce(tStamp);
    return this;
  }
}
