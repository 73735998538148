















































import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import Vue from "vue";

export default Vue.extend({
  components: {},
  props: {},
  data: () => ({
    demodata: "Demo data set",
  }),
  computed: {
    ...mapGetters(["wt"]),
    ...mapState({}),
    gameRunning() {
      if (this.wt.tm.running) return "Game is running";
      return "Game paused";
    },
    gameTime() {
      let value = this.wt.tm.now();
      let gHours = this.wt.tm.toHours(value);
      let days = Math.floor(gHours / 24);
      let hours = gHours - 24 * days;
      let formattedNumber = hours.toLocaleString("en-US", {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
        useGrouping: false,
      });
      return days + " days " + formattedNumber + " hours";
    },
    realPlayTime() {
      let number = this.wt.tm.now() / this.wt.tm.timeMultiplier;
      let hours = Math.floor(number / 3600);
      let minutes = Math.floor((number - 3600 * hours) / 60);
      let seconds = Math.floor(number - 3600 * hours - 60 * minutes);
      return hours + " hours " + minutes + " minutes " + seconds + " secondes";
    },
    lastSimStep() {
      return this.wt.tm.lastStepDuration() + " ms";
    },
  },
  methods: {
    ...mapActions(["awtGameStep"]),
    ...mapMutations([]),
    toggleGameRunning() {
      this.$store.dispatch("awtStartGame");
    },
  },
  created: function() {},
});
