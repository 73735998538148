import Vuex from "vuex";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import store from "@/store";

class State {
  permissions = {
    uiAccessTier: 2,
  };
}
const getters = <GetterTree<State, any>>{
  sUiAccessTier: (state) => state.permissions.uiAccessTier,
};

const actions = <ActionTree<State, any>>{};

const mutations = <MutationTree<State>>{
  mPermissionsIncreaseUiAccessTier(state) {
    state.permissions.uiAccessTier += 0.1;
  },
};

const storeAccount = {
  namespaced: false,
  modules: {},
  state: new State(),
  getters: getters,
  actions: actions,
  mutations: mutations,
};

export default storeAccount;
