





















import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import Vue from "vue";

import DetailsComposables from "@/composables/DetailsComposables";
import WorldObject from "@/ts/wt/entities/WorldObject/WorldObject";

export default Vue.extend({
  name: "DetailsObjects",
  components: {},
  props: {
    wtEntity: { type: WorldObject, required: true },
  },
  data: () => ({}),
  setup(wtEntity) {
    const { textComputed, flyTo } = DetailsComposables(
      wtEntity.wtEntity as WorldObject
    );
    console.warn(wtEntity.wtEntity);
    return { textComputed, flyTo };
  },
  computed: {
    ...mapGetters(["l10n", "settings"]),
    ...mapState({}),
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    templateFunction: function(event: any) {
      console.log("breakpoint " + event);
    },
  },
});
