




















import Vue from "vue";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

import TerrainListItem from "@/components/lists/items/TerrainListItem.vue";

export default Vue.extend({
  name: "TerrainList",
  components: {
    TerrainListItem,
  },
  props: {},
  data: () => ({
    uiItems: {
      terrain: {
        title: "Discovered world terrain",
        active: false,
      },
    },
  }),
  computed: {
    localComputed() {
      return "";
    },
    ...mapState({
      //@ts-ignore
      wtLocalization: (state) => state.l10n.wtLocalization,
    }),
    ...mapGetters(["wm", "l10n"]),
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
  },
  created: function() {
    //console.log(this)
  },
  mounted: function() {},
});
