import TimeManager from "../../managers/TimeManager";
import WTValue from "./WTValue";

export default class WTVLinear extends WTValue {
  a1: number = 1;
  iValue: number = 0;

  constructor(tStart: number, a1: number, iValue: number, tEnd?: number) {
    super(tStart, tEnd);
    this.a1 = a1;
    this.iValue = iValue;
  }

  calculateValue(tStamp: number): number {
    let fTime = this.functionTime(tStamp);
    return this.a1 * fTime + this.iValue;
  }

  calculateTimeUntil(targetValue: number, tStamp: number): number {
    this.timestampCheck(tStamp);
    let totalTime = this.calculateTimeTotal(targetValue);
    let time = tStamp - this.tStart;
    if (time < 0) {
      console.error(
        "WTVLinear.calulateTimeToValue() value request before start time"
      );
    }
    return totalTime - time;
  }

  calculateTimeTotal(targetValue: number): number {
    return (targetValue - this.iValue) / this.a1;
  }

  calucateTimestamp(targetValue: number): number {
    return this.calculateTimeTotal(targetValue) + this.tStart;
  }
}
