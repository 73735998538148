










































import CesiumViewer from "@/components/CesiumViewer.vue";
import EditorToolsPanel from "@/components/editor/elements/EditorTerrainCreation.vue";
import JsonPanel from "@/components/editor/JsonPanel.vue";
import JsonDetails from "@/components/editor/JsonDetails.vue";
import TerrainToolsPanel from "@/components/editor/TerrainToolsPanel.vue";

import { mapState, mapActions, mapMutations } from "vuex";
import Vue from "vue";

export default Vue.extend({
  name: "play",
  components: {
    CesiumViewer,

    EditorToolsPanel,
    JsonPanel,
    JsonDetails,
    TerrainToolsPanel,
  },
  data: () => ({
    target: { lat: 47.99974574, long: 10.99976256, height: 7000000 },
  }),
  computed: {
    localComputed() {
      return "Editor-Local Computed";
    },
    ...mapState({
      wtWorldData: (state: any) => state.wtWorldData,
    }),
  },
  methods: {
    ...mapActions(["aCsClearScene"]),
    ...mapMutations([
      "mCsCameraFlyToTarget",
      "mCsAddEnviToScene",
      "mCsDrawTest",
    ]),
    vCameraFlyToTargetHelper: function() {
      this.$store.commit("mCsCameraFlyToTarget", this.target);
    },
    resize() {
      let canvasElement = document.querySelector("canvas");
      if (canvasElement) {
        canvasElement.style.height = "60vh";
      }
    },
  },
  created() {
    console.log(this.localComputed);
    this.$store.dispatch("aCsInit").then(() => {
      this.$store.dispatch("aCsInitGame");
      this.$store.commit("mEditorSetIsOn", true);
    });
  },
  mounted() {},
  watch: {},
});
