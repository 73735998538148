import WTEntity from "../entities/WTEntity";
import TaskForce from "../entities/TaskForce/TaskForce";
import Tribe from "../entities/Tribe";
import Hunters from "../entities/TaskForce/Hunters";
import Worldtrix from "../Worldtrix";
import Gatherers from "../entities/TaskForce/Gatherers";
import Fishermen from "../entities/TaskForce/Fishermen";
import Scouts from "../entities/TaskForce/Scouts";
import WTEntitiesManager from "./WTEntitiesManager";

export default class WTEPlayerManager {
  wt: Worldtrix;
  em: WTEntitiesManager;

  pid: number = 42;

  // ++++++++ Lazy access player entities ++++++++ //
  tribes: Array<Tribe> = [];

  taskForces: Array<TaskForce> = [];

  hunters: Array<Hunters> = [];
  gatherers: Array<Gatherers> = [];
  fishermen: Array<Fishermen> = [];
  scouts: Array<Scouts> = [];

  constructor(em: WTEntitiesManager) {
    this.em = em;
    this.wt = em.wt;
    //this.logw(" created")
  }

  add(entity: WTEntity) {
    if (entity instanceof Tribe) {
      this.tribes.push(entity);
    } else if (entity instanceof Hunters) {
      this.hunters.push(entity);
      this.taskForces.push(entity);
    } else if (entity instanceof Gatherers) {
      this.gatherers.push(entity);
      this.taskForces.push(entity);
    } else if (entity instanceof Fishermen) {
      this.fishermen.push(entity);
      this.taskForces.push(entity);
    } else if (entity instanceof Scouts) {
      this.scouts.push(entity);
      this.taskForces.push(entity);
    } else throw new Error("Entity type not matching any class");
    this.em.entities.push(entity);
  }

  removeTaskForce(wtid: number): boolean {
    let length = this.taskForces.length;
    this.taskForces = this.taskForces.filter((tf) => tf.wtid !== wtid);
    if (length - 1 === this.taskForces.length) return true;
    console.warn(`deleteTerrain(${wtid}) did not remove TaskForce`);
    return false;
  }

  logl(msg?: string): void {
    if (msg) console.log("WTEPlayersManager.logl(): " + msg);
    else console.log("WTEPlayersManager.logl()");
  }

  logw(msg?: string): void {
    if (msg) console.info("WTEPlayersManager.logw(): " + msg);
    else console.info("WTEPlayersManager.logw()");
    console.info("----------------------------------");
    let count = 0;
    for (let item of this.em.entities) {
      item.logWarn();
      count++;
    }
    console.info("----------------------------------");
    console.info(`count: ${count} em.entities`);
    console.info("----------------------------------");
    console.info(this);
    console.info("-------------------------------END-");
  }
}
