import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEAbility from "../../WTEAbility";
import WTEAction from "../../WTEAction";
import WTEAInput from "../../WTEAInput";
import WTHelpers from "../../../lib/WTHelpers";
import WTVLinear from "@/ts/wt/lib/WTValue/WTVLinear";
import Population from "@/ts/wt/entities/Population/Population";
import Tribe from "@/ts/wt/entities/Tribe";
import TimeManager from "@/ts/wt/managers/TimeManager";
import WTValue from "@/ts/wt/lib/WTValue/WTValue";

export default class WTEALeaveSettlement extends WTEAction {
  type: WT.Entity.Action;
  population!: Population;
  tribe!: Tribe;

  BASE_DURATION = 2 * TimeManager.UNITS.HOUR;
  packUpA1 = -1;
  packUpWTValue!: WTValue;

  constructor(ability: WTEAbility, input?: WTEAInput) {
    super(ability, input);
    this.type = WT.Entity.Action.TRIBE_SETTLE_DOWN;
  }

  // ------------------------ START action functions ------------------------ \\
  init(input: WTEAInput): WTEAction {
    this.superInit();
    // assigne potential new input in case of re-init call
    this.input = input;
    this.tribe = this.entity as Tribe;
    this.population = this.entity.population;
    return this;
  }

  initPreConditions(): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  listPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  checkPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    let failedConds: Array<WT.Interaction.PreCondtionType> = [];
    // do additional pre condition checks here
    failedConds = failedConds.concat(
      this.tribe.checkState(WT.Tribe.State.SETTELED_TRIBE)
    );
    return failedConds;
  }

  start(): boolean {
    let preCondditions = this.checkPreConditions();
    if (!WTHelpers.checkPreConditions(preCondditions)) {
      WTHelpers.preConditionsToLog(preCondditions);
      return false;
    } else {
      this.updateAttributes(this.input);
      this.packUpWTValue = new WTVLinear(this.tm.now(), this.packUpA1, 0);
      this.timeBoundariesSet(this.isDoneIn());
      console.log("aPackUpStart()");
      return true;
    }
  }

  update(tStamp: number): WTEAction {
    if (this.isDoneIn() <= 0) {
      this.tribe.changeState(WT.Tribe.State.WANDERING_TRIBE);
      this.finish();
    } else {
      // other updates
    }
    return this;
  }

  updateAttributes(input: WTEAInput): WTEAction {
    this.packUpA1 = 1 / this.BASE_DURATION;
    return this;
  }

  isDoneIn(): number {
    let t = this.tm.now();
    let rt = this.packUpWTValue.calculateTimeUntil(1, t);
    console.log("aPackUpDoneIn: " + TimeManager.toHours(rt) + " hours");
    return rt;
  }

  finish(): boolean {
    this.superFinish();
    // TODO: [WT-171] WTEALeaveSettlement - Create matching WorldObject after leaving a settlement
    console.warn("Tribe.leaveSettlement().finish(), wtid:" + this.entity.wtid);
    return this.finished;
  }

  cancel(): boolean {
    this.superCancel();
    throw new Error("Method not implemented.");
    return this.canceled;
  }

  // ---------------------------- action helpers ---------------------------- \\

  // ------------------------------ wt helpers ------------------------------ \\
}
