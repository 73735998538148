

































import Vue from "vue";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

import ListItemComposables from "@/composables/ListItemComposables";
import Tribe from "@/ts/wt/entities/Tribe";

export default Vue.extend({
  name: "TribeListItem",
  components: {},
  props: {
    wtTribe: { type: Tribe, required: true },
  },
  data: () => ({}),
  setup(wtTribe) {
    const { textComputed, iClick, iDoubleClick } = ListItemComposables(
      wtTribe.wtTribe as Tribe
    );
    return { textComputed, iClick, iDoubleClick };
  },
  computed: {
    ...mapState({}),
    ...mapGetters(["l10n", "settings"]),
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    emptyFunction(wtid: number) {
      console.log("click: " + wtid);
    },
  },
  created: function() {
    //console.log(this)
  },
  mounted: function() {},
});
