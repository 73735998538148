import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEAbility from "../../WTEAbility";
import WTEAction from "../../WTEAction";
import WTEAInput from "../../WTEAInput";
import WTHelpers from "../../../lib/WTHelpers";
import TaskForce from "@/ts/wt/entities/TaskForce/TaskForce";
import Inventory from "@/ts/wt/Inventory/Inventory";
import TimeManager from "@/ts/wt/managers/TimeManager";
import WTValue from "@/ts/wt/lib/WTValue/WTValue";
import WorldObject from "@/ts/wt/entities/WorldObject/WorldObject";
import WGS from "@/ts/wt/lib/WGS";
import WTVLinear from "@/ts/wt/lib/WTValue/WTVLinear";

export default class WTEARaidAnimalDen extends WTEAction {
  type: WT.Entity.Action;

  taskForce!: TaskForce;
  inventory!: Inventory;
  woTarget!: WorldObject;

  static raidAnimalDenWGSRange = 0.0005;
  static raidAnimalDenDurationValue = 1.2 / TimeManager.UNITS.HOUR;

  raidAnimalDenA1 = -1;
  raidAnimalDenWTValue!: WTValue;
  raidAnimalDenTargetValue = 1;
  raidAnimalDenSpeed = 0.005;

  constructor(ability: WTEAbility, input?: WTEAInput) {
    super(ability, input);
    this.type = WT.Entity.Action.BASIC_MOVE;
  }

  // ------------------------ START action functions ------------------------ \\
  init(input: WTEAInput): WTEAction {
    this.superInit();
    // assigne potential new input in case of re-init call
    this.input = input;
    this.taskForce = this.input.entity as TaskForce;
    this.inventory = this.input.entity.inventory;
    this.woTarget = this.input.tEntity as WorldObject;
    throw new Error("Method not implemented.");
  }

  initPreConditions(): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  listPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  checkPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    let failedConds: Array<WT.Interaction.PreCondtionType> = [];
    if (this.woTarget.woType !== WT.WorldObject.Type.ANIMAL_DEN) {
      failedConds.push(WT.Interaction.PreCondtionType.WORLDOBJECT_TYPE_WRONG);
    }
    if (this.inventory.checkIsFull()) {
      failedConds.push(WT.Interaction.PreCondtionType.INVENTORY_FULL);
    }
    WGS.checkMaxDistance(
      failedConds,
      this.entity,
      this.woTarget,
      WTEARaidAnimalDen.raidAnimalDenWGSRange
    );
    return failedConds;
  }

  start(): boolean {
    let preCondditions = this.checkPreConditions();
    if (!WTHelpers.checkPreConditions(preCondditions)) {
      WTHelpers.preConditionsToLog(preCondditions);
      return false;
    } else {
      this.updateAttributes(this.input);
      this.raidAnimalDenTargetValue = this.inventory.getFreeSpace();
      if (this.input.targetAmount)
        this.raidAnimalDenTargetValue = this.input.targetAmount;
      this.raidAnimalDenWTValue = new WTVLinear(
        this.tm.now(),
        this.raidAnimalDenA1,
        0,
        this.raidAnimalDenTargetValue
      );
      this.timeBoundariesSet(this.isDoneIn());
      console.log(
        "WTEARaidAnimalDen.start() for free Space " +
          this.inventory.getFreeSpace()
      );
      return true;
    }
  }

  update(tStamp: number): WTEAction {
    if (this.isDoneIn() <= 0) {
      this.finish();
    } else {
      // other updates
    }
    return this;
  }

  updateAttributes(input: WTEAInput): WTEAction {
    let popSize = this.entity.population.size();
    this.raidAnimalDenA1 =
      WTEARaidAnimalDen.raidAnimalDenDurationValue * popSize;
    let spears = this.inventory.getExistingItem(WT.Inventory.Item.SPEAR_WOOD);
    if (spears)
      this.raidAnimalDenA1 +=
        0.5 *
        WTEARaidAnimalDen.raidAnimalDenDurationValue *
        Math.min(this.entity.population.size(), spears.amount);
    return this;
  }

  isDoneIn(): number {
    let t = this.tm.now();
    let rt = this.raidAnimalDenWTValue.calculateTimeUntil(
      this.raidAnimalDenTargetValue,
      t
    );
    console.log("aRaidAnimalDenDoneIn: " + TimeManager.toHours(rt) + " hours");
    return rt;
  }

  finish(): boolean {
    this.superFinish();
    return this.finished;
  }

  cancel(): boolean {
    this.superCancel();
    return this.canceled;
  }

  // ---------------------------- action helpers ---------------------------- \\

  // ------------------------------ wt helpers ------------------------------ \\
}
