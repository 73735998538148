import Population from "../Population/Population";
import WTEMoveable from "../WTEMoveable";
import Worldtrix from "../../Worldtrix";
import * as WT from "@/ts/wt/declaration/WTEnums";
import Tribe from "../Tribe";
import WTEAction from "../../interaction/WTEAction";
import WTHelpers from "../../lib/WTHelpers";
import WGS from "../../lib/WGS";
import WTEAbility from "../../interaction/WTEAbility";

export default abstract class TaskForce extends WTEMoveable {
  tribe!: Tribe;

  protected constructor(wt: Worldtrix, tfData?: any) {
    super(wt, tfData);
    if (tfData) {
      this.population = new Population(this, tfData.population);
    } else {
      this.population = new Population(this);
    }
    this.initAbilitiesTaskForce();
    this.wt.em.pm.taskForces.push(this);

    if (tfData && tfData.tribe) {
      this.tribe = tfData.tribe as Tribe;
    }
  }

  initAbilitiesTaskForce() {
    this.addAbility(
      new WTEAbility(
        WT.Entity.Action.TASKFORCE_JOIN_TRIBE,
        this,
        "Join tribe",
        "Joins a tribe including all population and items."
      )
    );
  }

  protected updateTaskForce(tStamp: number): TaskForce {
    this.updateMoveable(tStamp);
    return this;
  }
}
