import Vuex from "vuex";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import store from "@/store";

// Server APIs
import serverAPI from "@/../api/serverAPI";
import wtDataURIs from "@/../api/wtDataURIs";

type RootState = typeof store.state;
class State {
  wtWorldData = {
    wtWorldTerrain: {
      wtWorldFeatureCollections: [],
    },
    wtWorldTerrainObjects: {
      objectsDataArray: [],
    },
    wtWorldObjects: {
      objects: [],
    },
  };

  wtPlayerData = {
    wtpObjects: {
      tribes: [],
      taskForces: [],
    },
    wtTechnology: {},
  };
}

const getters = <GetterTree<State, any>>{
  getWorldRessourcesArray: (state) => {
    //console.log("Ressource Getter called");
    let resources: any = [];
    //insert technology dependancy here in the getter
    for (const fCollection of state.wtWorldData.wtWorldTerrain
      .wtWorldFeatureCollections) {
      for (const terrain of (fCollection as any).features) {
        //console.log(terrainID)
        const tRes = state.wtWorldData.wtWorldRessources.wtidRessources[
          terrain.wtid
        ] as any;
        if (typeof tRes != "undefined") {
          //console.log(tRes)
          resources = resources.concat(tRes.rTypes);
        }
      }
    }
    return resources;
  },
};

const actions = <ActionTree<State, any>>{
  aVuexLoadMinGameStore({ commit }) {
    serverAPI.getwtPlayerData((wtpData: any) => {
      commit("mInitSetPlayerData", wtpData);
    });
    serverAPI.getwtWorldData((wtWorldData: any) => {
      commit("mInitSetWorldDataObject", wtWorldData);
    });
    serverAPI.getwtLocalization((wtLocalization: any) => {
      commit("mInitSetLocalizationData", wtLocalization);
    });
    commit(
      "mInitSetgWorldTerrainObjectsTypes",
      wtDataURIs.getgWorldTerrainObjectsTypes()
    );
    //console.log("keyboardflags:" + csStore.WASDFlags)
  },
};

const mutations = <MutationTree<State>>{
  mLoadTescht(state, payload) {
    //console.log("Store test is working...")
    //worldtrix.startTime()
    //worldtrix.valueCalculation()
    state.wtWorldData = payload;
    console.log("mSetData: " + state.wtWorldData);
  },

  // ------------------------ Initial loading ------------------------ //
  mInitSetPlayerData(state, wtpData) {
    //console.log(wtpData)
    state.wtPlayerData = wtpData;
    store.state.wt.wt.addJson(wtpData);
    store.state.wt.wt.em.postLoadDataInit();
  },

  mInitSetWorldDataObject(state, wtWorldDataObject) {
    //console.log(wtWorldDataObject)
    state.wtWorldData = wtWorldDataObject;
    store.state.wt.wt.addJson(wtWorldDataObject);
  },

  mInitSetgWorldTerrainObjectsTypes(state, gWorldTerrainObjects) {
    //console.log(gWorldObjects)
    store.state.gfx.gWorldTerrainObjectTypes = gWorldTerrainObjects;
  },

  mInitSetLocalizationData(state, wtLocalization) {
    //console.log(wtLocalization)
    store.state.l10n.wtLocalization = wtLocalization.wtLocalization;
  },
};

const storeDataLoad = {
  namespaced: false,
  modules: {},
  state: new State(),
  getters: getters,
  actions: actions,
  mutations: mutations,
};

export default storeDataLoad;
