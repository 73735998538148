import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEntity from "../entities/WTEntity";
import WTEAInput from "../interaction/WTEAInput";
import WTTarget from "../interaction/WTTarget";

export default class WGS {
  static distance(wgs1: Array<number>, wgs2: Array<number>): number {
    let a2 = wgs1[0] - wgs2[0];
    a2 = a2 * a2;
    let b2 = wgs1[1] - wgs2[1];
    b2 = b2 * b2;
    return Math.sqrt(a2 + b2);
  }

  static checkMaxDistance(
    failedConds: Array<WT.Interaction.PreCondtionType>,
    e1: WTEntity | WTTarget,
    e2: WTEntity | WTTarget,
    maxWGSDistance: number
  ): boolean {
    if (WGS.distance(e1.wgs, e2.wgs) > maxWGSDistance) {
      failedConds.push(WT.Interaction.PreCondtionType.WGS_DISTANCE);
      return false;
    }
    return true;
  }

  static checkMaxDistanceForInput(
    failedConds: Array<WT.Interaction.PreCondtionType>,
    input: WTEAInput,
    maxWGSDistance: number
  ): boolean {
    let entity = input.entity;
    let tEntity = input.tEntity;
    if (input.target && input.target.tEntity) {
      tEntity = input.target.tEntity;
    }
    if (!entity || !tEntity) {
      throw new Error("WGS.CheckDistanceForInput Case");
    }
    if (WGS.distance(entity.wgs, tEntity.wgs) <= maxWGSDistance) {
      return true;
    }
    failedConds.push(WT.Interaction.PreCondtionType.WGS_DISTANCE);
    return false;
  }
}
