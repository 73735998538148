import Worldtrix from "../Worldtrix";
import Inventory from "../Inventory/Inventory";
import WTEAbility from "../interaction/WTEAbility";
import WTEAction from "../interaction/WTEAction";
import Population from "./Population/Population";
import * as WT from "@/ts/wt/declaration/WTEnums";
import WGS from "../lib/WGS";
import WTHelpers from "../lib/WTHelpers";
import TimeManager from "../managers/TimeManager";
import WTTarget from "../interaction/WTTarget";
import WTEAInput from "../interaction/WTEAInput";
import WTEMoveable from "./WTEMoveable";

export default abstract class WTEntity {
  // entity
  readonly wtid: number;
  wt: Worldtrix;
  title: string = "unnamed";
  text: string = "unnamed";
  ownerID?: number = -1;
  wgs: [number, number, number] = [-666, -666, -666];
  type: string = "WTEntity";
  parent?: WTEntity;

  //time
  updatedTime: number;
  updatedTimePrevious: number;
  updatedTimeDelta: number = 0;

  inventory: Inventory;
  population!: Population;

  // action handling
  abilities: Array<WTEAbility> = [];
  abilitiesCounter: number = 0;
  actions: Array<WTEAction> = [];
  // TODO
  actionIsActive = false;
  actionAutoStart = Worldtrix.INIT_VALUES_PRODUCTION;

  //gfx
  gModel: string = "GFX_CURSOR_360DEG_1S";
  gScale: number = 1;

  static readonly Attributes = {
    ItemExchange: {
      DURATION_BASE: 0,
    },
    FoodConsume: {
      DURATION: 10 * TimeManager.UNITS.MINUTE,
      DISTANCE_MAX: 0.005,
    },
  };

  constructor(wt: Worldtrix, data?: any) {
    this.wt = wt;
    this.type = this.constructor.name;
    //console.warn("New WTEntity by new " + this.constructor.name + "()");
    // building the basis identity without additional stuff
    // things like name should be done in the constructor of the target entity to guarantee the naming
    if (data) {
      // build from JSON data
      let eData = data;
      if (data.entity) {
        // get entity data in case of for Terrain geoJsonFeatures
        eData = data.entity;
      }
      if (eData && eData.wtid) {
        this.wtid = eData.wtid;
      } else {
        this.wtid = wt.em.wtidNew();
      }
      wt.em.add(this);

      if (eData && eData.wgs) this.wgs = eData.wgs;

      if (eData && eData.ownerID) this.ownerID = eData.ownerID;

      if (eData && eData.title) {
        this.title = eData.title;
        this.text = this.wtid + ": " + this.title;
      }

      if (eData && eData.inventory) {
        this.inventory = new Inventory(this, eData.inventory);
      } else {
        this.inventory = new Inventory(this);
      }

      if (eData && eData.updated) {
        this.updatedTime = eData.updated;
        this.updatedTimePrevious = eData.updated;
      } else {
        this.updatedTime = wt.tm.now();
        this.updatedTimePrevious = wt.tm.now();
      }
    } else {
      this.wtid = wt.em.wtidNew();
      this.updatedTime = wt.tm.now();
      this.updatedTimePrevious = wt.tm.now();
      this.inventory = new Inventory(this);
    }
    this.initAbilities();
    this.updateTimeHelper();
  }

  //////// Entity Update Functions \\\\\\\\
  abstract initAbilities(): WTEntity;
  abstract getAbilities(): Array<WTEAbility>;
  abstract update(tStamp: number): WTEntity;

  protected updateTimeHelper(): number {
    this.updatedTimePrevious = this.updatedTime;
    this.updatedTime = this.wt.tm.now();
    this.updatedTimeDelta = this.updatedTime - this.updatedTimePrevious;
    return this.updatedTimeDelta;
  }

  protected updateEntity(tStamp: number): WTEntity {
    //console.log("Entity udpated()")
    this.updateTimeHelper();

    if (this.population) {
      this.population.update();
      // this.log(
      //   this.wtid + ": food required " + this.population.foodForSaturation()
      // );
      // console.log(
      //   `Food-Check (${
      //     this.population.size
      //   }): For saturation: ${this.population.foodForSaturation(
      //     false
      //   )} MAX: ${this.population.foodForSaturation(
      //     true
      //   )}  Remaining food: ${Inventory.sumItemAmount(
      //     this.inventory.getItemsOfType(WT.Inventory.Type.FOOD)
      //   )}`
      // );
    }

    this.updateActions(tStamp);

    // DEPRECATED -> replaced by
    // if (this.itemExchangeActive) this.aItemExchangeUpdate(tStamp);
    // if (this.foodConsumeActive) this.aFoodConsumeUpdate(tStamp);
    return this;
  }

  //-------------------  Entity actions -------------------\\

  protected updateActions(tStamp: number): WTEntity {
    let currentAction = this.getCurrentAction();
    // start new action if no action is running and auto start is true
    if (!this.actionIsActive && this.actionAutoStart && currentAction) {
      let success = this.startNextAction();
      if (success) currentAction = this.getCurrentAction();
      else return this;
    }
    // update run
    if (currentAction && this.actionIsActive) {
      currentAction.update(tStamp);
    } else if (currentAction && this.actionAutoStart) {
      this.startNextAction();
      this.logError(
        "Action available and autostart == true, but no simulation is running - Something is very very wrong..."
      );
    }
    return this;
  }

  getCurrentAction(): WTEAction | undefined {
    return this.actions[0];
  }

  /**
   * Save function to start new actions if no action is runing and a action to start exists.
   *
   * @returns true if a NEW action was started.
   */
  startNextAction(): boolean {
    console.debug(`${this.wtid}: nextAction()`);
    if (this.actions.length > 0) {
      let action = this.actions[0];
      if (action.isDone()) {
        let oldAction = this.actions.shift();
        if (this.actions.length > 0) {
          if (this.actionAutoStart) {
            let sucess = this.actions[0].start();
            if (sucess) this.actionIsActive = true;
            return true;
          }
        }
        return false;
      } else {
        //start first action
        let sucess = this.actions[0].start();
        if (sucess) this.actionIsActive = true;
        return true;
      }
    } else {
      console.debug(
        `${this.wtid}.startNextAction() -> false: No actions to start`
      );
      return false;
    }
  }

  /**
   *
   * Creates an action if the action is available in the entites abilites list.
   *
   * @param actionType
   * @param input
   */
  createAction(actionType: WT.Entity.Action, input: WTEAInput): WTEAction {
    let ablility = this.abilities.filter(
      (ability) => ability.actionType === actionType
    );
    if (ablility.length === 1) {
      return ablility[0].createAction(input);
    } else {
      throw new Error(
        `createAction(${actionType}): Ability not found on ${this.toString}`
      );
    }
  }

  /********************* other functions  & helpers ************************/

  addAbility(ability: WTEAbility): number {
    ability.index = this.abilitiesCounter++;
    this.abilities.push(ability);
    return this.abilitiesCounter;
  }

  getAbilityByIndex(index: number): WTEAbility {
    if (index >= this.abilitiesCounter || index < 0)
      throw new Error("Ability with this index not avaialble!");
    return this.abilities[index];
  }

  setWGS(wgs: number[]): WTEntity {
    this.wgs[1] = wgs[1];
    this.wgs[2] = wgs[2];
    this.wgs[0] = wgs[0];
    return this;
  }

  getWTTarget(): WTTarget {
    return new WTTarget(this.wgs, this);
  }

  getWGSString(): String {
    let digits = 10;
    return `[${this.wgs[0]
      .toString()
      .slice(0, digits)}, ${this.wgs[1].toString().slice(0, digits)}]`;
  }

  /****************************** wt-helpers ******************************/

  toString(): String {
    return (
      this.wtid +
      ", " +
      this.constructor.name +
      ", [" +
      this.wgs.toString() +
      "]"
    );
  }

  toStringDebug(): String {
    return `${this.toString()}, abilities[${this.abilities.length}], actions[${
      this.actions.length
    }]`;
  }

  log(msg?: string): void {
    console.log(
      `Entity.log(): ${this.constructor.name}(title=${this.title}) ${this.wtid} : ${msg}`
    );
  }

  logWarn(msg?: string): void {
    console.warn(
      `Entity.logWarn(): ${this.constructor.name}(title=${this.title}) ${this.wtid} : ${msg}`
    );
    console.warn(this);
  }

  logError(msg?: string): void {
    console.warn(
      `Entity.logError(): ${this.constructor.name}(title=${this.title}) ${this.wtid} : ${msg}`
    );
    console.warn(this);
  }

  logDebug(msg?: string): void {
    console.warn(
      `Entity.logDebug(): ${this.constructor.name}(title=${this.title}) ${this.wtid} : ${msg}`
    );
    console.warn(this);
  }
}
