




































































































import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import Vue from "vue";

import WGSInput from "@/components/elements/WGSInput.vue";
import Worldtrix from "@/ts/wt/Worldtrix";

export default Vue.extend({
  name: "InteractionPanel",
  components: { WGSInput },
  props: {},
  data: () => ({
    ok: true,
    testingData: 22,
    buttonsToggle: {
      singleSelection: 1,
      multipleToggle: [1, 2],
    },
    items: [
      { title: "Click Me1" },
      { title: "Click Me2" },
      { title: "Click Me3" },
      { title: "Click Me4" },
    ],
    target: {
      long: 11.0,
      lat: 48,
      height: 1500,
    },
    buttonsState: {
      singleSelection: 1,
      multipleToggle: [1, 2],
      polyDrawActive: false,
      polyDrawSelection: 1,
    },
    traget: {
      selection: 0,
    },
    entity: {
      selection: 0,
    },
    tcItems: [
      {
        index: 0,
        text: "Forest Mixed",
        type: "GFX_TEXTURE_FOREST_MIXED_1",
        callback: () => {
          this.cameraFlyToUnit();
        },
      },
      {
        index: 1,
        text: "Forest Coniferous",
        type: "GFX_TEXTURE_FOREST_CONIFEROUS_1",
        callback: () => {
          this.cameraFlyToUnit();
        },
      },
    ],
  }),
  computed: {
    ...mapState({}),
    ...mapGetters({
      gameEngineData: "gameEngineData",
      worldtrix: "wt",
      em: "em",
    }),
  },
  methods: {
    ...mapActions([
      "awtGameStep",
      "awtStartGame",
      "aEngineInjectDevActions",
      "aEngineStartAllEntityActions",
      "aEngineSetEntityActionAutostart",
    ]),
    ...mapMutations([""]),
    cameraFlyToUnit: function() {
      let target = (this.worldtrix as Worldtrix).em.get(888)?.wgs;
      this.$store.dispatch("aCsCameraFlyToLongLat", target);
    },

    cameraFlyToTarget: function() {
      let target = (this.worldtrix as Worldtrix).em.get(8675)?.wgs;
      this.$store.dispatch("aCsCameraFlyToLongLat", target);
    },
    cesiumRefreshHelper: function() {
      this.buttonsState.polyDrawActive = false;
      this.$store.dispatch("aCsInitRefresh");
    },
    templateFunction: function(event: any) {
      console.log("breakpoint " + event);
    },
  },

  created: function() {
    //
  },
});
