import TimeManager from "./managers/TimeManager";
import WTEntitiesManager from "./managers/WTEntitiesManager";
import WTJsonFactory from "./WTJsonFactory";
import WTEWorldManager from "./managers/WTEWorldManager";
import WTTarget from "./interaction/WTTarget";
import * as WT from "@/ts/wt/declaration/WTEnums";
import WTItem from "./Inventory/Item/WTItem";
import WTEAction from "./interaction/WTEAction";
import Human from "./entities/Population/Human";
import WTEPlayersManager from "./managers/WTEPlayerManager";
import Population from "./entities/Population/Population";
import WTInteractionManager from "./managers/InteractionManager";
import WTEAInput from "./interaction/WTEAInput";
import Entity from "cesium/Source/DataSources/Entity";

export default class Worldtrix {
  // game data
  tm: TimeManager;
  em: WTEntitiesManager;

  pm: WTEPlayersManager;
  wm: WTEWorldManager;
  im: WTInteractionManager;

  aHuman: Human = new Human(this, 22);
  cHuman: Human = new Human(this, 10);
  bHuman: Human = new Human(this, 2);

  static devSpeedMul = 1;
  static devSpeedDiv = 1 / Worldtrix.devSpeedMul;
  static INIT_VALUES_PRODUCTION = false;

  constructor() {
    this.tm = new TimeManager();
    this.em = new WTEntitiesManager(this);
    this.im = new WTInteractionManager(this);
    WTItem.initItemTypesMappingTable();
    this.pm = this.em.pm;
    this.wm = this.em.wm;
  }

  injectCounter: number = 0;

  //------------------getters--------------------/

  timeIsRunning(): boolean {
    return this.tm.running;
  }

  //------------------functions------------------/

  injectDevActions() {
    console.log("WT: Action Injection!");
    const fishermen = this.em.pm.fishermen[0];
    const gatherers = this.em.pm.gatherers[0];
    const itemList = [WT.Inventory.Item.BERRIES, WT.Inventory.Item.NUTS];
    const hunters = this.em.pm.hunters[0];
    const scouts = this.em.pm.scouts[0];

    const caves = this.wm.worldObjects.filter(
      (wo) => wo.woType === WT.WorldObject.Type.CAVES
    )[0];
    const fishSwarm = this.wm.worldObjects.filter((obj) => obj.wtid == 888)[0];

    const woStockpile = this.wm.worldObjects.filter(
      (obj) => obj.wtid == 8111
    )[0];
    const woAnimalDen = this.wm.worldObjects.filter(
      (obj) => obj.wtid == 9990
    )[0];

    const tribe = this.em.pm.tribes[0];
    const tribeTarget = new WTTarget(tribe.wgs, tribe);
    const tribeInput = new WTEAInput().setToActionQueue();
    tribeInput.entity = tribeInput.tEntity = tribe;
    tribeInput.maxSaturation = true;
    tribeInput.loopAction = true;

    const dynTarget = new WTTarget([
      hunters.wgs[0] + 0.0,
      hunters.wgs[1] + 0.01,
      hunters.wgs[2] + 0.0,
    ]);
    const moveTarget1 = new WTTarget([11.0, 48.0, 0]);
    const moveTarget2 = new WTTarget([12, 49.0, 0]);
    const finalDestination = new WTTarget([42, 42, 0]);

    const moveInput1 = new WTEAInput().setToActionQueue();
    moveInput1.target = moveTarget1;
    const moveInput2 = new WTEAInput().setToActionQueue();
    moveInput2.target = moveTarget2;
    const dynInput = new WTEAInput().setToActionQueue();
    dynInput.target = dynTarget;

    const finalInput = new WTEAInput();
    finalInput.target = finalDestination;
    finalInput.loopAction = true;
    finalInput.pushActionToQueue = true;

    dynInput.target = new WTTarget(tribe.wgs, tribe);
    dynInput.itemType = WT.Inventory.Item.BERRIES;
    dynInput.targetAmount = 50;

    tribe.createAction(WT.Entity.Action.BASIC_FOOD_CONSUME, tribeInput);
    //scouts.createAction(WT.Entity.Action.BASIC_ITEM_EXCHANGE, dynInput);

    hunters.createAction(WT.Entity.Action.BASIC_MOVE, finalInput);
    hunters.createAction(WT.Entity.Action.TASKFORCE_HUNT_WILDLIFE, moveInput2);
    //hunters.createAction(WT.Entity.Action.BASIC_MOVE, moveInput1);
    //hunters.createAction(WT.Entity.Action.BASIC_MOVE, moveInput2);
    //hunters.createAction(WT.Entity.Action.BASIC_MOVE, moveInput2);

    //tribe.population.logSaturation()
    const oldPop = tribe.population;
    let newPop: Population;

    console.debug(
      "-----" +
        (this.injectCounter % 3) +
        ". injection Loop triggered - counter: " +
        this.injectCounter +
        "-----"
    );
    if (this.injectCounter % 3 == 0) {
      //console.warn("------------ Inject-countner 0 ------------");
    }

    if (this.injectCounter % 3 == 1) {
      //console.warn("------------ Inject-countner 1 ------------");
    }

    if (this.injectCounter++ % 3 == 2) {
      //console.warn("------------ Inject-countner 2 ------------");
    }
  }

  startAllEntityActionsDev() {
    for (let entity of this.em.entities) {
      entity.startNextAction();
    }
  }

  setAllEntititiesActionAutostartDev(value: boolean) {
    for (let entity of this.em.entities) {
      entity.actionAutoStart = value;
    }
  }

  // ------------------------ functions ------------------------ \\

  timeStart() {
    this.tm.start();
    console.log(`Worldtrix.startTime() ${this.tm.now()}`);
    // this loop is not allowed because of modification outside of mutations
    // Javascript context magic - https://stackoverflow.com/questions/2749244/javascript-setinterval-and-this-solution
    //this.simHandlerNumber = setInterval(this.valueCalculation, stepSize)
    // this.simHandlerNumber = setInterval(
    //     (function (self) {
    //         return function () {
    //             self.valueCalculation();
    //         }
    //     })(this),
    //     stepSize
    // );

    //commit("mVuexGameEngineLoopStatus", true)
    console.log("WT: value simulation started...");
  }

  timeStop() {
    this.tm.stop();
    //clearInterval(this.simHandlerNumber);
    console.log(
      `Worldtrix.stopTime() ${this.tm.now() / TimeManager.UNITS.HOUR} h`
    );
  }

  valueCalculation(delta?: number) {
    //console.log("WT: Doing fancy calculations!")
    this.tm.update(delta);
    this.em.updateAllEntities();

    //this.wtRandom.roll(this.tm.now())

    // this.aHuman.update(100)
    // this.bHuman.update(100)
    // this.cHuman.update(100)

    //this.em.fishermen[0].update()
    //this.em.tribes[0].update()
  }

  addJson(jsonObj: any) {
    // select the right functions based on wtObject type
    switch (jsonObj.wtObj) {
      case "wtWorldRessources":
        break;
      case "wtWorldData":
        //this.logw("wtWorldData JSON added")
        WTJsonFactory.buildWorldTerrain(this, jsonObj.wtWorldTerrain);
        WTJsonFactory.buildWorldTerrainObjects(
          this,
          jsonObj.wtWorldTerrainObjects
        );
        WTJsonFactory.buildWorldObjects(this, jsonObj.wtWorldObjects);
        break;
      case "wtPlayerData":
        //this.logw("wtPlayerData JSON added")
        WTJsonFactory.buildPlayerEntities(this, jsonObj.wtpObjects);
        break;
      default:
        throw new Error("wtObj type unkown");
        break;
    }
  }

  logl(msg?: string): void {
    console.log(`Worldtrix.logl(): ${msg}`);
  }

  logw(msg?: string): void {
    console.warn(`Worldtrix.logw(): ${msg}`);
  }
}

declare global {
  interface Array<T> {
    remove(elem: T): Array<T>;
  }
}

if (!Array.prototype.remove) {
  Array.prototype.remove = function<T>(this: T[], elem: T): T[] {
    return this.filter((e) => e !== elem);
  };
}
