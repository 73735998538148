import Worldtrix from "../Worldtrix";
import WTEntity from "../entities/WTEntity";
import WTItem from "./Item/WTItem";
import * as WT from "@/ts/wt/declaration/WTEnums";
import Population from "../entities/Population/Population";
import { VToolbarItems } from "vuetify/lib";

export default class Inventory {
  parent: WTEntity;
  items: Array<WTItem> = [];
  sizeMax: number = 1000000000;

  static readonly SIZE_TRIBE_FACTOR: number = 20;
  isTribe: boolean = false;

  static readonly EXCHANGE_WGS_DISTANCE = 0.005;

  constructor(parent: WTEntity, inventoryObj?: any) {
    this.parent = parent;

    if (inventoryObj) {
      for (let item of inventoryObj.items) {
        this.items.push(
          new WTItem(
            item[0] as WT.Inventory.Item,
            (item[1] as unknown) as number
          )
        );
      }
    }
    this.getSizeMax();
    //this.logd("");
  }

  /**
   *
   * @returns The total added number of items in the invetory of all types   */
  getSize(): number {
    let itemSum = 0;
    for (let item of this.items) {
      itemSum += item.amount;
    }
    return itemSum;
  }

  getTypesAmount(): number {
    return this.items.length;
  }

  getFreeSpace(): number {
    return this.getSizeMax() - this.getSize();
  }

  checkIsFull(): boolean {
    if (this.getSize() < this.getSizeMax()) return false;
    return true;
  }

  getItemsOfType(itemType: WT.Inventory.Type): Array<WTItem> {
    let items = this.items.filter((item) => item.getItemType() === itemType);
    return items;
  }

  getAmountOfItemType(itemType: WT.Inventory.Type): number {
    let foodItems = this.getItemsOfType(WT.Inventory.Type.FOOD);
    let foodAvailable = Inventory.sumItemAmount(foodItems);
    return foodAvailable;
  }

  /**
   *
   * @param item the item in this inventory
   * @param amount
   * @returns Amount of not added items
   */
  addAmountUntilMax(item: WTItem, amount: number): number {
    let freeSpace = this.getFreeSpace();
    if (freeSpace >= amount) {
      item.amount += amount;
      return 0;
    } else {
      let remaining = amount - freeSpace;
      item.amount += freeSpace;
      return remaining;
    }
  }

  getSizeMax(): number {
    if (this.parent.population) {
      this.sizeMax = this.parent.population.calculateInventoryBaseValue();
      if (this.isTribe) {
        this.sizeMax *= Inventory.SIZE_TRIBE_FACTOR;
        //console.warn("Tribe Inventory Size: " + this.sizeMax)
      }
    }
    //console.warn(`${this.parent.wtid} (${this.parent.title}).inventory.maxSize: ${this.sizeMax}`)
    return this.sizeMax;
  }

  getExistingItem(itemRequest: WT.Inventory.Item): WTItem | undefined {
    let filtered = this.items.filter((item) => item.item == itemRequest);
    if (filtered.length == 1) return filtered[0];
    return undefined;
  }

  getOrCreateItem(itemType: WT.Inventory.Item): WTItem {
    let tryGet = this.getExistingItem(itemType);
    if (tryGet) {
      return tryGet;
    } else {
      let createdItem = new WTItem(itemType, 0);
      this.items.push(createdItem);
      this.logw(`getOrCreateItem(${itemType}) created.`);
      return createdItem;
    }
  }

  merge(sourceInventory: Inventory): boolean {
    if (this.getFreeSpace() <= sourceInventory.getSize()) {
      console.error("can not merge Inventories due to missing space.");
      return false;
    } else {
      for (let item of sourceInventory.items) {
        let targetItem = this.getOrCreateItem(item.item);
        targetItem.merge(item);
      }
      return true;
    }
  }

  setItemAmount(itemType: WT.Inventory.Item, valueSet: number): number {
    let item = this.getOrCreateItem(itemType);
    let delta = valueSet - item.amount;
    item.amount = valueSet;
    if (item.amount == 0) {
      this.removeItem(itemType);
    } else if (item.amount < 0) {
      this.removeItem(itemType);
      console.error(`Inventory. Changed to negative: ${item.amount}`);
    }
    return delta;
  }

  addItemAmount(itemType: WT.Inventory.Item, amountAdd: number): boolean {
    let item = this.getOrCreateItem(itemType);
    item.amount += amountAdd;
    if (item.amount == 0) {
      this.removeItem(itemType);
    } else if (item.amount < 0) {
      this.removeItem(itemType);
      console.error(
        `Inventory. Changed (${amountAdd}) and ended with less than 0 available - final value ${item.amount}`
      );
      return false;
    }
    return true;
  }

  removeItem(itemType: WT.Inventory.Item): Inventory {
    this.items = this.items.filter((arg) => arg.item != itemType);
    return this;
  }

  checkItemAmount(itemType: WT.Inventory.Item, amount: number): boolean {
    let iObj = this.getExistingItem(itemType);
    if (iObj) {
      if (iObj.amount >= amount) return true;
    }
    console.debug(
      `checkItemAmount(${itemType}, ${amount}) existing amount: ${iObj?.amount}`
    );
    return false;
  }

  addAmountToItems(
    itemsType: Array<WT.Inventory.Item>,
    amount: number
  ): Inventory {
    for (let i of itemsType) {
      let item = this.getOrCreateItem(i);
      item.amount += amount;
    }
    return this;
  }

  toString(): String {
    return (
      this.getSize() +
      " / " +
      this.getSizeMax() +
      " || " +
      this.getTypesAmount() +
      " types"
    );
  }

  toStringItemList(): String {
    let rString = "";
    let count = 0;
    for (let item of this.items) {
      if (count++ > 0) rString += ",";
      rString += `${item.amount} ${item.item}`;
    }
    return rString;
  }
  //////// Static Inventory functions\\\\\\\\

  static sumItemAmount(items: Array<WTItem>): number {
    let sum = 0;
    for (let item of items) sum += item.amount;
    return sum;
  }

  /**
   * Removes the defined amount of items from a Array<Items> just by simple starting with the first item.
   *
   * @param items Array of items where the amount should be removed
   * @param amount Amount of items to remove
   * @returns should be 0 if the Array contained enough items to removed the desired amount. 0> if not enough items where available.
   */
  static subItemAmount(items: Array<WTItem>, amount: number): number {
    let leftOverSubAmount = amount;
    for (let item of items) {
      if (item.amount >= leftOverSubAmount) {
        item.amount -= leftOverSubAmount;
        return 0;
      } else {
        leftOverSubAmount -= item.amount;
        item.amount = 0;
      }
    }
    return leftOverSubAmount;
  }

  logl(msg?: string): void {
    console.log(
      `Inventory.logl(): ${this.constructor.name} ${this.parent.wtid} : ${msg}`
    );
  }

  logw(msg?: string): void {
    console.warn(
      `Inventory.logw(): ${this.constructor.name} ${this.parent.wtid} : ${msg}`
    );
    console.warn(this);
  }

  logd(msg?: string, obj?: boolean): void {
    console.debug("-----Inventory.logd()-list----");
    for (let item of this.items) {
      item.logd();
    }
    if (obj) console.debug(this);
    console.debug("------------------------------");
  }
}
