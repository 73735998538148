









import { Component, Vue } from "vue-property-decorator";
import NavigationNav from "@/components/elements/NavigationNav.vue";

import store from "@/store";

@Component({
  components: {
    NavigationNav,
  },
  created() {
    let darkModeFlag = (this.$store.state as typeof store.state).settings.UI
      .darkTheme;
    this.$vuetify.theme.dark = darkModeFlag;
  },
})
export default class App extends Vue {
  name: string = "Worldtrix";
}
