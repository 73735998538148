
































import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import DetailsTerrain from "@/components/details/DetailsTerrain.vue";
import DetailsObjects from "@/components/details/DetailsObjects.vue";
import DetailsTaskForce from "@/components/details/DetailsTaskForce.vue";
import DetailsTribe from "@/components/details/DetailsTribe.vue";

import Vue from "vue";
import WTSelect from "@/ts/wt/interaction/WTSelect";
//import store from "@/store";

export default Vue.extend({
  name: "DetailsPanel",
  components: {
    DetailsTerrain,
    DetailsObjects,
    DetailsTaskForce,
    DetailsTribe,
  },
  props: {},
  data: () => ({
    jsonSuccess: true,
    testingData: "Testing-Data in Cesium-Panel",
    textareaObj: "not defined",
  }),
  computed: {
    ...mapGetters([
      "wt",
      "getWTSelect",
      "getWTSelectIfEntity",
      "getWTSelectEntityType",
    ]),
    wteAbilites() {
      let select = this.getWTSelect as WTSelect;
      //console.warn(select);
      if (select.entity) {
        return select.entity.abilities;
      } else {
        return undefined;
      }
    },
    ...mapState({
      interaction: (state: any) => state.interaction,
      worldtrix: (state: any) => state.wt.wt,
    }),
    lastIndex: function() {
      return this.interaction.selection.player.length - 1;
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    templateFunction: function(event: any) {
      console.log("breakpoint " + event);
    },
  },
});
