import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEAbility from "../../WTEAbility";
import WTEAction from "../../WTEAction";
import WTEAInput from "../../WTEAInput";
import WTHelpers from "../../../lib/WTHelpers";
import TaskForce from "@/ts/wt/entities/TaskForce/TaskForce";
import Inventory from "@/ts/wt/Inventory/Inventory";
import WTValue from "@/ts/wt/lib/WTValue/WTValue";
import TimeManager from "@/ts/wt/managers/TimeManager";
import WGS from "@/ts/wt/lib/WGS";
import WTVLinear from "@/ts/wt/lib/WTValue/WTVLinear";
import WTEAItemExchange from "../WTEAItemExchange";
import WTTarget from "../../WTTarget";
import WTEAHuntWildlifeSub from "./WTEAHuntWildlifeSub";
import Tribe from "@/ts/wt/entities/Tribe";

/**
 * Wildlife hunting allows to collect food from "terrain" or in orgins from wgs-coordinates.
 *
 * Who?
 * - TaskForce -> Hunters
 *
 * Input?
 * - Amount: to collect
 * - Target: WTTarget with wgs
 * - Tribe: to deliver the goods
 *
 * Sub-actions steps:
 * 1. MoveTo target WGS
 * 2. THIS: Hunt around spot with WTEMoveable.startRandomWalk()
 * 3. MoveTo tribe
 * 4. ItemExchange with tribe
 */
export default class WTEAHuntWildlife extends WTEAction {
  type: WT.Entity.Action;

  taskForce!: TaskForce;
  tribe!: Tribe;
  inventory!: Inventory;

  //sub-actionLoop
  actionMoveTarget!: WTEAction;
  actionMoveTribe!: WTEAction;
  actionHunt!: WTEAction;
  actionItemExchangeItems!: WTEAction;

  tribeToMoveAndExchange!: WTEAInput;
  targetToMoveAndHunt!: WTEAInput;

  static wildlifeHuntingWGSRange = 0.0005;
  static wildlifeHuntingItems: Array<WT.Inventory.Item> = [
    WT.Inventory.Item.MEAT,
    WT.Inventory.Item.FUR,
    WT.Inventory.Item.BONES,
  ];
  static wildlifeHunterItemRatio: Array<number> = [0.85, 0.1, 0.05];
  static wildlifeHuntingDurationValue = 0.4 / TimeManager.UNITS.HOUR;

  wildlifeHuntingIsActive: boolean = false;
  wildlifeHuntingTargetValue = -1;
  wildlifeHuntingA1 = -1;

  wildlifeHuntingWTValue!: WTValue;

  constructor(ability: WTEAbility, input?: WTEAInput) {
    super(ability, input);
    this.type = WT.Entity.Action.TASKFORCE_HUNT_WILDLIFE;
  }

  // ------------------------ START action functions ------------------------ \\
  init(input: WTEAInput): WTEAction {
    this.superInit();
    // assigne potential new input in case of re-init call
    this.input = input;
    this.targetToMoveAndHunt = input.clone().setToSubAction();
    this.tribeToMoveAndExchange = input.clone().setToSubAction();

    this.taskForce = this.input.entity as TaskForce;
    this.tribe = this.taskForce.tribe;
    this.inventory = this.entity.inventory;

    //prepare step 1 & 2 input
    this.targetToMoveAndHunt.startNow = false;
    this.targetToMoveAndHunt.loopAction = false;

    //prepare step 3 & 4 input
    this.tribeToMoveAndExchange.startNow = false;
    this.tribeToMoveAndExchange.loopAction = false;
    this.tribeToMoveAndExchange.tribe = this.taskForce.tribe;
    this.tribeToMoveAndExchange.target = new WTTarget(
      this.tribeToMoveAndExchange.tribe.wgs,
      this.tribeToMoveAndExchange.tribe
    );
    this.tribeToMoveAndExchange.items =
      WTEAHuntWildlifeSub.wildlifeHuntingItems;
    this.tribeToMoveAndExchange.itemType = WT.Inventory.Item.MEAT;

    // generate action items if they do not exist yet because no init was done
    this.generateSubActions();

    return this;
  }

  private generateSubActions(): void {
    if (
      !this.actionItemExchangeItems ||
      !this.actionMoveTarget ||
      !this.actionMoveTribe
    ) {
      this.actionMoveTarget = this.entity.createAction(
        WT.Entity.Action.BASIC_MOVE,
        this.targetToMoveAndHunt
      );
      this.actionHunt = new WTEAHuntWildlifeSub(
        undefined,
        this.targetToMoveAndHunt
      );
      this.actionMoveTribe = this.entity.createAction(
        WT.Entity.Action.BASIC_MOVE,
        this.tribeToMoveAndExchange
      );
      this.actionItemExchangeItems = this.entity.createAction(
        WT.Entity.Action.BASIC_ITEM_EXCHANGE,
        this.tribeToMoveAndExchange
      );
      this.actionMoveTarget.isSubAction = true;
      this.actionHunt.isSubAction = true;
      this.actionMoveTribe.isSubAction = true;
      this.actionItemExchangeItems.isSubAction = true;
    }
  }

  initPreConditions(): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  listPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  checkPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    let failedConds: Array<WT.Interaction.PreCondtionType> = [];
    switch (this.subActionState) {
      case 1:
        break;
      case 2:
        break;
      case 3:
        break;
      case 4:
        break;
      default:
        throw new Error("Wrong subaction subActionState");
    }
    this.actionMoveTarget.checkPreConditions(this.targetToMoveAndHunt);
    // do additional pre condition checks here
    return failedConds;
  }

  start(): boolean {
    this.superStart();
    if (!this.started) {
      return false;
    }
    this.actionMoveTarget.init(this.targetToMoveAndHunt);
    this.actionMoveTarget.start();
    return true;
  }

  update(tStamp: number): WTEAction {
    //console.log(`WTEAHuntWildlife.updater(${tStamp})`;
    switch (this.subActionState) {
      case 1:
        this.actionMoveTarget.update(tStamp);
        if (this.actionMoveTarget.isDone()) {
          console.warn(`Hunting-State-1: move done start hunting in state 2`);
          this.actionHunt.init(this.targetToMoveAndHunt);
          this.actionHunt.start();
          this.subActionState = 2;
        }
        break;
      case 2:
        this.actionHunt.update(tStamp);
        if (this.actionHunt.isDone()) {
          console.warn(
            `Hunting-State-2: Hunting done start moving to tribe in state 3`
          );
          this.actionMoveTribe.init(this.tribeToMoveAndExchange);
          this.actionMoveTribe.start();
          this.subActionState = 3;
        }
        break;
      case 3:
        this.actionMoveTribe.update(tStamp);
        if (this.actionMoveTribe.isDone()) {
          console.warn(
            `Hunting-State-3: move done start item exchange in state 4`
          );
          this.taskForce.inventory.logd("Hunting before exchange");
          this.actionItemExchangeItems.init(this.tribeToMoveAndExchange);
          this.actionItemExchangeItems.start();
          this.subActionState = 4;
        }
        break;
      case 4:
        this.actionItemExchangeItems.update(tStamp);
        if (this.actionItemExchangeItems.isDone()) {
          this.tribe.inventory.logd("Hunting trade done");
          this.taskForce.inventory.logd("Hunting trade done");
          console.warn(`Hunting-State-4: Item Exchange done. Finish...`);
          this.finish();
        }
        break;
      default:
        throw new Error("Wrong subaction subActionState");
    }
    return this;
  }

  updateAttributes(input: WTEAInput): WTEAction {
    switch (this.subActionState) {
      case 1:
        break;
      case 2:
        break;
      case 3:
        break;
      case 4:
        break;
      default:
        throw new Error("Wrong subaction subActionState");
    }
    return this;
  }

  isDoneIn(): number {
    let t = this.tm.now();
    switch (this.subActionState) {
      case 1:
        return this.actionMoveTarget.isDoneIn();
      case 2:
        return this.actionHunt.isDoneIn();
      case 3:
        return this.actionMoveTribe.isDoneIn();
      case 4:
        return this.actionItemExchangeItems.isDoneIn();
      default:
        throw new Error("Wrong subaction subActionState");
    }
    return -1;
  }

  finish(): boolean {
    this.superFinish();
    return this.finished;
  }

  cancel(): boolean {
    switch (this.subActionState) {
      case 1:
        this.actionMoveTarget.cancel();
        break;
      case 2:
        this.actionHunt.cancel();
        break;
      case 3:
        this.actionMoveTribe.cancel();
        break;
      case 4:
        this.actionItemExchangeItems.cancel();
        break;
      default:
        throw new Error("Wrong subaction subActionState");
    }
    this.superCancel();
    return this.canceled;
  }

  // ---------------------------- action helpers ---------------------------- \\

  // ------------------------------ wt helpers ------------------------------ \\
}
