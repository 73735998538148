





























import Vue from "vue";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

import ListItemComposables from "@/composables/ListItemComposables";
import WorldObject from "@/ts/wt/entities/WorldObject/WorldObject";

export default Vue.extend({
  name: "ObjectListItem",
  components: {},
  props: {
    wtObject: { type: WorldObject, required: true },
  },
  data: () => ({}),
  setup(wtObject) {
    const { textComputed, iClick, iDoubleClick } = ListItemComposables(
      wtObject.wtObject as WorldObject
    );
    return { textComputed, iClick, iDoubleClick };
  },
  computed: {
    ...mapState({}),
    ...mapGetters(["l10n", "settings"]),
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    emptyFunction(wtid: number) {
      console.log("click: " + wtid);
    },
  },
  created: function() {
    //console.log(this)
  },
  mounted: function() {},
});
