const callbackTime = 0;

const gModelTypesToURLs = [
    {

        text: "Coniferous tree",
        gType: "GFX_TREE_C1",
        url: "gfx/treeC1_h5m.gltf",
    },
    {

        text: "Deciduous tree",
        gType: "GFX_TREE_L1",
        url: "gfx/treeL1_h4m.gltf",
    },
    {
        text: "Bush",
        gType: "GFX_BUSH_1",
        url: "gfx/bush1_wl50cm.gltf",
    },
    {

        text: "Stone",
        gType: "GFX_STONE_1",
        url: "gfx/stone1_lw1m.gltf",
    },
    {

        text: "Cursor",
        gType: "GFX_CURSOR_360DEG_1S",
        url: "gfx/icocursor_6m_360deg_48f.gltf",
    },
    {

        text: "Fishing spot",
        gType: "GFX_FISHING_SPOT_1",
        url: "gfx/icocursor_6m_360deg_48f.gltf",
    },
    {

        text: "Hunting grounds",
        gType: "GFX_HUNTING_GROUNDS_1",
        url: "gfx/icocursor_6m_360deg_48f.gltf",
    },
    {
        text: "Ressource stockpile",
        gType: "GFX_STOCKPILE_1",
        url: "gfx/icocursor_6m_360deg_48f.gltf",
    }
];

const gTexturesToURLs = {
    "GFX_TEXTURE_FOREST_MIXED_1": "gfx/textures/forest_mixed_1.png",
    "GFX_TEXTURE_FOREST_CONIFEROUS_1": "gfx/textures/forest_coniferous_1.png",
    "GFX_TEXTURE_FOREST_LEAVES_1": "gfx/textures/forest_leaves_1.png",
    "GFX_TEXTURE_GRASLAND_1": "gfx/textures/grasland_1.png",
    "GFX_TEXTURE_STEPPLAND_1": "gfx/textures/steppland_1.png",
    "GFX_TEXTURE_SEA_1": "gfx/textures/sea_1.png",
    "GFX_TEXTURE_MOUNTAINS_1": "gfx/textures/montains_1.png",
}

const gNaturalEarthIIWorlds = [
    "Minmum quality with tiles.",
    "gfx/naturalEarthII/natural_earth_II_1350p.jpg",
    "gfx/naturalEarthII/natural_earth_II_2700p.jpg",
    "gfx/naturalEarthII/natural_earth_II_5400p.jpg",
    "gfx/naturalEarthII/natural_earth_II_10800p.jpg"
]

const gNaturalEarthIISpectrals = [
    "gfx/naturalEarthII/natural_earth_II_specular_1350p.jpg",
    "gfx/naturalEarthII/natural_earth_II_specular_2700p.jpg",
    "gfx/naturalEarthII/natural_earth_II_specular_5400p.jpg",
    "gfx/naturalEarthII/natural_earth_II_specular_10800p.jpg"
]

export default {
    getgWorldTerrainObjectsTypes() {
        for (let i = 0; i < gModelTypesToURLs.length; i++) {
            gModelTypesToURLs[i].aIndex = i++;
        }
        return gModelTypesToURLs;
    },

    getgModelURIFromTyp(GFX_MODEL_TYPE) {
        let rModel = gModelTypesToURLs.find(model => model.gType == GFX_MODEL_TYPE)
        return rModel.url;
    },

    getgTextureURI(GFX_TEXTURE_TYPE) {
        return gTexturesToURLs[GFX_TEXTURE_TYPE];
    },

    getgTextureURIsObject() {
        return gTexturesToURLs;
    },

    getgNaturalEarthWorlds() {
        return gNaturalEarthIIWorlds;
    },

    gNaturalEarthIISpectrals() {
        return gNaturalEarthIISpectrals;
    },

    getServerGameTime(cb) {
        setTimeout(() => cb(new Date().getTime()), callbackTime)
    }
}