import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEAbility from "../../WTEAbility";
import WTEAction from "../../WTEAction";
import WTEAInput from "../../WTEAInput";
import WTHelpers from "../../../lib/WTHelpers";
import Population from "@/ts/wt/entities/Population/Population";
import Tribe from "@/ts/wt/entities/Tribe";
import Inventory from "@/ts/wt/Inventory/Inventory";
import WTVLinear from "@/ts/wt/lib/WTValue/WTVLinear";
import TimeManager from "@/ts/wt/managers/TimeManager";
import WTValue from "@/ts/wt/lib/WTValue/WTValue";
import WTEntity from "@/ts/wt/entities/WTEntity";

export default class WTEASplitTribe extends WTEAction {
  type: WT.Entity.Action;

  population!: Population;
  tribe!: Tribe;
  inventory!: Inventory;

  BASE_DURATION = 2 * TimeManager.UNITS.HOUR;
  splitTribeA1 = -1;
  readonly splitTribeWGSRange = 0.0005;
  splitTribeWTValue!: WTValue;
  newTribe!: Tribe;

  // TODO: Insert/replace simulation variables here
  actionValue1: number = 0;
  actionValue2: number = 0;

  constructor(ability: WTEAbility, input?: WTEAInput) {
    super(ability, input);
    this.type = WT.Entity.Action.TRIBE_SPLIT_TRIBE;
  }

  // ------------------------ START action functions ------------------------ \\
  init(input: WTEAInput): WTEAction {
    // prevent loop because it is not required so often...
    this.input.loopAction = false;
    this.input.loopable = false;
    this.loopAction = false;
    this.superInit();
    // assigne potential new input in case of re-init call
    this.input = input;
    this.tribe = this.entity as Tribe;
    this.population = this.entity.population;
    this.inventory = this.entity.inventory;
    return this;
  }

  initPreConditions(): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  listPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  checkPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    let failedConds: Array<WT.Interaction.PreCondtionType> = [];
    // do additional pre condition checks here
    failedConds = failedConds.concat(
      this.population.checkMinFoodLevel(WT.Population.FoodLevel.WELL_FEED)
    );
    return failedConds;
  }

  start(): boolean {
    let preCondditions = this.checkPreConditions();
    if (!WTHelpers.checkPreConditions(preCondditions)) {
      WTHelpers.preConditionsToLog(preCondditions);
      return false;
    } else {
      this.updateAttributes(this.input);
      this.splitTribeWTValue = new WTVLinear(
        this.tm.now(),
        this.splitTribeA1,
        0
      );
      this.timeBoundariesSet(this.isDoneIn());
      console.log("aSplitTribeStart()");
      return true;
    }
  }

  update(tStamp: number): WTEAction {
    let newTribe = new Tribe(this.tribe.wt).setWGS(this.tribe.wgs);
    this.entity.wt.em.add(newTribe);
    let amount = Math.round(this.tribe.population.size() * 0.5);
    newTribe.title = "Split from " + this.tribe.title;
    newTribe.population = this.population.split(
      WT.Population.SplitType.ADULTS_CHILDREN,
      amount
    );
    //@ts-ignore - not sure what is here going on...
    this.newTribe = newTribe;
    console.warn("Splitted from this tribe(" + this.entity.wtid + ")");
    newTribe.logWarn("New splitted tribe(" + newTribe.wtid + ")");
    console.log(newTribe);
    this.finish();

    return this;
  }

  updateAttributes(input: WTEAInput): WTEAction {
    this.splitTribeA1 = 1 / this.BASE_DURATION;
    return this;
  }

  isDoneIn(): number {
    let t = this.tm.now();
    this.update(t);
    // let rt = this.splitTribeWTValue.calculateTimeUntil(1, t);
    // console.log(
    //   "Tribe.splitTribe().doneIn(): " + TimeManager.toHours(rt) + " hours"
    // );
    return 0;
  }

  finish(): boolean {
    this.superFinish();
    console.log(
      "WTEASpliteTribe().finish(): Old:" +
        this.tribe.wtid +
        ", New: " +
        this.newTribe.wtid
    );
    return this.finished;
  }

  cancel(): boolean {
    this.superCancel();
    throw new Error("Method not implemented.");
    return this.canceled;
  }

  // ---------------------------- action helpers ---------------------------- \\

  // ------------------------------ wt helpers ------------------------------ \\
}
