import * as WT from "@/ts/wt/declaration/WTEnums";
import Scouts from "../../entities/TaskForce/Scouts";
import WTEMoveable from "../../entities/WTEMoveable";
import WGS from "../../lib/WGS";
import WTHelpers from "../../lib/WTHelpers";
import TimeManager from "../../managers/TimeManager";
import WTEAbility from "../WTEAbility";
import WTEAction from "../WTEAction";
import WTEAInput from "../WTEAInput";
import WTTarget from "../WTTarget";

export default class WTEAMove extends WTEAction {
  type: WT.Entity.Action;
  moveSpeed!: number;
  moveTarget!: WTTarget;
  moveVector: Array<number> = [0, 0, 0];

  logMoveOutputCounter: number = 0;

  constructor(ability: WTEAbility, input?: WTEAInput) {
    super(ability, input);
    this.type = WT.Entity.Action.BASIC_MOVE;
  }

  // ------------------------ START action functions ------------------------ \\

  init(input: WTEAInput): WTEAction {
    this.superInit();
    // assigne potential new input in case of re-init call
    this.input = input;
    // fallback speed
    if (!this.input.moveSpeed)
      this.input.moveSpeed = WTEMoveable.MOVE_SPEED_DEFAULT;
    this.moveSpeed = input.moveSpeed;
    this.moveTarget = new WTTarget(this.input.target.wgs);
    //console.warn(`WTEAMove.init() + ${this.toString()}`);
    return this;
  }

  initPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  listPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  checkPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    if (input) this.init(input);
    let conditionFails: Array<WT.Interaction.PreCondtionType> = [];
    return conditionFails;
  }

  start(): boolean {
    this.superStart();
    if (!this.started) {
      return false;
    }

    console.warn(
      `WTEAMove.start() ${this.toString()} @ ${TimeManager.toHours(
        this.updatedTime
      )} h gameTime`
    );
    this.wgsDirectionUpdate();
    this.isDoneIn();
    return true;
  }

  update(tStamp: number): WTEAction {
    //TODO: [WT-130] fix planetary movement
    this.superUpdate();
    this.wgsDirectionUpdate();
    let mDistance = this.moveSpeed * this.updatedTimeDelta;
    let failedConds: Array<WT.Interaction.PreCondtionType> = [];
    if (
      WGS.checkMaxDistance(
        failedConds,
        this.entity,
        this.moveTarget,
        WTEMoveable.MOVE_MIN_STOP_DISTANCE
      )
    ) {
      this.entity.setWGS(this.moveTarget.wgs);
      this.finish();
    } else {
      let llRatio = Math.abs(this.moveVector[0]) + Math.abs(this.moveVector[1]);
      let longR = Math.abs(this.moveVector[0]) / llRatio;
      let latR = Math.abs(this.moveVector[1]) / llRatio;
      this.entity.wgs[0] += longR * mDistance * Math.sign(this.moveVector[0]);
      this.entity.wgs[1] += latR * mDistance * Math.sign(this.moveVector[1]);
    }
    this.logMoveCounted();
    //console.log(`moveUpdate() wgs(${this.entity.wgs})`);
    return this;
  }

  updateAttributes(input: WTEAInput): WTEAction {
    if (input && input.moveSpeed) {
      this.moveSpeed = input.moveSpeed / TimeManager.UNITS.HOUR;
    } else if (this.type == WT.Entity.Action.TASKFORCE_TRAVEL) {
      this.moveSpeed =
        WTEMoveable.MOVE_SPEED_DEFAULT * Scouts.MOVE_TRAVEL_MULTIPLIER;
    } else {
      this.moveSpeed = WTEMoveable.MOVE_SPEED_DEFAULT;
    }
    return this;
  }

  isDoneIn(): number {
    let distance = this.distanceToTarget();
    this.durationRemaining = distance / this.moveSpeed;
    return this.durationRemaining;
  }

  finish(): boolean {
    // if this is the last move action it can not be looped because it will cause many actions that are resolved instantly
    if (this.entity.actions.length == 1) {
      this.loopAction = false;
      this.input.loopAction = false;
    }
    this.superFinish();
    return this.finished;
  }

  cancel(): boolean {
    this.superCancel();
    return this.canceled;
  }

  // ---------------------------- action helpers ---------------------------- \\

  private wgsDirectionUpdate(): void {
    if (this.moveTarget) {
      let longDir = this.moveTarget.wgs[0] - this.entity.wgs[0];
      let latDir = this.moveTarget.wgs[1] - this.entity.wgs[1];
      let hDir = this.moveTarget.wgs[2] - this.entity.wgs[2];
      this.moveVector = [longDir, latDir, hDir];
    } else {
      console.error("No moveTarget() set but direction update requested");
    }
  }

  distanceToTarget(): number {
    if (this.moveTarget)
      return WGS.distance(this.moveTarget.wgs, this.entity.wgs);
    else return 0;
  }

  // ------------------------------ wt helpers ------------------------------ \\

  toString(): String {
    return `${
      this.entity.wtid
    }.action<WTEAMove>: ${this.distanceToTarget()} wgs-units
    , Duration: ${TimeManager.toHours(this.durationRemaining)} h
    , speed  ${TimeManager.perHour(this.moveSpeed)} wgsu / h
      "WGS: Pos(${this.entity.wgs}) -> Target(${this.moveTarget.wgs})`;
  }

  /**
   * Outputs the move action values to console.log()
   *
   * @param warn If true use console.warn()
   */
  logMoveCounted(warn?: boolean): void {
    if (this.logMoveOutputCounter++ >= WTEMoveable.LOG_OUT_MOVEMENT_STEPS) {
      this.logMoveOutputCounter = 0;
      if (this.started) {
        this.isDoneIn();
        if (warn) {
          console.warn(this.toString());
        } else {
          console.log(this.toString());
        }
      } else {
        console.log("Not moving, because action was not started");
      }
    }
  }
}
