
















































// @ is an alias to /src
import CesiumViewer from "@/components/CesiumViewer.vue";
import WorldPanel from "@/components/WorldPanel.vue";
import InteractionPanel from "@/components/play/InteractionPanel.vue";
import EntityActionsList from "@/components/play/EntityActionsList.vue";
import DetailsPanel from "@/components/DetailsPanel.vue";
import CreateAction from "@/components/elements/CreateAction.vue";

import Vue from "vue";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default Vue.extend({
  name: "Play",
  components: {
    CesiumViewer,
    WorldPanel,
    InteractionPanel,
    EntityActionsList,
    DetailsPanel,
    CreateAction,
  },
  data: () => ({
    viewport: {
      x: 0,
      y: 0,
    },
    absolute: true,
    overlay: true,
  }),
  computed: {
    ...mapState({
      overlayIsActive: (state) =>
        state.interaction.createAction.overlayIsActive,
      createAction: (state) => state.interaction.createAction,
    }),
    ...mapGetters([]),
  },
  methods: {
    ...mapActions([""]),
    ...mapMutations([""]),

    onResize(resizeEvent: any) {
      let canvasElement = document.querySelector("canvas");
      if (canvasElement) {
        canvasElement.style.height = "92vh";
      }
      if (resizeEvent) {
        this.viewport.x = resizeEvent.currentTarget.innerWidth;
        this.viewport.y = resizeEvent.currentTarget.innerHeight;
        //console.log(event);
        //console.log(`x: ${this.viewport.x} y: ${this.viewport.y}`);
      }
    },
  },
  created() {
    this.$store.dispatch("aCsInit").then(() => {
      this.$store.dispatch("aCsInitGame");
      this.$store.commit("mEditorSetIsOn", false);
    });
  },
  watch: {},
});
