import WTTarget from "./WTTarget";
import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEntity from "../entities/WTEntity";
import WTItem from "../Inventory/Item/WTItem";
import WTEAbility from "./WTEAbility";
import Tribe from "../entities/Tribe";
import TaskForce from "../entities/TaskForce/TaskForce";

/**
 * CHAOS-OBJECT
 *
 * Collection class to collect all potential input which is required to created and verifiy an action. Does not contain any logic.
 * Verifcation of the dataset is implemented with WTEAbility.ts before action creation and with the WTEA-Implementation files.
 */
export default class WTEAInput {
  protected static wtiidCounter = 0;
  wtiid: number;

  entity!: WTEntity;
  tEntity!: WTEntity;
  target!: WTTarget;
  ability!: WTEAbility;

  abilityDummy: boolean = false;
  pushActionToQueue: boolean = false;
  subActionChangeFlag: boolean = false;
  loopAction: boolean = false;
  startNow: boolean = false;
  loopable!: boolean;

  any!: any;
  anyArray!: Array<any>;

  startTime!: number;
  endTime!: number;
  duration!: number;

  // Moveable
  moveSpeed!: number;

  // Inventory & Items
  item!: WTItem;
  items!: Array<WT.Inventory.Item>;
  itemType!: WT.Inventory.Item;
  targetAmount: number = 0;
  consumeAmount: number = 0;

  // Population
  maxSaturation: boolean = false;
  populationSplitType!: WT.Population.SplitType;
  populationSplitAmount!: number;

  // TaskForce
  taskForceType!: WT.Entity.Type;
  taskForce!: TaskForce;

  // Tribe
  tribe!: Tribe;

  // Pre-Conditions
  preConds: Array<WT.Interaction.PreCondtionType> = [];

  constructor(entity?: WTEntity, abilityDummy?: boolean) {
    this.wtiid = WTEAInput.wtiidCounter++;
    if (entity) {
      this.entity = entity;
      this.target = new WTTarget(this.entity.wgs);
    }
    if (abilityDummy) {
      this.abilityDummy = abilityDummy;
    }
  }

  clone(): WTEAInput {
    let clone = new WTEAInput();
    clone = Object.assign(clone, this);
    return clone;
  }

  /**
   * Changes the flag pushActionToQueue to false and the modify subActionChangeFlag to true
   *
   * @returns this object to cont.
   */
  setToSubAction(): WTEAInput {
    this.pushActionToQueue = false;
    this.subActionChangeFlag = true;
    return this;
  }

  setToActionQueue(): WTEAInput {
    this.pushActionToQueue = true;
    this.subActionChangeFlag = true;
    return this;
  }

  /******************* wt-helpers ********************/

  toString(): String {
    return `${this.wtiid}.WTEAInput.toString(): `;
  }

  toStringDebug(): String {
    return `WTEAInput.toStringDebug(): ${this.entity.wtid}`;
  }

  log(msg?: string): void {
    console.log(`WTEAInput.log(): ${this.entity.wtid} : ${msg}`);
  }

  logWarn(msg?: string): void {
    console.warn(`WTEAInput.logWarn(): ${this.entity.wtid} : ${msg}`);
    console.warn(this);
  }

  logError(msg?: string): void {
    console.error(`WTEAInput.logError(): ${this.entity.wtid}: ${msg}`);
    console.error(this);
  }

  logDebug(msg?: string): void {
    console.debug(`WTEAInput.logDebug(): ${this.entity.wtid}: ${msg}`);
    console.debug(this);
  }
}
