

































import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import Vue from "vue";

import DetailsComposables from "@/composables/DetailsComposables";
import TaskForce from "@/ts/wt/entities/TaskForce/TaskForce";
import AbilitiesList from "@/components/lists/AbilitiesList.vue";

export default Vue.extend({
  name: "DetailsTaskForce",
  components: { AbilitiesList },
  props: {
    wtEntity: { type: TaskForce, required: true },
  },
  data: () => ({}),
  setup(wtEntity) {
    const { textComputed, flyTo } = DetailsComposables(
      wtEntity.wtEntity as TaskForce
    );
    return { textComputed, flyTo };
  },
  computed: {
    ...mapGetters([
      "l10n",
      "wt",
      "settings",
      "getWTSelectIfEntity",
      "getWTSelectEntityAbilities",
    ]),
    ...mapState({
      wtid: (state: any) => state.interaction.selection.last,
    }),
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    submitJson() {
      console.log("breakpoint " + event);
    },
  },
});
