import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEntity from "../entities/WTEntity";
import WTHelpers from "../lib/WTHelpers";
import TimeManager from "../managers/TimeManager";
import WTEAbility from "./WTEAbility";
import WTEAInput from "./WTEAInput";

export default abstract class WTEAction {
  private static wtaidCounter = 0;
  wtaid: number;

  // global action vlaues
  actionRangeWGS = 0.0005;

  // group action for graph based gameplay of WT-177 with more high level makro instead of mikro
  isSubAction = false;
  hasSubActions = false;
  subActions: Array<WTEAction> = [];
  subActionState: number = 1;

  // action related values with default false
  preCheckPassed: boolean = false;
  started: boolean = false;
  finished: boolean = false;
  canceled: boolean = false;
  loopAction: boolean = false;

  // ability and input data links
  input!: WTEAInput;
  ability!: WTEAbility;

  updatedTime!: number;
  updatedTimePrevious!: number;
  updatedTimeDelta: number = 0;

  startedTimestamp!: number;
  finishTimestamp!: number;
  durationRemaining!: number;

  // init here because preConds might be already be checked/added here
  preConditions: WT.Interaction.PreCondtionType[] = [];
  preCondsFailed: Array<WT.Interaction.PreCondtionType> = [];

  // additional references to make code more readable
  entity!: WTEntity;
  tm!: TimeManager;

  /****************************** Abstracts ******************************/
  abstract type: WT.Entity.Action;

  /** Is called by super() if input ist given */
  abstract init(input: WTEAInput): WTEAction;
  abstract initPreConditions(): WT.Interaction.PreCondtionType[];
  abstract listPreConditions(
    input?: WTEAInput
  ): Array<WT.Interaction.PreCondtionType>;
  abstract checkPreConditions(
    input?: WTEAInput
  ): Array<WT.Interaction.PreCondtionType>;

  abstract start(): boolean;
  abstract update(tStamp: number): WTEAction;
  abstract updateAttributes(input: WTEAInput): WTEAction;
  abstract isDoneIn(): number;
  abstract finish(): boolean;
  abstract cancel(): boolean;

  /****************************** Super stuff  ******************************/

  constructor(ability?: WTEAbility, input?: WTEAInput) {
    this.wtaid = WTEAction.wtaidCounter++;

    if (ability) {
      this.ability = ability;
      this.entity = ability.entity;
    }

    // call init() and set initailization flag
    if (input) {
      this.input = input;
      this.loopAction = input.loopAction;
      this.init(input);
    } else {
      input = new WTEAInput(ability?.entity);
      return;
    }

    if (input) {
      // dummy abilites are used in ability for pre calculation and possibility checks but are never started()
      if (this.input.abilityDummy) {
        return;
      }
      // separate sub-actions form main actions by using dirty input flag
      else if (this.input.pushActionToQueue) {
        this.ability.entity.actions.push(this);
      }

      if (this.input.startNow) {
        this.start();
      }
    }
  }

  superInit() {
    this.tm = this.entity?.wt.tm;
    // TODO: [WT-170]
    //this.initPreConditions();
    if (this.started) {
      console.warn("init() call of already started action");
    }
    this.updatedTime = this.updatedTimePrevious = this.tm.now();
    console.log(
      `${this.entity.toString()} -> ${this.constructor.name}.superInit():`
    );
  }

  superStart(): boolean {
    this.started = true;
    this.startedTimestamp = this.tm.now();

    let preConds = this.checkPreConditions();
    if (!this.preCheckPassed) {
      WTHelpers.preConditionsToLog(preConds);
      return false;
    }

    this.started = true;
    this.superUpdate();
    return true;
  }

  superUpdate() {
    this.updatedTimePrevious = this.updatedTime;
    this.updatedTime = this.tm.now();
    this.updatedTimeDelta = this.updatedTime - this.updatedTimePrevious;
  }

  superFinish() {
    console.warn(`${this.entity.wtid}.${this.constructor.name}.superFinish()`);
    this.finished = true;
    this.finishTimestamp = this.tm.now();
    if (this.loopAction && !this.isSubAction) {
      console.warn(`${this.entity.wtid}.loopAction = true`);
      // loop action might was change in dataset but not initial input
      this.input.loopAction = true;
      this.createLoopAction();
    }

    // do not start next action in case this is a sub-action finish
    if (this.isSubAction) return;

    this.entity.startNextAction();
  }

  superCancel() {
    console.warn(`${this.entity.wtid}.${this.constructor.name}.superCancel()`);
    this.canceled = true;
    this.finishTimestamp = this.tm.now();
    this.entity.startNextAction();
  }

  /****************************** Action functions ******************************/

  createLoopAction(): WTEAction {
    return this.entity.createAction(this.type, this.input);
  }

  timeBoundariesSet(duration: number): boolean {
    // not sure if this is still required
    if (!this.startedTimestamp)
      console.warn("Action timeBoundariesSet already set");

    this.startedTimestamp = this.tm.now();
    if (this.durationRemaining < 0)
      throw new Error(
        "Can´t not set timeBoundaries for an unkown  duration < 0"
      );
    this.durationRemaining = duration;
    this.finishTimestamp = this.startedTimestamp + this.durationRemaining;
    return true;
  }

  /*********************************** helpers ***********************************/

  isDone(): boolean {
    return this.finished || this.canceled;
  }

  isDoneInRealTimeSeconds(): number {
    if (this.isDoneIn() === -1) return -1;
    return this.tm.toRealTimeSeconds(this.isDoneIn());
  }

  isDoneInRealTimeCountdown(): String {
    let totalSeconds = Math.floor(this.isDoneInRealTimeSeconds());
    if (totalSeconds === -1) {
      return "unkown";
    }
    let hours = Math.floor(totalSeconds / 3600);
    let secondsLeft = totalSeconds - hours * 3600;
    let minutes = Math.floor(secondsLeft / 60);
    secondsLeft = totalSeconds - hours * 3600 - minutes * 60;
    return `${hours} h ${minutes} m ${secondsLeft} s`;
  }

  toString(): String {
    return `"WTEAction: ${this.ability.title}, wtid: ${
      this.ability.entity.wtid
    }, Done in: ${this.isDoneIn()}`;
  }

  toStringDebug(): String {
    return `"WTEAction: ${
      this.ability.title
    } wtid: ${this.ability.entity.toString()}`;
  }
}
