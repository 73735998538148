import { ref, reactive, computed, toRefs } from "@vue/composition-api";
import store from "@/store";
import WTEntity from "@/ts/wt/entities/WTEntity";

export default function fileCompositionOne(entity: WTEntity) {
  const textComputed: any = reactive({
    debug: computed(() => entity.toStringDebug()),
    location: computed(() => entity.getWGSString()),
    inventory: computed(() => entity.inventory.toString()),
    population: computed(() => entity.population.toString()),
  });

  function iClick(wtid: number) {
    console.log("click: " + wtid);
    //@ts-ignore
    this.$forceUpdate();
    store.commit("mGameInteractionSelectionPush", wtid);
  }

  function iDoubleClick(wtid: number) {
    console.log("double-click: " + wtid);
    store.commit("mGameInteractionSelectionPush", wtid);
    store.dispatch("aCsCameraFlyToSelection", wtid);
  }

  return { textComputed, iClick, iDoubleClick };
}
