























import CesiumViewer from "@/components/CesiumViewer.vue";
import Vue from "vue";

export default Vue.extend({
  name: "Panorama",
  components: {
    CesiumViewer,
  },
  data: () => ({
    target: {
      long: 11.0,
      lat: 48,
      height: 1500,
    },
  }),
  computed: {},
  methods: {
    cameraFlyToTargetHelper: function() {
      this.$store.commit("mCsCameraFlyToTarget", this.target);
    },
    cesiumRefreshHelper: function() {
      this.$store.dispatch("aCsInitRefresh");
    },
    accessPowerUp: function() {
      this.$store.commit("mPermissionsIncreaseUiAccessTier");
    },
    resize() {
      let canvasElement = document.querySelector("canvas");
      if (canvasElement) {
        canvasElement.style.height = "94vh";
      }
    },
  },
  created() {
    this.$store.dispatch("aCsInit").then(() => {
      this.$store.dispatch("aCsInitGame");
      this.$store.commit("mEditorSetIsOn", false);
    });
  },
  destroyed() {},
  watch: {},
});
