













import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default {
  name: "JsonDetails",
  data: () => ({
    jsonSuccess: true,
    testingData: "Testing-Data in Cesium-Panel",
    textareaObj: "not defined",
  }),
  computed: {
    ...mapState({
      cesiumData: (state) => state.cesium,
    }),
    ...mapState({}),
    message: {
      get() {
        this.jsonSuccess = true;
        return JSON.stringify(this.$store.state.cesium.polyDraw.terrainAddJson);
      },
      set(value) {
        try {
          let json = JSON.parse(value);
          this.jsonSuccess = true;
          this.textareaObj = json;
        } catch (error) {
          console.log(error);
          this.jsonSuccess = false;
          return;
        }
      },
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    ...mapGetters([]),
    submitJson() {
      if (this.jsonSuccess) {
        this.$store.commit("mUpdateTerrainJson", this.textareaObj);
      }
    },
    templateFunction: function(event: any) {
      console.log("breakpoint " + event);
    },
  },
};
