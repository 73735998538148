































import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import Vue from "vue";
import AbilityListItem from "@/components/lists/items/AbilityListItem.vue";

import WTSelect from "@/ts/wt/interaction/WTSelect";
import WTEntity from "@/ts/wt/entities/WTEntity";

export default Vue.extend({
  name: "AbilitiesList",
  components: { AbilityListItem },
  props: {
    wtEntity: { type: WTEntity, required: true },
    abilities: { required: true },
  },
  data: () => ({
    benched: 0,
    forceUpdateID: -1,
  }),
  computed: {
    ...mapGetters([
      "wt",
      "getWTSelect",
      "getWTSelectIfEntity",
      "getWTSelectEntityType",
      "getWTSelectEntityAbilities",
    ]),
    ...mapState({}),
    wteAbilities() {
      let select = (this.$data.dataEntity as WTEntity).abilities;
      //console.log("ability out");
      //console.log(select);
      return select;
    },
    isDoneIn() {
      let value = (this.getWTSelect as WTSelect).entity
        .getCurrentAction()
        ?.isDoneIn();
      if (value && value > 0) {
        return "" + value;
      }
      return "unkown";
    },
    length() {
      return 7000;
    },
  },
  methods: {
    ...mapActions(["awtGameStep"]),
    ...mapMutations([]),
    templateFunction: function(event: any) {
      console.log("breakpoint " + event);
    },
  },
  created: function() {
    this.forceUpdateID = setInterval(() => this.$forceUpdate(), 1000);
  },
  mounted: function() {},
  beforeDestroy: function() {
    if (this.forceUpdateID !== -1) {
      clearInterval(this.forceUpdateID);
    }
  },
});
