































































































































































































import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import Vue from "vue";

import WGSInput from "@/components/elements/WGSInput.vue";
import WTEAbility from "@/ts/wt/interaction/WTEAbility";
import Worldtrix from "@/ts/wt/Worldtrix";
import * as WT from "@/ts/wt/declaration/WTEnums";
import WorldObject from "@/ts/wt/entities/WorldObject/WorldObject";
import WTEWorldManager from "@/ts/wt/managers/WTEWorldManager";
import WTHelpers from "@/ts/wt/lib/WTHelpers";
import WTEAInput from "@/ts/wt/interaction/WTEAInput";
import WTEPlayerManager from "@/ts/wt/managers/WTEPlayerManager";
import WTTarget from "@/ts/wt/interaction/WTTarget";
import WTEntitiesManager from "@/ts/wt/managers/WTEntitiesManager";

export default Vue.extend({
  name: "CreateAction",
  components: { WGSInput },
  props: {
    ability: { type: WTEAbility, required: true },
    aIndex: { type: Number, required: true },
  },
  data: () => ({
    switches: {
      loop: true,
      startNow: true,
      maxSaturation: false,
    },
    target: {
      selection: 7888,
    },
    entity: {
      selection: 7888,
    },
    taskForceType: {
      selection: 0,
      array: [
        { id: 0, text: "Hunters" },
        { id: 1, text: "Gatherers" },
      ],
    },
    inputValues: {
      consumeAmount: 0,
      targetAmount: 0,
    },
  }),
  computed: {
    ...mapState({
      l10n: (state) => state.l10n,
      wteaInput: (state) => state.interaction.wteaInput,
      createActionStore: (state) => state.interaction.createAction,
      wgsInput: (state) => state.interaction.wgsInput,
    }),
    ...mapGetters([
      "gameEngineData",
      "wt",
      "em",
      "wm",
      "pm",
      "getWTSelectEntityAbilities",
      "getWTSelectIfEntity",
    ]),

    showCheckTargetEntitySelection() {
      let compare = this.ability.actionType;
      let check = [
        WT.Entity.Action.TASKFORCE_RAID_ANIMAL_DEN,
        WT.Entity.Action.TASKFORCE_FISH,
        WT.Entity.Action.BASIC_ITEM_EXCHANGE,
        WT.Entity.Action.BASIC_LOOT_RESOURCES,
        WT.Entity.Action.TASKFORCE_JOIN_TRIBE,
        WT.Entity.Action.TRIBE_SETTLE_DOWN,
      ];
      if (WTHelpers.checkForEnumActionMatch(compare, check)) return true;
      return false;
    },

    showCheckTargetWGSSelection() {
      let compare = this.ability.actionType;
      let check = [
        WT.Entity.Action.TASKFORCE_HUNT_WILDLIFE,
        WT.Entity.Action.BASIC_MOVE,
        WT.Entity.Action.TASKFORCE_GATHER,
        WT.Entity.Action.TASKFORCE_EXPLORE,
        WT.Entity.Action.TASKFORCE_TRAVEL,
        WT.Entity.Action.TRIBE_BUILD_SHELTERS,
      ];
      if (WTHelpers.checkForEnumActionMatch(compare, check)) return true;
      return false;
    },

    showCheckMaxSaturation() {
      if (this.ability.actionType == WT.Entity.Action.BASIC_FOOD_CONSUME)
        return true;
      return false;
    },

    showCheckTaskForceType() {
      if (this.ability.actionType == WT.Entity.Action.TRIBE_CREATE_TASKFORCE)
        return true;
      return false;
    },

    showCheckMatchingWTETargets() {
      let woType: WT.WorldObject.Type;
      switch (this.ability.actionType) {
        case WT.Entity.Action.TASKFORCE_RAID_ANIMAL_DEN:
          woType = WT.WorldObject.Type.ANIMAL_DEN;
          break;
        case WT.Entity.Action.BASIC_LOOT_RESOURCES:
          woType = WT.WorldObject.Type.STOCKPILE;
          break;
        case WT.Entity.Action.TASKFORCE_FISH:
          woType = WT.WorldObject.Type.FISH_SWARM;
          break;
        case WT.Entity.Action.TRIBE_SETTLE_DOWN:
          woType = WT.WorldObject.Type.CAVES;
          break;
        case WT.Entity.Action.BASIC_ITEM_EXCHANGE:
        case WT.Entity.Action.TASKFORCE_JOIN_TRIBE:
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.target.selection = (this.pm as WTEPlayerManager).tribes[0]?.wtid;
          return (this.pm as WTEPlayerManager).tribes;
        default:
          return (this.wm as WTEWorldManager).worldObjects;
      }

      if (woType == WT.WorldObject.Type.CAVES) {
        let list = (this.wm as WTEWorldManager).worldObjects.filter(
          (item: WorldObject) =>
            item.woType == woType ||
            item.woType == WT.WorldObject.Type.LEDGE ||
            item.woType == WT.WorldObject.Type.ABANDONED_SHELTERS
        );
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        if (list[0]) this.target.selection = list[0].wtid;
        return list;
      }
      //@ts-ignore
      else if (woType) {
        let list = (this.wm as WTEWorldManager).worldObjects.filter(
          (item: WorldObject) => item.woType == woType
        );
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        if (list[0]) this.target.selection = list[0].wtid;
        return list;
      }
      return undefined;
    },

    showCheckItemsTargetAmount() {
      let compare = this.ability.actionType;
      let check = [
        WT.Entity.Action.TASKFORCE_RAID_ANIMAL_DEN,
        WT.Entity.Action.TASKFORCE_HUNT_WILDLIFE,
        WT.Entity.Action.BASIC_ITEM_EXCHANGE,
        WT.Entity.Action.BASIC_LOOT_RESOURCES,
        WT.Entity.Action.TASKFORCE_FISH,
      ];
      if (compare == WT.Entity.Action.BASIC_ITEM_EXCHANGE) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.inputValues.targetAmount = this.ability.entity.inventory.getSize();
      } else if (compare == WT.Entity.Action.TRIBE_CREATE_TASKFORCE) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.inputValues.targetAmount = 5;
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.inputValues.targetAmount = this.ability.entity.inventory.getFreeSpace();
      }
      if (WTHelpers.checkForEnumActionMatch(compare, check)) return true;
      return false;
    },

    showCheckItemsConsumeAmount() {
      let compare = this.ability.actionType;
      let check = [
        WT.Entity.Action.BASIC_FOOD_CONSUME,
        WT.Entity.Action.TRIBE_GROW_TRIBE,
      ];
      if (this.ability.entity.population) {
        if (compare == WT.Entity.Action.TRIBE_GROW_TRIBE) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.inputValues.consumeAmount = this.ability.entity.population.foodForSaturation(
            true
          );
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.inputValues.consumeAmount = this.ability.entity.population.foodForSaturation();
        }
      }
      if (WTHelpers.checkForEnumActionMatch(compare, check)) return true;
      return false;
    },
  },
  methods: {
    ...mapActions(["awtGameStep", "awtStartGame", "aEngineInjectDevActions"]),
    ...mapMutations([""]),
    closeOverlay() {
      let payload = { showOverlay: false };
      this.$store.commit("mInteractionCreatedActionOverlay", payload);
    },
    createActionButton() {
      let input = new WTEAInput(this.ability.entity);
      input.ability = this.ability;
      input.pushActionToQueue = true;

      switch (input.ability.actionType) {
        case WT.Entity.Action.BASIC_MOVE:
          input.target = new WTTarget(this.wgsInput);
          break;
        case WT.Entity.Action.BASIC_FOOD_CONSUME:
          input.maxSaturation = this.switches.maxSaturation;
          break;
        case WT.Entity.Action.TASKFORCE_HUNT_WILDLIFE:
        case WT.Entity.Action.TASKFORCE_GATHER:
          input.target = new WTTarget(this.wgsInput);
          break;
        case WT.Entity.Action.TRIBE_GROW_TRIBE:
        case WT.Entity.Action.TRIBE_SPLIT_TRIBE:
          break;
        case WT.Entity.Action.TASKFORCE_JOIN_TRIBE:
          // eslint-disable-next-line no-case-declarations
          let tribe = (this.em as WTEntitiesManager).get(this.target.selection);
          if (!tribe) {
            tribe = (this.pm as WTEPlayerManager).tribes[0];
          }
          input.tEntity = tribe;
          break;
        case WT.Entity.Action.TRIBE_CREATE_TASKFORCE:
          input.taskForceType =
            this.taskForceType.selection == 0
              ? WT.Entity.Type.HUNTERS
              : WT.Entity.Type.GATHERERS;
          break;
        default:
          throw new Error("The fail is strong in you!");
      }

      input.loopAction = this.switches.loop;
      input.startNow = this.switches.startNow;
      input.targetAmount = this.inputValues.targetAmount;
      input.consumeAmount = this.inputValues.consumeAmount;

      console.warn(input);
      this.$store.dispatch("aInteractionCreateAction", input);
      let overlayPayload = { showOverlay: false };
      this.$store.commit("mInteractionCreatedActionOverlay", overlayPayload);
    },
    cameraFlyToUnit() {
      let target = (this.wt as Worldtrix).em.get(888)?.wgs;
      this.$store.dispatch("aCsCameraFlyToLongLat", target);
    },

    cameraFlyToTarget() {
      let target = (this.wt as Worldtrix).em.get(8675)?.wgs;
      this.$store.dispatch("aCsCameraFlyToLongLat", target);
    },
    templateFunction(event: any) {
      console.log("breakpoint " + event);
    },
    setEntitySelectionID() {
      this.entity.selection = this.ability.entity.wtid;
      console.log("Selected WTEntity: " + this.entity.selection);
    },
  },

  created: function() {
    this.setEntitySelectionID();
  },
  mounted: function() {},
});
