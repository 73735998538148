























import Vue from "vue";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

import TribeListItem from "@/components/lists/items/TribeListItem.vue";

export default Vue.extend({
  name: "TribeList",
  components: { TribeListItem },
  props: {},
  data: () => ({
    uiItems: {
      playerTribes: {
        title: "Your tribes",
        active: true,
      },
    },
  }),
  computed: {
    localComputed() {
      return "";
    },
    ...mapState({}),
    ...mapGetters(["pm", "wt", "l10n"]),
  },
  methods: {
    ...mapActions(["aCsCameraFlyToSelection"]),
    ...mapMutations(["mGameInteractionSelectionPush"]),
  },
  created: function() {
    //console.log(this)
  },
  mounted: function() {},
});
