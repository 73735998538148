























import TaskForceListItem from "@/components/lists/items/TaskForceListItem.vue";

import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import Vue from "vue";

export default Vue.extend({
  name: "TaskForcesList",
  components: { TaskForceListItem },
  props: {},
  data: () => ({
    uiItems: {
      playerTaskForces: {
        title: "Your task forces",
        active: true,
      },
    },
  }),
  computed: {
    localComputed() {
      return "";
    },
    ...mapState({
      taskForces: (state) => state.wt.wt.pm.taskForces,
    }),
    ...mapGetters(["l10n"]),
  },
  methods: {
    ...mapActions([""]),
    ...mapMutations([""]),
  },
  created: function() {
    //console.log(this)
  },
  mounted: function() {},
});
