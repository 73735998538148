export default class TerrainObject {
    gModel: string = "GFX_CURSOR_360DEG_1S"
    gScale: number = 1
    wgs: Array<Number> = [0, 0, 0]

    constructor(gModel: string, gScale: number, wgs: Array<Number>) {
        this.gModel = gModel
        this.gScale = gScale
        this.wgs[0] = wgs[0]
        this.wgs[1] = wgs[1]
        this.wgs[2] = wgs[2]
    }
} 