const widget = require('../../node_modules/cesium/Build/Cesium/Widgets/widgets.css')
const Cesium = require('../../node_modules/cesium/Source/Cesium')

// use export all
export default Cesium

// initial manual import from Cesium ES6 migration. Not sure if/how tree-shaking is currently working
// test on 2019-11-29 seems to have not effect
// import { Cartesian2, Cartesian3, Cartographic, Ellipsoid, Transforms, ImageMaterialProperty, ColorMaterialProperty, HeightReference, ScreenSpaceEventType, Rectangle, PolygonHierarchy, createTileMapServiceImageryProvider, CallbackProperty, GeometryInstance, RectangleGeometry, buildModuleUrl, TileMapServiceImageryProvider, JulianDate, SingleTileImageryProvider, PostProcessStageLibrary, EllipsoidSurfaceAppearance, GeoJsonDataSource, Ion, Primitive, Color, defined, Model, Math, Viewer, Material, Entity, HeadingPitchRoll } from '../../node_modules/cesium/Source/Cesium'

// export default {
//     Cartesian2, Cartesian3, Cartographic, Ellipsoid, Transforms, ImageMaterialProperty, ColorMaterialProperty, HeightReference, ScreenSpaceEventType, Rectangle, PolygonHierarchy, createTileMapServiceImageryProvider, CallbackProperty, GeometryInstance, RectangleGeometry, buildModuleUrl, TileMapServiceImageryProvider, JulianDate, SingleTileImageryProvider, PostProcessStageLibrary, EllipsoidSurfaceAppearance, GeoJsonDataSource, Ion, Primitive, Color, defined, Model, Math, Viewer, Material, Entity, HeadingPitchRoll
// }

