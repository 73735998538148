// ------------ vue core ------------ //
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

// ------------- other -------------- //
//import vueshortkey from './plugins/vue-shortkey'
const vueshortkey = require("vue-shortkey");
Vue.use(vueshortkey, { prevent: ["input", "textarea"] });

import VueCompositionAPI from "@vue/composition-api";
Vue.use(VueCompositionAPI);

// ------------- cesium ------------- //
import Cesium from "./plugins/cesium";
Cesium.Ion.defaultAccessToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmNWZhMzRjYS1hMjJlLTQ4ZmEtODFhYy1lZmU3NjE4OTlmM2MiLCJpZCI6Mjc3MCwiaWF0IjoxNTM0NDk1NDQ0fQ.egjhbscoaXNxMxudm3UtaD8b8I2XkesYanG5WdbfdDY";

// *********** vue config *********** //
Vue.config.productionTip = false;

new Vue({
  vuetify,
  store,
  render: (h) => h(App),
  router,
  created() {
    this.$store.dispatch("aVuexLoadMinGameStore").then(() => {});
    this.$store.commit("mStoreTescht");
  },
  mounted() {},
}).$mount("#app");
