















































import Vue from "vue";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

import WTEAction from "@/ts/wt/interaction/WTEAction";

export default Vue.extend({
  name: "ActionListItem",
  components: {},
  props: {
    // problem is the abstract constructor not sure wh0t happens due to this
    action: { type: WTEAction, required: true },
    aIndex: { type: Number, required: true },
  },
  data: (instance: { action: WTEAction }) => ({
    dataAction: instance.action as WTEAction,
    forceUpdateID: -1,
    loopAction: false,
    isRunning: false,
  }),
  setup() {},
  computed: {
    ...mapState({}),
    ...mapGetters(["l10n", "settings"]),
    isActiveBtnColor() {
      if (this.aIndex == 0) {
        return this.action.started ? "info" : "primary";
      } else {
        return "error";
      }
    },
  },
  watch: {
    dataAction() {
      this.loopAction = this.dataAction.loopAction;
    },
  },
  methods: {
    ...mapActions([""]),
    ...mapMutations([]),
    startAction() {
      console.warn(this.$data.dataAction);
      (this.action as WTEAction).entity.startNextAction();
    },
    cancelAction() {
      let action = this.action as WTEAction;
      console.warn("Cancel action clicked...");
      console.warn(action);
      if (action.started) {
        action.cancel();
      } else {
        this.$store.dispatch("aRemoveActionFromEntityQueue", action);
      }
    },
    changeLoopAction() {
      let payload = {
        action: this.action,
        value: this.loopAction,
        aIndex: this.aIndex,
      };
      this.$store.commit("mChangeLoopAction", payload);
      console.log("loopChange");
    },
    actionClick() {
      //TODO: Open details window for the action which was clicked on
      // console.log(
      //   "ABILITY clicked but do not know why... aIndex: " + this.aIndex
      // );
    },
  },
  created: function() {
    // maybe this is pretty dirty but we will see how it goes... but vue3 will change a lot - I think
    this.forceUpdateID = setInterval(() => this.$forceUpdate(), 1000);
    this.loopAction = this.dataAction.loopAction;
  },
  mounted: function() {},
  beforeDestroy: function() {
    if (this.forceUpdateID !== -1) {
      clearInterval(this.forceUpdateID);
    }
  },
});
