import WTEntity from "../entities/WTEntity";
import Worldtrix from "../Worldtrix";
import * as WT from "@/ts/wt/declaration/WTEnums";

export default class WTSelect {
  type: WT.Interaction.SelectionType = WT.Interaction.SelectionType.NONE;
  wtid: number = -1;
  readonly cTimestamp: number = Date.now();
  wtTime!: number;
  selectionTime!: number;
  entity!: WTEntity;
  wt!: Worldtrix;

  constructor(wtid?: number, wt?: Worldtrix) {
    if (wtid && wt) {
      this.wtid = wtid;
      this.wt = wt;
      this.wtTime = wt.tm.now();
      let wtEntity = wt.em.get(wtid);
      if (wtEntity) {
        this.type = WT.Interaction.SelectionType.ENTITY;
        console.warn(wtEntity.wtid + " entity selected");
        console.warn(wtEntity);
        this.entity = wtEntity;
      }
    } else {
      return;
    }
  }

  isEntity(): boolean {
    if (this.wtid == -1) return false;
    return true;
  }

  toString(): String {
    return `WTSelect(${this.wtid}, wtTime: ${this.wtTime} h, cTimestamp: ${
      this.cTimestamp
    } | WTEntity: ${this.entity!.toString()})`;
  }
}
