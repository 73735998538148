














































































































































































































import Vue from "vue";

export default Vue.extend({
  name: "News",
  components: {},
  data: () => ({
    expandGenesis: false,
    expandOrigins: false,
    expandBigBang: false,
  }),
});
