import TaskForce from "./TaskForce";
import Worldtrix from "../../Worldtrix";
import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEAbility from "../../interaction/WTEAbility";
import WTEMoveable from "../WTEMoveable";
import WTEntity from "../WTEntity";
export default class Scouts extends TaskForce {
  static MOVE_TRAVEL_MULTIPLIER = 1.5;

  constructor(wt: Worldtrix, hData?: any) {
    super(wt, hData);
    this.wt.em.pm.scouts.push(this);
    //console.warn("Scouts constructed");
  }

  initAbilities(): WTEntity {
    // Scouts travel skill is replacing the basic move skill
    this.abilities = this.abilities.filter(
      (entry) => entry.actionType != WT.Entity.Action.BASIC_MOVE
    );
    this.addAbility(
      new WTEAbility(
        WT.Entity.Action.TASKFORCE_TRAVEL,
        this,
        "Travel to",
        "Scouts can move much faster than other groups."
      )
    );
    this.moveSpeed =
      WTEMoveable.MOVE_SPEED_DEFAULT * Scouts.MOVE_TRAVEL_MULTIPLIER;
    this.addAbility(
      new WTEAbility(
        WT.Entity.Action.TASKFORCE_EXPLORE,
        this,
        "Explore area",
        "Explores the area and try to find points of interest."
      )
    );
    return this;
  }

  getAbilities(): WTEAbility[] {
    return this.abilities;
  }

  update(tStamp: number) {
    this.updateEntity(tStamp);
    this.updateTaskForce(tStamp);
    return this;
  }
}
