import { GetterTree, MutationTree, ActionTree } from "vuex";
import Cesium from "../plugins/cesium";
import store from "@/store";

import wtDataURIs from "@/../api/wtDataURIs";
import { Cartesian2 } from "cesium";
import Cartesian3 from "cesium/Source/Core/Cartesian3";

type RootState = typeof store.state;

let csViewer: Cesium.Viewer;
let csDataJS = {
  viewer: {},
  world: {
    worldRectangleNaturalEarthOcean: null,
    worldRectangleNaturalEarth: null,
  },
  WASDFlags: {
    moveForward: false,
    moveBackward: false,
    moveUp: false,
    moveDown: false,
    moveLeft: false,
    moveRight: false,
    rotateLeft: false,
    rotateRight: false,
  },
  polyDraw: {
    //drawingMode: 'line',
    isActive: false,
    handler: undefined,
    drawingMode: "Polygon",
    screenSpaceHandler: null,
    activeShapePoints: new Array<Cartesian3>(),
    activeShape: Cesium.Entity,
    floatingPoint: Cesium.Entity,
    lastDrawningType: "Polygon",
    lastDrawnShapePoints: [
      { x: 4197179.367310179, y: 815651.7765823915, z: 4716893.205951836 },
      { x: 4197126.710326455, y: 815657.6058723561, z: 4716938.745673406 },
      { x: 4197122.206617834, y: 815771.2976076199, z: 4716923.196777109 },
    ],
  },
  cursor: {
    feature: -1,
    lastMouseClickPosition: {
      lat: -42,
      long: -42,
      flag: -1,
      updated: -1,
    },
  },
  interaction: {
    hover: null,
    selection: {
      last: null,
    },
  },
  settings: {
    camera: {
      keyboardRotationSpeed: 0.04,
      keyboardMovementSpeed: 1 / 80.0,
    },
  },
  dataSources: [],
  materials: [],
  csSceneModels: [],
  handler: Cesium.ScreenSpaceEventHandler,
  worldRectangleNaturalEarthOcean: Cesium.Primitive,
};

class State {
  cursor = {
    featureWtids: -1,
    lastMouseClickPosition: {
      lat: -42,
      long: -42,
      flag: -1,
      updated: -1,
    },
  };
  polyDraw = {
    isActive: false,
    drawingMode: "Polygon",
    terrainAddJson: Object,
  };
  settings = {
    camera: {
      keyboardMovementSpeed: 4,
      keyboardRotationSpeed: 1,
    },
  };
}
const getters = <GetterTree<State, any>>{};

const actions = <ActionTree<State, any>>{
  aCsInit({ commit }) {
    commit("mInteractionReset");
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        commit("mCsInit");
        resolve();
      }, 10);
    });
  },

  aCsInitRefresh({ commit }) {
    //@ts-ignore
    csViewer.destroy();
    commit("mInteractionReset");
    commit("mCsInit");
    commit("mCsCameraFlyToTarget", {
      lat: 47.99974574,
      long: 10.99976256,
      height: 7000000,
    });
    commit("mCsDrawWorld");
  },

  aCsInitGame({ commit }) {
    commit("mCsCameraFlyToTarget", {
      lat: 47.99974574,
      long: 10.99976256,
      height: 7000000,
    });
    commit("mCsDrawWorld");
  },

  aCsViewportMouseClickEvent({ state, commit, dispatch }, mouseEvent) {
    //console.log("Mouse Event: " + mouseEvent.type);
    switch (mouseEvent.type) {
      case "mousemove":
        break;
      case "click":
        commit("mCsViewportSetMouseClickCoordinates", mouseEvent);
        dispatch("aCsCursorDraw");
        break;
    }
    if (state.polyDraw.isActive) {
      return; // skip additonal mouse event if cs polydraw listener are active
    }
    commit("mCsViewportMouseEvent", mouseEvent);
  },

  aCsEditorViewportMouseClickEvent({ state, commit, dispatch }, mouseEvent) {
    switch (mouseEvent.type) {
      case "mousemove":
        break;
      case "click": //left click
        commit("mCsViewportSetMouseClickCoordinates", mouseEvent);
        commit("mEditorTerrainPointStackPrecalculate");
        if (store.state.interaction.objectsAdd.onClick) {
          dispatch("aInteractionObjectAdd", {
            gType: store.state.interaction.objectsAdd.gType,
          });
          return; // don't want any additonal things to happen after add object event
        }
        dispatch("aCsCursorDraw");
        break;
      case "contextmenu":
        if (store.state.interaction.objectsAdd.onClick) {
          let check = csViewer.scene.primitives.remove(
            //@ts-ignore
            csDataJS.interaction.hover.primitive
          );
          //console.log("Delection right click " + check);
          commit("mInteractionObjectRemove");
          return;
        }
        break;
    }
    if (state.polyDraw.isActive) {
      return; // skip additonal mouse event if cs polydraw listener are active
    }
    commit("mCsViewportMouseEvent", mouseEvent);
  },

  aCsCursorDraw({ state }) {
    let scene = csViewer.scene;
    let lat = state.cursor.lastMouseClickPosition.lat;
    let long = state.cursor.lastMouseClickPosition.long;
    let eHeight = -4;
    let eMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(
      Cesium.Cartesian3.fromDegrees(long, lat, eHeight)
    );
    let gfxType = "GFX_CURSOR_360DEG_1S";
    csDataJS.cursor.feature == -1
      ? true
      : scene.primitives.remove(csDataJS.cursor.feature);
    let model = Cesium.Model.fromGltf({
      id: "cursorP1",
      url: wtDataURIs.getgModelURIFromTyp(gfxType),
      modelMatrix: eMatrix,
      scale: 1,
      silhouetteColor: Cesium.Color.ORANGE,
      silhouetteSize: parseFloat("3"),
    });
    (csDataJS as any).csSceneModels["cursorP1"] = scene.primitives.add(model);
    //state.cursor.featureWtids = model.id;
    csDataJS.cursor.feature = model;

    // Example 3. Add an animation and provide all properties and events
    // let startTime = Cesium.JulianDate.now();
    // Cesium.when(model.readyPromise).then(function (model) {
    //   let animation = model.activeAnimations.addAll({
    //     name: 'another animation name',
    //     loop: Cesium.ModelAnimationLoop.REPEAT,
    //     delay: 0.0,
    //     reverse: false,
    //     removeOnStop: false
    //   });
    // });
  },

  aCsDrawTerrainObject({ state }, objectInfo) {
    let scene = csViewer.scene;
    let lat = objectInfo.position.lat;
    let long = objectInfo.position.long;
    let eHeight = objectInfo.position.height;
    let eMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(
      Cesium.Cartesian3.fromDegrees(long, lat, eHeight)
    );
    let gfxType = objectInfo.gType;
    let model = Cesium.Model.fromGltf({
      url: wtDataURIs.getgModelURIFromTyp(gfxType),
      modelMatrix: eMatrix,
      scale: objectInfo.scale,
      silhouetteColor: store.state.settings.gfx.modelSilhouettes.color,
      silhouetteSize: store.state.settings.gfx.modelSilhouettes.size,
    });
    scene.primitives.add(model);
  },

  aInteractionObjectAdd({ commit, dispatch, state }, inputInfo) {
    //console.log("interaction add");
    let twtid = store.state.interaction.selection.current;
    if (twtid == -1) {
      //alert("Not terrain selected!")
      //console.info("aInteractionObjectAdd - terrain not selected.")
      return;
    }
    let objectsData = store.state.dataLoad.wtWorldData.wtWorldTerrainObjects.objectsDataArray.find(
      //@ts-ignore
      (obj) => obj.pwtid === twtid
    ) as any;
    let owtid = 0;
    if (typeof objectsData !== "undefined") {
      owtid = objectsData.length;
    }
    let objectInfo = {
      pwtid: twtid,
      objectsData: objectsData,
      position: {
        long: state.cursor.lastMouseClickPosition.long,
        lat: state.cursor.lastMouseClickPosition.lat,
        height: 0,
      },
      gType: inputInfo.gType,
      scale: 2 + 8 * Math.random(),
    };

    dispatch("aCsDrawTerrainObject", objectInfo);
    commit("mInteractionObjectAdd", objectInfo);
  },

  aCsCameraFlyToTarget({ commit }) {
    commit("mCsCameraFlyToTarget");
  },

  aCsCameraFlyToSelection({ state, dispatch }, wtid: number) {
    let wtEntity = store.state.wt.wt.em.get(wtid);
    if (!wtEntity) return;
    let wgs = wtEntity.wgs;
    if (typeof wgs === "undefined") {
      //@ts-ignore
      let csEntity = csDataJS.dataSources[0].entities.getById(wtEntity.cid);
      //console.log("failomatik " + wtEntity.cid);
      csViewer.flyTo(csEntity, {
        duration: store.state.settings.UI.csCamera.flyDuration,
      });
      return;
    }
    //let initialOrientation = new Cesium.HeadingPitchRoll.fromDegrees(21.27879878293835, -21.34390550872461, 0.0716951918898415);
    let position = new Cesium.Cartographic(
      Cesium.Math.toRadians(wgs[0]),
      Cesium.Math.toRadians(wgs[1]),
      store.state.settings.UI.csCamera.targetHeight + wgs[2]
    );
    let cartesianPosition = Cesium.Ellipsoid.WGS84.cartographicToCartesian(
      position
    );
    let flyTarget = {
      destination: cartesianPosition,
      //orientation: initialOrientation,
      duration: store.state.settings.UI.csCamera.flyDuration,
    };
    csViewer.scene.camera.flyTo(flyTarget);
  },

  aCsCameraFlyToLongLat({ state }, target) {
    //console.log("I don't understand the Javascript & Vuex context!");
    //console.log(csDataJS);

    let height = store.state.settings.UI.csCamera.targetHeight;
    if (typeof target[2] !== undefined || target[2] === "") {
      height = Number.parseInt(target[2]);
    }

    //let initialOrientation = new Cesium.HeadingPitchRoll.fromDegrees(21.27879878293835, -21.34390550872461, 0.0716951918898415);
    let position = new Cesium.Cartographic(
      Cesium.Math.toRadians(target[0]),
      Cesium.Math.toRadians(target[1]),
      height
    );
    let cartesianPosition = Cesium.Ellipsoid.WGS84.cartographicToCartesian(
      position
    );
    let flyTarget = {
      destination: cartesianPosition,
      //orientation: initialOrientation,
      duration: store.state.settings.UI.csCamera.flyDuration,
    };
    csViewer.scene.camera.flyTo(flyTarget);
  },

  aKeyboardEvent({ state }, vueKey) {
    //console.log("aKeyboardEvent: " + vueKey.keyEvent.key + ", " + vueKey.keyEvent.type);
    let shortkeys = store.state.settings.keyboard;
    switch (vueKey.keyEvent.key) {
      case shortkeys.cameraMovement.moveForward:
        csDataJS.WASDFlags.moveForward = vueKey.isKeyDown;
        break;
      case shortkeys.cameraMovement.moveBackward:
        csDataJS.WASDFlags.moveBackward = vueKey.isKeyDown;
        break;
      case shortkeys.cameraMovement.moveUp:
        csDataJS.WASDFlags.moveUp = vueKey.isKeyDown;
        break;
      case shortkeys.cameraMovement.moveDown:
        csDataJS.WASDFlags.moveDown = vueKey.isKeyDown;
        break;
      case shortkeys.cameraMovement.moveLeft:
        csDataJS.WASDFlags.moveLeft = vueKey.isKeyDown;
        break;
      case shortkeys.cameraMovement.moveRight:
        csDataJS.WASDFlags.moveRight = vueKey.isKeyDown;
        break;
      case shortkeys.cameraMovement.rotateLeft:
        csDataJS.WASDFlags.rotateLeft = vueKey.isKeyDown;
        break;
      case shortkeys.cameraMovement.rotateRight:
        csDataJS.WASDFlags.rotateRight = vueKey.isKeyDown;
        break;
    }
  },
};

const mutations = <MutationTree<State>>{
  mCsInit(state) {
    // create imageTileService based on settings
    let initImageProvider = null;
    let naturalEarthWorld =
      store.state.settings.gfx.naturalEarthResolutions.world;
    if (naturalEarthWorld > 0) {
      let layerPath = wtDataURIs.getgNaturalEarthWorlds()[naturalEarthWorld];
      initImageProvider = new Cesium.SingleTileImageryProvider({
        url: Cesium.buildModuleUrl(layerPath),
        rectangle: Cesium.Rectangle.fromDegrees(-180.0, -90.0, 180.0, 90.0),
      });
    } else {
      initImageProvider = new Cesium.TileMapServiceImageryProvider({
        url: Cesium.buildModuleUrl("Assets/Textures/NaturalEarthII"),
      });
    }

    let viewerData = {
      //eslint-disable-line
      scene3DOnly: true,
      selectionIndicator: false,
      baseLayerPicker: false,
      infoBox: false,
      geocoder: false,
      timeline: false,
      animation: false,
      targetFrameRate: store.state.settings.gfx.fpsTarget,
      navigationHelpButton: false,
      fullscreenButton: false,
      shadows: true,
      homeButton: false,
      creditContainer: "creditDiv",
      imageryProvider: initImageProvider,
    };

    let viewer = new Cesium.Viewer("cesiumContainer", viewerData);
    fCsInitCheckFeatures(viewer, store.state);

    let imageLayers = viewer.scene.imageryLayers;

    // Magic Layers - Use nice Matrix effectr
    // https://sandcastle.cesium.com/index.html?src=Imagery%2520Layers%2520Manipulation.html
    if (store.state.settings.gfx.layers.grid.active) {
      imageLayers.addImageryProvider(
        new Cesium.GridImageryProvider(),
        1.0,
        false
      );
    }

    //imageLayers.addImageryProvider(new Cesium.TileCoordinatesImageryProvider(), 1.0, false);

    //update csDataJS.for async runs from store
    csViewer = viewer;
    csViewer = viewer;
    csDataJS.settings.camera = store.state.settings.UI.csCamera;

    // needed to put focus on the canvas because it is lost each time a vue component is clicked
    viewer.canvas.setAttribute("tabindex", "0");
    viewer.canvas.onclick = function() {
      viewer.canvas.focus();
    };

    viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
      Cesium.ScreenSpaceEventType.RIGHT_CLICK
    );
    viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
      Cesium.ScreenSpaceEventType.RIGHT_UP
    );
    viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
      Cesium.ScreenSpaceEventType.RIGHT_DOWN
    );

    // Remove default base layer
    viewer.clock.shouldAnimate = true; // default
    viewer.clock.currentTime = Cesium.JulianDate.fromIso8601(
      "2017-07-11T09:00:00Z"
    );

    viewer.scene.debugShowFramesPerSecond = store.state.settings.gfx.fpsShow;
    viewer.scene.globe.enableLighting = true;
    viewer.scene.camera.frustum.near = 0.01;
    viewer.scene.postProcessStages.fxaa.enabled = true;

    fCsInitCameraKeyboardMovement();
    fCsInitMaterials();
  },

  mCsInitVueStackCesium(state) {
    const imageryViewModels: any = [];
    imageryViewModels.push(
      new Cesium.ProviderViewModel({
        name: "Natural Earth\u00a0II",
        iconUrl: Cesium.buildModuleUrl(
          "Widgets/Images/ImageryProviders/naturalEarthII.png"
        ),
        tooltip:
          "Edited Natural Earth II better colors and contrast.\nhttp://www.naturalearthdata.com/",
        creationFunction: function() {
          return new Cesium.TileMapServiceImageryProvider({
            url: Cesium.buildModuleUrl("Assets/Textures/NaturalEarthII"),
          });
        },
      })
    );

    const viewerData = {
      targetFrameRate: store.state.settings.gfx.fpsTarget,
      fullscreenButton: false,
      imageryProviderViewModels: imageryViewModels, // added your Cesium Ion Token (main.ts) and remove this to get access to all layers
    };
    csViewer = new Cesium.Viewer("cesiumContainer", viewerData);
    csViewer.scene.debugShowFramesPerSecond = true;
    console.log("mCsInit");
  },

  // ---------------------- interaction - cesium ---------------------- //
  mCsViewportMouseEvent(state, mouseEvent) {
    //console.log("CesiumViewer.vue mouse action triggered... ")
    let viewer = csViewer;
    let colorSelection =
      store.state.settings.UI.csHighlightingSilhouettes.selection;
    let colorHovering =
      store.state.settings.UI.csHighlightingSilhouettes.hovering;
    let colorModels = store.state.settings.gfx.modelSilhouettes;

    let stateSelectionLast = store.state.interaction.selection.current;
    let stateHover = store.state.interaction.hover;

    let mousePos = {
      x: mouseEvent.offsetX,
      y: mouseEvent.offsetY,
    };
    let pickedFeature = viewer.scene.pick(mousePos as Cartesian2);

    //check if there is something if not make sure last hover is removed
    if (!Cesium.defined(pickedFeature)) {
      if (stateHover.wtid !== -1) {
        fCsSelectionSetTerrainSilhouette(
          //@ts-ignore
          store.state,
          stateHover.wtid,
          colorModels
        );
        store.state.interaction.hover.wtid = -1;
      }
      if (mouseEvent.which === 3) {
        fCsSelectionSetTerrainSilhouette(
          //@ts-ignore
          store.state,
          stateSelectionLast,
          colorModels
        );
        store.state.interaction.selection.current = -1;
      }
      return;
    }

    let wtidPicked = pickedFeature.id;

    //skipp if cursor is selected
    if (wtidPicked === "cursor1!player1") {
      return;
    }

    //console.log(wtidPicked);

    //Change texture demo of cesium entities
    if (
      wtidPicked !== undefined &&
      wtidPicked._name == "Change Texture of material demo"
    ) {
      let imageForestMaterial = new Cesium.ImageMaterialProperty({
        image: "gfx/textures/icons8-forest-filled-green-100.png",
        repeat: new Cesium.Cartesian2(1, 3),
      });
      //console.log(viewer.entities.getById(wtidPicked._id).polygon.material);
      viewer.entities.getById(
        wtidPicked._id
      ).polygon.material = imageForestMaterial;
      return;
    }

    //@ts-ignore
    if (typeof wtidPicked === "wt-ocean-water") {
      return;
    }

    // fly to by using alt
    if (mouseEvent.ctrlKey) {
      if (typeof wtidPicked === "object") {
        viewer.flyTo(wtidPicked, {
          duration: store.state.settings.UI.csCamera.flyDuration,
        });
      } else if (typeof wtidPicked == "number") {
        //fly to gltf model with mutation
        //@ts-ignore
        let object = fWtidFindTerrainObject(store.state, wtidPicked).object;
        let long = object.wgs[0];
        let lat = object.wgs[1];
        let position = new Cesium.Cartographic(
          Cesium.Math.toRadians(long),
          Cesium.Math.toRadians(lat),
          object.wgs[2] + 150
        );
        let flyTarget = {
          destination: Cesium.Ellipsoid.WGS84.cartographicToCartesian(position),
          duration: store.state.settings.UI.csCamera.flyDuration,
        };
        viewer.scene.camera.flyTo(flyTarget);
      }
      return;
    }

    if (typeof wtidPicked == "number" && mouseEvent.which == 1) {
      //clicked a gltf object
      //console.log("gltf click " + wtidPicked)
      if (!wtidPicked) return;
      //@ts-ignore
      let pwtid = fWtidFindTerrainObject(state, wtidPicked).pwtid;
      if (stateSelectionLast !== -1 && stateSelectionLast !== pwtid) {
        fCsSelectionSetTerrainSilhouette(
          state,
          stateSelectionLast,
          colorModels
        );
      }
      fCsSelectionSetTerrainSilhouette(state, pwtid, colorSelection);
      stateHover.wtid = -1;
      fSelectionViewportClick(state, pwtid);
      store.state.interaction.selection.current = pwtid;
      return;
    }

    if (typeof wtidPicked == "number") {
      //hovering a gltf object
      //@ts-ignore
      let pwtid = fWtidFindTerrainObject(state, wtidPicked).pwtid;
      if (pwtid === stateSelectionLast) {
        //console.log("gltf hovering selected")
        return;
      }
      //console.log("gltf hover " + wtidPicked + " from " + pwtid)
      if (stateHover.wtid !== -1 && stateHover.wtid !== pwtid) {
        fCsSelectionSetTerrainSilhouette(state, stateHover.wtid, colorModels);
      }
      fCsSelectionSetTerrainSilhouette(state, pwtid, colorHovering);
      stateHover.wtid = wtidPicked;
      csDataJS.interaction.hover = pickedFeature;
      return;
    }

    if (typeof wtidPicked === "object" && mouseEvent.which == 1) {
      //clicked on a terrain item
      let twtid = wtidPicked.valueOf().properties.getValue().entity.wtid;
      //console.log("terrain click " + twtid)
      if (stateSelectionLast !== -1 && stateSelectionLast !== twtid) {
        fCsSelectionSetTerrainSilhouette(
          state,
          stateSelectionLast,
          colorModels
        );
      }
      fCsSelectionSetTerrainSilhouette(state, twtid, colorSelection);
      stateHover.wtid = -1;
      fSelectionViewportClick(state, twtid);
      store.state.interaction.selection.current = twtid;
      return;
    }

    if (typeof wtidPicked === "object") {
      //hovering a terrain item
      let twtid = wtidPicked.valueOf().properties.getValue().entity.wtid;
      if (twtid === stateSelectionLast) {
        //console.log("terrain hovering selected")
        return;
      }
      //console.log("terrain hover " + twtid)
      if (stateHover.wtid !== -1 && stateHover.wtid !== twtid) {
        fCsSelectionSetTerrainSilhouette(state, stateHover.wtid, colorModels);
      }
      fCsSelectionSetTerrainSilhouette(state, twtid, colorHovering);
      stateHover.wtid = twtid;
      return;
    }

    if (mouseEvent.which == 1 && wtidPicked === stateHover.wtid) {
      return; // CASE: mouse is hovering the already highlighted hover feature
    }
  },

  mCsPolyDrawStateUpdate(state, newState) {
    if (newState) {
      //console.log("Polydraw-Init");
      state.polyDraw.isActive = true;
      //@ts-ignore
      state.polyDraw.terrainAddJson = fDataCreateTerrainJSONFeature();
      fCsPolyDrawInit(store.state);
    } else {
      //console.log("Polydraw-Stop");
      state.polyDraw.isActive = false;
      (state.polyDraw as any).terrainAddJson = "Drawing stopped.";
      fCsPolyDrawStop();
    }
  },

  mInteractionReset(state) {
    store.state.interaction.hover.wtid = -1;
    store.state.interaction.objectsAdd.onClick = false;
  },

  mInteractionObjectsAddSettings(state, newSetting) {
    //console.log("Object on-Click " + newSetting.onClick + ", " + newSetting.gType);
    store.state.interaction.objectsAdd.onClick = newSetting.onClick;
    store.state.interaction.objectsAdd.gType = newSetting.gType;
  },

  mInteractionObjectAdd(state, objectInfo) {
    //console.log("mInteractionObjectAdd for object " + objectInfo.gType)
    let tObject = {
      wtid: objectInfo.wtid,
      gModel: objectInfo.gType,
      durability: 1,
      gScale: objectInfo.scale,
      wgs: [
        objectInfo.position.long,
        objectInfo.position.lat,
        objectInfo.position.height,
      ],
    };
    objectInfo.objectsData.objects.push(tObject);
  },

  mInteractionObjectRemove(state) {
    let hovering = store.state.interaction.hover;
    //console.log("mInteractionObjectRemove for interaction hover " + hovering.wtid)
    // if (hovering === "wtWorldTerrainObject") {
    //   let objectsData = store.state.dataLoad.wtWorldData.wtWorldTerrainObjects.objectsDataArray.find(
    //     (object) => object.pwtid === hovering.pwtid
    //   );
    //   let remainingObjects = objectsData.objects.filter(
    //     (entry) => entry.wtid !== hovering.wtid
    //   );
    //   objectsData.objects = remainingObjects;
    // }
  },

  mCsPolyDrawChangeMode(state, mode: string) {
    csDataJS.polyDraw.drawingMode = mode;
    state.polyDraw.drawingMode = mode;
  },

  mCsPolyDrawAddTerrain(state, GFX_TYPE) {
    //console.log("mCsPolyDrawAddTerrain called for " + GFX_TYPE)
    let polyDraw = csDataJS.polyDraw;
    (state.polyDraw.terrainAddJson as any).properties.tType = GFX_TYPE;
    //console.log("PolyDraw object:" + JSON.stringify(polyDraw));
    let cartesianArray = CsHelpCartesian3ArrayToCartographicArray(
      (polyDraw as any).lastDrawnShapePoints
    );
    fDataAddTerrainToStore(
      //@ts-ignore
      state,
      cartesianArray,
      csDataJS.polyDraw.lastDrawningType
    );
    //state.polyDraw.terrainAddJson = fDataCreateTerrainJSONFeature();
  },

  // Calculation of long-lat in Cesium.viewer for this mouse event
  mCsViewportSetMouseClickCoordinates(state, mouseEvent) {
    let viewer = csViewer;
    //console.log("breakpoint");
    // calcuation need the elements coordinates of the cesium viewport div
    // https://www.w3schools.com/jsref/obj_mouseevent.asp
    let clickPosition = new Cesium.Cartesian2(
      mouseEvent.offsetX,
      mouseEvent.offsetY
    );
    let ellipsoid = viewer.scene.globe.ellipsoid;
    let cartesian = viewer.camera.pickEllipsoid(clickPosition, ellipsoid);
    // https://groups.google.com/forum/#!topic/cesium-dev/mwW1mQ7kKgw

    if (cartesian) {
      let cartographic = ellipsoid.cartesianToCartographic(cartesian);
      let latitude = Cesium.Math.toDegrees(cartographic.latitude);
      let longitude = Cesium.Math.toDegrees(cartographic.longitude);
      let clickPos = {
        lat: latitude,
        long: longitude,
        flag: 1,
        updated: 1,
      };
      //console.log(clickPos);
      state.cursor.lastMouseClickPosition = clickPos;
      csDataJS.cursor.lastMouseClickPosition = clickPos;
    } else {
      state.cursor.lastMouseClickPosition.updated = -1;
      csDataJS.cursor.lastMouseClickPosition.flag = -1;
      //console.log('Globe was not picked');
    }
  },

  mCsDrawWorld(state) {
    //fCsDrawWorldObjects(state);
    let naturalEarthWorld =
      store.state.settings.gfx.naturalEarthResolutions.world;
    // world texture is drawn in mCsInit
    if (store.state.settings.gfx.animateWater)
      fCsDrawWorldNaturalEarthOcean(
        wtDataURIs.gNaturalEarthIISpectrals()[
          store.state.settings.gfx.naturalEarthResolutions.spectral
        ]
      );
    //@ts-ignore
    fCsDrawWorldTerrain(state);
  },

  mCsCameraFlyToTarget(state, target) {
    if (typeof target.height == "undefined") {
      target.height = store.state.settings.UI.csCamera.targetHeight;
    }
    //let initialOrientation = new Cesium.HeadingPitchRoll.fromDegrees(21.27879878293835, -21.34390550872461, 0.0716951918898415);
    let position = new Cesium.Cartographic(
      Cesium.Math.toRadians(target.long),
      Cesium.Math.toRadians(target.lat),
      target.height
    );
    let cartesianPosition = Cesium.Ellipsoid.WGS84.cartographicToCartesian(
      position
    );
    let flyTarget = {
      destination: cartesianPosition,
      //orientation: initialOrientation,
      duration: store.state.settings.UI.csCamera.flyDuration,
    };
    csViewer.scene.camera.flyTo(flyTarget);
  },
};

// this is requried because cesium objects are killing webpack with stack overflows
const cesiumStore = {
  namespaced: false,
  modules: {},
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};

//*********************************** CESIUM - FUNCTIONS *********************************** //
//++++++++++++++++++++++++++++++++++ Cesium Initial loading ++++++++++++++++++++++++++++++++++ //
function fCsInitCheckFeatures(viewer: any, state: RootState) {
  if (!Cesium.PostProcessStageLibrary.isSilhouetteSupported(viewer.scene)) {
    console.error(
      "NOT SUPPORTED FEATURE - Cesium.PostProcessStageLibrary.isSilhouetteSupported()"
    );
    return false;
  }
  if (!Cesium.Entity.supportsPolylinesOnTerrain(viewer.scene)) {
    console.error(
      "NOT SUPPORTED FEATURE - Cesium.Entity.supportsPolylinesOnTerrain()"
    );
    return false;
  }
}

function fCsInitMaterials() {
  //console.error("MATERPOINT");
  let textureObject = wtDataURIs.getgTextureURIsObject() as any;
  for (let textureEntry in textureObject) {
    //@ts-ignore
    csDataJS.materials[textureEntry] = new Cesium.ImageMaterialProperty({
      image: wtDataURIs.getgTextureURI(textureEntry),
      repeat: new Cesium.Cartesian2(3, 3),
    });
  }
}

function fCsInitCameraKeyboardMovement() {
  csViewer.clock.onTick.addEventListener(function(clock) {
    let camera = csViewer.camera;
    let width = csViewer.canvas.clientWidth;
    let height = csViewer.canvas.clientHeight;
    let center = { x: width / 2, y: height / 2 } as Cartesian2;

    // Change movement speed based on the distance of the camera to the surface of the ellipsoid.
    let cameraHeight = csViewer.scene.globe.ellipsoid.cartesianToCartographic(
      camera.position
    ).height;
    let moveRate = Math.max(
      cameraHeight * csDataJS.settings.camera.keyboardMovementSpeed,
      1
    );
    let rotationSpeed = csDataJS.settings.camera.keyboardRotationSpeed;

    if (csDataJS.WASDFlags.moveForward) {
      camera.moveForward(moveRate);
    }
    if (csDataJS.WASDFlags.moveBackward) {
      camera.moveBackward(moveRate);
    }
    if (csDataJS.WASDFlags.moveUp) {
      camera.moveUp(moveRate);
    }
    if (csDataJS.WASDFlags.moveDown) {
      camera.moveDown(moveRate);
    }
    if (csDataJS.WASDFlags.moveLeft) {
      camera.moveLeft(moveRate);
    }
    if (csDataJS.WASDFlags.moveRight) {
      camera.moveRight(moveRate);
    }
    if (csDataJS.WASDFlags.rotateLeft) {
      let earthPosition = csViewer.camera.pickEllipsoid(
        center,
        csViewer.scene.globe.ellipsoid
      );
      if (earthPosition) {
        camera.rotate(earthPosition, rotationSpeed);
      }
    }
    if (csDataJS.WASDFlags.rotateRight) {
      let earthPosition = csViewer.camera.pickEllipsoid(
        center,
        csViewer.scene.globe.ellipsoid
      );
      if (earthPosition) {
        camera.rotate(earthPosition, -rotationSpeed);
      }
    }
  });
}

//++++++++++++++++++++++++++++++ Cesium EDITOR ++++++++++++++++++++++++++++++ //
let globalHeight = 0.00511; // dirty work around to prevent z-fighting
function fDataAddTerrainToStore(
  state: RootState,
  cartesianArray: any,
  featureType: any
) {
  //console.log("fDataAddTerrainToStore called.");
  let featuresArray = (state.dataLoad.wtWorldData.wtWorldTerrain
    .wtWorldFeatureCollections[0] as any).features;
  let newFeature = state.cesium.polyDraw.terrainAddJson as any;
  // fill wgs coordinates into the feature
  newFeature.geometry.type = featureType;
  for (let i = 0; i < cartesianArray.length; i++) {
    let ce = cartesianArray[i];
    let coorsAdd = [ce.long, ce.lat, ce.height + globalHeight];
    newFeature.geometry.coordinates[0].push(coorsAdd);
  }
  globalHeight += 0.00511;
  featuresArray.push(newFeature);

  //create terrain objects structure
  let tObjectsData = {
    pwtid: newFeature.properties.wtid,
    wgsOffset: [0, 0, 0],
    gtScale: 1,
    objects: [],
  };
  // state.dataLoad.wtWorldData.wtWorldTerrainObjects.objectsDataArray.push(
  //   tObjectsData
  // );
}

//++++++++++++++++++++++++++++++ Cesium Rendering ++++++++++++++++++++++++++++++ //
function fCsDrawWorldObjects(state: typeof store.state) {
  // TODO: Rework Render
  let scene = csViewer.scene;
  let drawEntities = (store.state.dataLoad.wtWorldData as any)
    .wtWorldEnvironment.wtWorldObjects as any;

  let silhouette = Cesium.PostProcessStageLibrary.createEdgeDetectionStage();
  silhouette.uniforms.color = Cesium.Color.BLACK;
  silhouette.uniforms.length = 0.2;
  silhouette.selected = [];

  for (let j = 0; j < drawEntities.length; j++) {
    let wtEntity = drawEntities[j];
    let oGArray = wtEntity.GFX.gObjs;
    let oGScale = wtEntity.GFX.gScale;
    let oGWGSHOffset = wtEntity.GFX.gWGSHOffset;
    for (let i = 0; i < oGArray.length; i++) {
      let entry = oGArray[i];
      let eLat = entry.gWGS[0] + oGWGSHOffset[0];
      let eLong = entry.gWGS[1] + oGWGSHOffset[1];
      let eHeight = entry.gHeight + oGWGSHOffset[2];
      let eMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(
        Cesium.Cartesian3.fromDegrees(eLong, eLat, eHeight)
      );
      let gfxType = entry.gModel;
      let tOid = j + "@" + i;
      let model = Cesium.Model.fromGltf({
        id: tOid,
        name: "Name it!",
        url: wtDataURIs.getgModelURIFromTyp(gfxType),
        modelMatrix: eMatrix,
        scale: oGScale,
        silhouetteColor: store.state.settings.gfx.modelSilhouettes.color,
        silhouetteSize: store.state.settings.gfx.modelSilhouettes.size,
      });
      //csDataJS.csSceneModels[tOid] = scene.primitives.add(model);
    }
  }
}

function fCsDrawWorldNaturalEarthOcean(imageurl: string) {
  let oceanWater = new Cesium.Material({
    fabric: {
      type: "Water",
      uniforms: {
        baseWaterColor: new Cesium.Color(0.0, 0.0, 0.7, 1),
        blendColor: new Cesium.Color(1.0, 1.0, 1.0, 0),
        specularMap: imageurl,
        normalMap: "Assets/Textures/waterNormals.jpg",
        frequency: 10000.0,
        animationSpeed: 0.02,
        amplitude: 5.0,
      },
    },
  });

  csDataJS.worldRectangleNaturalEarthOcean = csViewer.scene.primitives.add(
    new Cesium.Primitive({
      geometryInstances: new Cesium.GeometryInstance({
        id: "wt-ocean-water",
        geometry: new Cesium.RectangleGeometry({
          rectangle: Cesium.Rectangle.fromDegrees(-180.0, -90.0, 180.0, 90.0),
          vertexFormat: Cesium.EllipsoidSurfaceAppearance.VERTEX_FORMAT,
        }),
      }),
      appearance: new Cesium.EllipsoidSurfaceAppearance({
        material: oceanWater,
        aboveGround: false,
      }),
      show: true,
    })
  );
}

function fCsDrawWorldTerrain(state: RootState) {
  let viewer = csViewer;
  let featureCollectionsArray =
    store.state.dataLoad.wtWorldData.wtWorldTerrain.wtWorldFeatureCollections;
  let featureCounter = 1;
  let counter = 1;
  if (typeof featureCollectionsArray == "undefined") {
    return;
  }
  let tcounter = 0;
  for (let featureCollection of featureCollectionsArray) {
    //console.info("Features count: " + featureCounter++);
    let promise = Cesium.GeoJsonDataSource.load(featureCollection);
    //let promise = Cesium.GeoJsonDataSource.load(data0);
    promise
      .then(function(dataSource: any) {
        viewer.dataSources.add(dataSource);
        csDataJS.dataSources.push(dataSource as never);
        //Get the array of entities
        let entities = dataSource.entities.values;
        for (let i = 0; i < entities.length; i++) {
          //console.info("Draw terrain count: " + counter++);
          let entity = entities[i];
          let textureType = entity.properties.tType;
          //fCsGFXMaterialsDimensionXY(textureType, 5, 5);
          entity.polygon.material = csDataJS.materials[textureType];
          entity.polygon.outline = true;
          entity.polygon.outlineColor = Cesium.Color.BLACK;
          entity.polygon.extrudedHeight = 0;

          //draw gfx-models
          let wtid = entity.properties.getValue().entity.wtid;
          let feature = (featureCollection as any).features[i];

          if (feature.wtid === wtid) {
            //console.log(tcounter++ + ". terrain created " + entity._id);
            feature.cid = entity._id;
          }
          fCsDrawObjectsForTerrain(state, wtid);
        }
      })
      .otherwise(function(error: any) {
        console.error("Error parsing geoJson terrain:\n" + error);
      });
  }
}

function fCsDrawObjectsForTerrain(state: RootState, twtid: number) {
  let scene = csViewer.scene;
  let objectsData = store.state.dataLoad.wtWorldData.wtWorldTerrainObjects.objectsDataArray.find(
    (objectsData) => (objectsData as any).pwtid === twtid
  );
  if (typeof objectsData == "undefined") {
    //console.error("fCsDrawObjectsForTerrain objects data structure missing for " + twtid)
    return;
  }
  let wgstOffset = (objectsData as any).wgsOffset;
  let gtScale = (objectsData as any).gtScale;
  for (let wtObject of (objectsData as any).objects) {
    //TODO WT-104
    //console.info("gltf draw call for " + wtObject.wtid);
    let gModel = wtObject.gModel;
    let eLong = wtObject.wgs[0] + wgstOffset[0];
    let eLat = wtObject.wgs[1] + wgstOffset[1];
    //let eHeight = wtObject.wgs[2] + wgstOffset[2];
    let center = Cesium.Cartesian3.fromDegrees(eLong, eLat);
    let hpr = new Cesium.HeadingPitchRoll(Math.random() * 6.28, 0, 0);
    let transform = Cesium.Transforms.headingPitchRollToFixedFrame(center, hpr);
    //let eMatrix = Cesium.Transforms.eastNorthUpToFixedFrame(Cesium.Cartesian3.fromDegrees(eLong, eLat, eHeight));
    let csModel = Cesium.Model.fromGltf({
      id: wtObject.wtid,
      name: gModel,
      url: wtDataURIs.getgModelURIFromTyp(gModel),
      modelMatrix: transform,
      scale: wtObject.gScale * gtScale,
      silhouetteColor: store.state.settings.gfx.modelSilhouettes.color,
      silhouetteSize: store.state.settings.gfx.modelSilhouettes.size,
    });
    (csDataJS as any).csSceneModels[wtObject.wtid] = scene.primitives.add(
      csModel
    );
  }
}

function fCsGFXMaterialsDimensionXY(textureType: any, x: number, y: number) {
  (csDataJS as any).materials[textureType]._repeat._value.x = x;
  (csDataJS as any).materials[textureType]._repeat._value.y = y;
}

function fCsSelectionSetTerrainSilhouette(
  state: State,
  twtid: number,
  silhouetteConf: any
) {
  // //console.log("fCsSelectionSetTerrainSilhouette called for " + twtid)
  // let objectsData = state.dataLoad.wtWorldData.wtWorldTerrainObjects.objectsDataArray.find(
  //   (obj: any) => obj.pwtid === twtid
  // ) as any;
  // if (typeof objectsData === "undefined") return;
  // for (let object of objectsData.objects) {
  //   let entity = csDataJS.csSceneModels[object.wtid] as any;
  //   entity.silhouetteColor = silhouetteConf.color;
  //   entity.silhouetteSize = silhouetteConf.size;
  // }
}

function fCsHelpSetSilhouettes(feature: any, color: any, size: number) {
  feature.node._model.silhouetteColor = color;
  feature.node._model.silhouetteSize = size;
}

function refreshCurrentCameraData(currentCameraView: any) {
  let camera = csViewer.scene.camera;
  //console.log("breakpoint_CameraRefresh");
  currentCameraView.position = camera.position.clone();
  currentCameraView.direction = camera.direction.clone();
  currentCameraView.up = camera.up.clone();
  currentCameraView.right = camera.right.clone();
  currentCameraView.transform = camera.transform.clone();
  currentCameraView.frustum = (camera.frustum as any).clone();
}

//++++++++++++++++++++++++++++ Cesium Visual Interaction ++++++++++++++++++++++++++++ //
function fCsPolyDrawStop() {
  if (typeof csDataJS.handler !== "undefined") csDataJS.handler.destroy();
}

function fCsPolyDrawInit(state: RootState) {
  let viewer = csViewer;
  let handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
  csDataJS.handler = handler;
  viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
    Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
  );
  handler.setInputAction(function(event: any) {
    //console.log("Polydraw cesium handler called...")
    // We use `viewer.scene.pickPosition` here instead of `viewer.camera.pickEllipsoid` so that
    // we get the correct point when mousing over terrain.
    //let earthPosition = viewer.scene.pickPosition(event.position);
    let earthPosition = viewer.camera.pickEllipsoid(
      event.position,
      viewer.scene.globe.ellipsoid
    );
    // `earthPosition` will be undefined if our mouse is not over the globe.
    if (Cesium.defined(earthPosition)) {
      if (csDataJS.polyDraw.activeShapePoints.length === 0) {
        csDataJS.polyDraw.floatingPoint = fCsPolyDrawcreatePoint(earthPosition);
        csDataJS.polyDraw.activeShapePoints.push(earthPosition);
        let dynamicPositions = new Cesium.CallbackProperty(function() {
          return new Cesium.PolygonHierarchy(
            csDataJS.polyDraw.activeShapePoints
          );
        }, false);
        csDataJS.polyDraw.activeShape = fCSPolyDrawDrawShape(dynamicPositions);
      }
      csDataJS.polyDraw.activeShapePoints.push(earthPosition);
      fCsPolyDrawcreatePoint(earthPosition);
    }
  }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

  handler.setInputAction(function(event: any) {
    if (Cesium.defined(csDataJS.polyDraw.floatingPoint)) {
      //let newPosition = viewer.scene.pickPosition(event.endPosition);
      let newPosition = viewer.camera.pickEllipsoid(
        event.endPosition,
        viewer.scene.globe.ellipsoid
      );
      if (Cesium.defined(newPosition)) {
        csDataJS.polyDraw.floatingPoint.position.setValue(newPosition);
        csDataJS.polyDraw.activeShapePoints.pop();
        csDataJS.polyDraw.activeShapePoints.push(newPosition);
      }
    }
  }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
  // Redraw the shape so it's not dynamic and remove the dynamic shape.

  handler.setInputAction(function(event: any) {
    fCsPolyDrawTerminateShape();
  }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
}

function fCsPolyDrawcreatePoint(worldPosition: any) {
  let point = csViewer.entities.add({
    position: worldPosition,
    point: {
      color: Cesium.Color.RED,
      //@ts-ignore
      pixelSize: 6,
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
    },
  });
  return point;
}

function fCSPolyDrawDrawShape(positionData: any) {
  let shape;
  //console.log(positionData);
  if (csDataJS.polyDraw.drawingMode === "LineString") {
    shape = csViewer.entities.add({
      polyline: {
        positions: positionData,
        //@ts-ignore
        clampToGround: true,
        width: 3,
        material: new Cesium.ColorMaterialProperty(Cesium.Color.ORANGE),
      },
    });
  } else if (csDataJS.polyDraw.drawingMode === "Polygon") {
    shape = csViewer.entities.add({
      //@ts-ignore
      polygon: {
        hierarchy: positionData,
        material: new Cesium.ColorMaterialProperty(
          Cesium.Color.YELLOW.withAlpha(0.5)
        ),
      },
    });
  }
  return shape;
}

function fCsPolyDrawTerminateShape() {
  csDataJS.polyDraw.activeShapePoints.pop();
  fCSPolyDrawDrawShape(csDataJS.polyDraw.activeShapePoints);
  csViewer.entities.remove(csDataJS.polyDraw.floatingPoint);
  csViewer.entities.remove(csDataJS.polyDraw.activeShape);
  csDataJS.polyDraw.floatingPoint = undefined;
  csDataJS.polyDraw.activeShape = undefined;
  csDataJS.polyDraw.lastDrawnShapePoints = csDataJS.polyDraw.activeShapePoints;
  csDataJS.polyDraw.lastDrawningType = csDataJS.polyDraw.drawingMode;
  csDataJS.polyDraw.activeShapePoints = [];
}

function fSelectionViewportClick(state: State, wtid: number) {
  //console.log("Selection in Viewport for: " + wtid)
  //if (wtid == store.state.interaction.selection.current) {
  //return; // ignored multiple selections in history of the same object
  //}
  //const wtidSelection = fwtGetEntitySelectionFromWtid(state, wtid);
  //@ts-ignore
  //store.state.interaction.selection.player.push(wtidSelection);
  //store.state.interaction.selection.current = wtidSelection;
  //console.log(state.interaction.selection.player)
}

//++++++++++++++++++++++++++++++++++ Cesium Helpers ++++++++++++++++++++++++++++++++++ //

function CsHelpCartesian3ArrayToCartographicArray(
  cartesianArray: Array<number>
) {
  let cartographicArray = [];
  for (let i = 0; i < cartesianArray.length; i++) {
    let cartesianEntry = cartesianArray[i] as any;
    let cartographicEntry = fCsHelpCartesian3ToCartographic(cartesianEntry);
    cartographicArray.push(cartographicEntry);
  }
  return cartographicArray;
}

function fCsHelpCartesian3ToCartographic(cartesian3: {
  x: number;
  y: number;
  z: number;
}) {
  let ellipsoid = (csViewer as any).scene.globe.ellipsoid;
  let cartographic = ellipsoid.cartesianToCartographic(cartesian3);
  let latitudeString = Cesium.Math.toDegrees(cartographic.latitude);
  let longitudeString = Cesium.Math.toDegrees(cartographic.longitude);
  let position = {
    lat: latitudeString,
    long: longitudeString,
    height: cartographic.height,
  };
  return position;
}

function fWtidFindTerrainObject(state: RootState, owtid: number) {
  const objectsDataArray = store.state.dataLoad.wtWorldData
    .wtWorldTerrainObjects.objectsDataArray as any;
  for (const objectsData of objectsDataArray) {
    const terrainObject = objectsData.objects.find(
      (object: any) => object.wtid === owtid
    );
    if (typeof terrainObject !== "undefined") {
      const wtidRequest = {
        wtid: owtid,
        pwtid: objectsData.pwtid,
        object: terrainObject,
      };
      return wtidRequest;
    }
  }
  return undefined;
}

function fDataCreateTerrainJSONFeature() {
  let newWtid = +Math.round(100000000 * Math.random());
  //TODO change to now yolo the new wtid
  let newFeature = {
    type: "Feature",
    geometry: {
      type: undefined,
      coordinates: [[]],
    },
    wtid: newWtid,
    properties: {
      wtid: newWtid,
      name: "Unkown Terrain",
      tType: undefined,
      wgsCenter: [35, 10, 0],
    },
  };
  return newFeature;
}

export default cesiumStore;
