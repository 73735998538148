import Worldtrix from "../Worldtrix";

export default class TimeManager {
  running: boolean = false;
  private n0: number;
  private n1: number;
  private n2: number;

  readonly rlZeroTimestamp: number;
  private rln0: number;
  private rln1: number;

  timeMultiplier: number =
    20 * TimeManager.UNITS.MINUTE * Worldtrix.devSpeedMul;

  private time2Log = 0.1 * TimeManager.UNITS.HOUR;
  private time2LogSum = 0;
  private time2LogCount = 0;

  private simulationUpdateCounter = 0;

  public static readonly TIMESTAMP_DIVIDER = 1000;
  public static readonly UNITS = {
    SECOND: 1000 / TimeManager.TIMESTAMP_DIVIDER,
    MINUTE: (1000 * 60) / TimeManager.TIMESTAMP_DIVIDER,
    HOUR: (1000 * 3600) / TimeManager.TIMESTAMP_DIVIDER,
    DAY: (1000 * 3600 * 24) / TimeManager.TIMESTAMP_DIVIDER,
    MONTH: (1000 * 3600 * 24 * 30) / TimeManager.TIMESTAMP_DIVIDER,
    YEAR: (1000 * 3600 * 24 * 365) / TimeManager.TIMESTAMP_DIVIDER,
  };

  constructor() {
    this.rlZeroTimestamp = this.rln0 = this.rln1 = Date.now();
    this.n0 = this.n1 = this.n2 = 0;
  }

  update(wtTimeDelta?: number): number {
    this.simulationUpdateCounter++;
    if (wtTimeDelta) {
      this.rln1 = this.rln0;
      this.rln0 +=
        wtTimeDelta * this.timeMultiplier * TimeManager.TIMESTAMP_DIVIDER;
      this.n2 = this.n1;
      this.n1 = this.n0;
      this.n0 += Math.round(
        (this.timeMultiplier * (this.rln0 - this.rln1)) /
          TimeManager.TIMESTAMP_DIVIDER
      );
    } else if (this.running) {
      this.rln1 = this.rln0;
      this.rln0 = Date.now();
      this.n2 = this.n1;
      this.n1 = this.n0;
      this.n0 += Math.round(
        (this.timeMultiplier * (this.rln0 - this.rln1)) /
          TimeManager.TIMESTAMP_DIVIDER
      );
    }
    this.time2LogSum += this.n0 - this.n1;
    this.time2LogCount++;
    if (this.time2LogSum > this.time2Log) {
      console.log(
        `Gametime passed: ${this.n0 /
          TimeManager.UNITS.HOUR} hours. update() count: ${this.time2LogCount}`
      );
      this.time2LogSum = 0;
      this.time2LogCount = 0;
    }
    //console.warn(`TimeManager.now(): ${this.n0}`)
    return this.n0;
  }

  now() {
    return this.n0;
  }

  nowAdded(delta: number): number {
    return this.now() + delta;
  }

  nowDelta(timestamp: number) {
    return this.n0 - timestamp;
  }

  start() {
    this.rln0 = this.rln1 = Date.now();
    this.running = true;
    console.info("TimeManager time started.");
  }

  stop() {
    this.now();
    this.rln0 = this.rln1 = Date.now();
    this.running = false;
    console.info("TimeManager time stopped.");
  }

  lastStepDuration(): number {
    return this.n0 - this.n1;
  }

  toSeconds(time: number) {
    return time / TimeManager.UNITS.SECOND;
  }

  toMinutes(time: number) {
    return time / TimeManager.UNITS.MINUTE;
  }

  toHours(time: number) {
    return time / TimeManager.UNITS.HOUR;
  }

  static toHours(time: number) {
    return time / TimeManager.UNITS.HOUR;
  }

  static toMinutes(time: number) {
    return time / TimeManager.UNITS.MINUTE;
  }

  perMinute(time: number) {
    return time * TimeManager.UNITS.MINUTE;
  }

  perHour(time: number) {
    return time * TimeManager.UNITS.HOUR;
  }

  static perHour(time: number) {
    return time * TimeManager.UNITS.HOUR;
  }

  static perMinute(time: number) {
    return time * TimeManager.UNITS.MINUTE;
  }

  toRealTimeHours(time: number) {
    return this.toHours(time) / this.timeMultiplier;
  }

  toRealTimeMinutes(time: number) {
    return this.toMinutes(time) / this.timeMultiplier;
  }

  toRealTimeSeconds(time: number) {
    return this.toSeconds(time) / this.timeMultiplier;
  }
}
