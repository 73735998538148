import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEAbility from "../../WTEAbility";
import WTEAction from "../../WTEAction";
import WTEAInput from "../../WTEAInput";
import WTHelpers from "../../../lib/WTHelpers";
import WTVLinear from "@/ts/wt/lib/WTValue/WTVLinear";
import Population from "@/ts/wt/entities/Population/Population";
import Tribe from "@/ts/wt/entities/Tribe";
import Inventory from "@/ts/wt/Inventory/Inventory";

export default class WTEAGrowTribe extends WTEAction {
  type: WT.Entity.Action;

  inventory!: Inventory;
  population!: Population;
  tribe!: Tribe;

  // TODO: Insert/replace simulation variables here
  actionValue1: number = 0;
  actionValue2: number = 0;

  constructor(ability: WTEAbility, input?: WTEAInput) {
    super(ability, input);
    this.type = WT.Entity.Action.TRIBE_GROW_TRIBE;
  }

  // ------------------------ START action functions ------------------------ \\
  init(input: WTEAInput): WTEAction {
    this.superInit();
    // assigne potential new input in case of re-init call
    this.input = input;
    this.tribe = this.entity as Tribe;
    this.population = this.entity.population;
    this.inventory = this.entity.inventory;
    return this;
  }

  initPreConditions(): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  listPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  checkPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    let failedConds: Array<WT.Interaction.PreCondtionType> = [];
    // do additional pre condition checks here
    failedConds = failedConds.concat(
      this.tribe.checkState(WT.Tribe.State.SETTELED_TRIBE)
    );
    return failedConds;
  }

  start(): boolean {
    let preCondditions = this.checkPreConditions();
    if (!WTHelpers.checkPreConditions(preCondditions)) {
      WTHelpers.preConditionsToLog(preCondditions);
      return false;
    } else {
      this.updateAttributes(this.input);
      this.timeBoundariesSet(this.isDoneIn());
      console.log("aGrowStart()");
      return true;
    }
  }

  update(tStamp: number): WTEAction {
    let maxSaturation = true;
    let foodItems = this.inventory.getItemsOfType(WT.Inventory.Type.FOOD);
    let foodAvailable = Inventory.sumItemAmount(foodItems);
    let foodReq = this.population.foodForSaturation(maxSaturation);
    //console.log(`Food-Before: for saturation: ${foodReq} MAX: ${this.population.foodForSaturation(true)} Remaining food: ${foodAvailable}`)
    if (foodAvailable >= foodReq) {
      // Over saturation leads to increase pregnancy rates
      let leftOver = this.population.eatFoodAmount(foodReq, maxSaturation);
      Inventory.subItemAmount(foodItems, foodReq);
      console.log(
        "aGrowUpdate() food left: " +
          this.inventory.getAmountOfItemType(WT.Inventory.Type.FOOD)
      );
      if (leftOver >= 0.000000000001)
        throw new Error(`Left over food ${leftOver} but 0 expected.`);
    } else {
      let leftOver = Inventory.subItemAmount(foodItems, foodReq);
      let feededAmount = foodReq - leftOver;
      this.population.eatFoodAmount(feededAmount, maxSaturation);
      console.log("Not enough food to feed the all...");
      this.finish();
    }
    return this;
  }

  updateAttributes(input: WTEAInput): WTEAction {
    return this;
  }

  isDoneIn(): number {
    console.log("aGrowDoneIn: Action alwasy active till food is gone... ");
    return Infinity;
  }

  finish(): boolean {
    this.superFinish();
    console.warn("Tribe.growTribe().finish(), wtid:" + this.entity.toString());
    return this.finished;
  }

  cancel(): boolean {
    this.superCancel();
    throw new Error("Method not implemented.");
    return this.canceled;
  }

  // ---------------------------- action helpers ---------------------------- \\

  // ------------------------------ wt helpers ------------------------------ \\
}
