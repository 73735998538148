import Vuex from "vuex";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import store from "@/store";

type RootState = typeof store.state;

class State {
  gWorldTerrainObjectTypes: any;
}
const getters = <GetterTree<State, any>>{};

const actions = <ActionTree<State, any>>{};

const mutations = <MutationTree<State>>{};

const storeGraphics = {
  namespaced: false,
  modules: {},
  state: new State(),
  getters: getters,
  actions: actions,
  mutations: mutations,
};

export default storeGraphics;
