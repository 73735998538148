




















import Vue from "vue";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import ObjectListItem from "@/components/lists/items/ObjectListItem.vue";

export default Vue.extend({
  name: "ObjectsList",
  components: { ObjectListItem },
  props: {},
  data: () => ({
    ui: {
      worldObjects: {
        title: "Discovered objects",
        active: true,
      },
    },
  }),
  computed: {
    localComputed() {
      return "";
    },
    ...mapState({}),
    ...mapGetters({ wt: "wt", wm: "wm", l10n: "l10n" }),
  },
  methods: {
    ...mapActions([""]),
    ...mapMutations([""]),
  },
  created: function() {
    //console.log(this)
  },
  mounted: function() {},
});
