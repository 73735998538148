import TaskForce from "./TaskForce";
import Worldtrix from "../../Worldtrix";
import * as WT from "@/ts/wt/declaration/WTEnums";
import WTTarget from "../../interaction/WTTarget";
import WTEAction from "../../interaction/WTEAction";
import WTEntity from "../WTEntity";
import TimeManager from "@/ts/wt/managers/TimeManager";
import WGS from "../../lib/WGS";
import WTHelpers from "../../lib/WTHelpers";
import WTValue from "../../lib/WTValue/WTValue";
import WTVLinear from "../../lib/WTValue/WTVLinear";
import WTEAbility from "../../interaction/WTEAbility";

export default class Gatherers extends TaskForce {
  constructor(wt: Worldtrix, gData?: any) {
    super(wt, gData);
    this.wt.em.pm.gatherers.push(this);
  }

  initAbilities(): WTEntity {
    this.addAbility(
      new WTEAbility(
        WT.Entity.Action.TASKFORCE_GATHER,
        this,
        "Gather resources",
        "Gahter resoucres from the terain depending on what is there and what you stumble accross."
      )
    );
    return this;
  }

  getAbilities(): WTEAbility[] {
    return this.abilities;
  }

  update(tStamp: number): WTEntity {
    this.updateEntity(tStamp);
    this.updateTaskForce(tStamp);
    return this;
  }
}
