


















import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import Vue from "vue";

import DetailsComposables from "@/composables/DetailsComposables";

import Terrain from "@/ts/wt/entities/Terrain";

export default Vue.extend({
  name: "DetailsTerrain",
  components: {},
  props: {
    wtEntity: { type: Terrain, required: true },
  },
  data: () => ({}),
  setup(wtEntity) {
    const { textComputed, flyTo } = DetailsComposables(
      wtEntity.wtEntity as Terrain
    );
    return { textComputed, flyTo };
  },
  computed: {
    ...mapGetters(["l10n", "wt", "settings"]),
    ...mapState({
      wtid: (state: any) => state.interaction.selection.last,
    }),
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    submitJson() {
      console.log("breakpoint " + event);
    },
  },
});
