import WTEAbility from "../WTEAbility";
import WTEAction from "../WTEAction";
import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEAInput from "../WTEAInput";
import WTEAMove from "./WTEAMove";
import WTEAItemExchange from "./WTEAItemExchange";
import WTEAFoodConsume from "./WTEAFoodConsume";
import WTEAJoinTribe from "./TaskForce/WTEAJoinTribe";
import WTEASettleDown from "./Tribe/WTEASettleDown";
import WTEABuildShelters from "./Tribe/WTEABuildShelters";
import WTEACreateTaskForce from "./Tribe/WTEACreateTaskForce";
import WTEAGrowTribe from "./Tribe/WTEAGrowTribe";
import WTEALeaveSettlement from "./Tribe/WTEALeaveSettlement";
import WTEASplitTribe from "./Tribe/WTEASplitTribe";
import WTEAExplore from "./TaskForce/WTEAExplore";
import WTEAWildlifeHunting from "./TaskForce/WTEAHuntWildlife";
import WTEAFish from "./TaskForce/WTEAFish";
import WTEAGather from "./TaskForce/WTEAGather";
import WTEARaidAnimalDen from "./TaskForce/WTEARaidAnimalDen";

/** Static factory class to make it more readable how different action objects are generated. */
export default class WTEAAFactory {
  static generateActionForAbility(
    ability: WTEAbility,
    actionType: WT.Entity.Action,
    input?: WTEAInput
  ): WTEAction {
    switch (actionType) {
      case WT.Entity.Action.BASIC_MOVE:
      case WT.Entity.Action.BASIC_ITEM_EXCHANGE:
      case WT.Entity.Action.BASIC_LOOT_RESOURCES:
      case WT.Entity.Action.BASIC_FOOD_CONSUME:
        return WTEAAFactory.createBasicAction(ability, actionType, input);
      case WT.Entity.Action.TRIBE_BUILD_SHELTERS:
      case WT.Entity.Action.TRIBE_CREATE_TASKFORCE:
      case WT.Entity.Action.TRIBE_GROW_TRIBE:
      case WT.Entity.Action.TRIBE_LEAVE_SETTLEMENT:
      case WT.Entity.Action.TRIBE_SETTLE_DOWN:
      case WT.Entity.Action.TRIBE_SPLIT_TRIBE:
        return WTEAAFactory.createTribeAction(ability, actionType, input);
      case WT.Entity.Action.TASKFORCE_EXPLORE:
      case WT.Entity.Action.TASKFORCE_FISH:
      case WT.Entity.Action.TASKFORCE_GATHER:
      case WT.Entity.Action.TASKFORCE_HUNT_WILDLIFE:
      case WT.Entity.Action.TASKFORCE_JOIN_TRIBE:
      case WT.Entity.Action.TASKFORCE_RAID_ANIMAL_DEN:
      case WT.Entity.Action.TASKFORCE_TRAVEL:
        return WTEAAFactory.createTaskForceAction(ability, actionType, input);
      case WT.Entity.Action.OBJECT_ACTION:
        return WTEAAFactory.createWorldObjectAction(ability, actionType, input);
      default:
        throw new Error("WTEAAFacotry WT.WTEActions type not found.");
    }
  }

  /*
   * Splitting into multiple functions not technical required but is for improved maintanability.
   * Functions should be added to the "matching" group
   */
  private static createBasicAction(
    ability: WTEAbility,
    actionType: WT.Entity.Action,
    input?: WTEAInput
  ): WTEAction {
    switch (actionType) {
      case WT.Entity.Action.BASIC_MOVE: {
        let action = new WTEAMove(ability, input);
        return action;
      }
      case WT.Entity.Action.BASIC_ITEM_EXCHANGE: {
        let action = new WTEAItemExchange(ability, input);
        return action;
      }
      case WT.Entity.Action.BASIC_LOOT_RESOURCES: {
        let action = new WTEAItemExchange(ability, input);
        return action;
      }
      case WT.Entity.Action.BASIC_FOOD_CONSUME: {
        let action = new WTEAFoodConsume(ability, input);
        return action;
      }
      default:
        throw new Error("WTEAAFacotry WT.WTEActions type not found.");
    }
  }

  private static createTribeAction(
    ability: WTEAbility,
    actionType: WT.Entity.Action,
    input?: WTEAInput
  ): WTEAction {
    switch (actionType) {
      case WT.Entity.Action.TRIBE_BUILD_SHELTERS: {
        let action = new WTEABuildShelters(ability, input);
        return action;
      }
      case WT.Entity.Action.TRIBE_CREATE_TASKFORCE: {
        let action = new WTEACreateTaskForce(ability, input);
        return action;
      }
      case WT.Entity.Action.TRIBE_GROW_TRIBE: {
        let action = new WTEAGrowTribe(ability, input);
        return action;
      }
      case WT.Entity.Action.TRIBE_LEAVE_SETTLEMENT: {
        let action = new WTEALeaveSettlement(ability, input);
        return action;
      }
      case WT.Entity.Action.TRIBE_SETTLE_DOWN: {
        let action = new WTEASettleDown(ability, input);
        return action;
      }
      case WT.Entity.Action.TRIBE_SPLIT_TRIBE: {
        let action = new WTEASplitTribe(ability, input);
        return action;
      }
      default:
        throw new Error("WTEAAFacotry WT.WTEActions type not found.");
    }
  }

  private static createTaskForceAction(
    ability: WTEAbility,
    actionType: WT.Entity.Action,
    input?: WTEAInput
  ): WTEAction {
    switch (actionType) {
      case WT.Entity.Action.TASKFORCE_EXPLORE: {
        let action = new WTEAExplore(ability, input);
        return action;
      }
      case WT.Entity.Action.TASKFORCE_FISH: {
        let action = new WTEAFish(ability, input);
        return action;
      }
      case WT.Entity.Action.TASKFORCE_GATHER: {
        let action = new WTEAGather(ability, input);
        return action;
      }
      case WT.Entity.Action.TASKFORCE_JOIN_TRIBE: {
        let action = new WTEAJoinTribe(ability, input);
        return action;
      }
      case WT.Entity.Action.TASKFORCE_HUNT_WILDLIFE: {
        let action = new WTEAWildlifeHunting(ability, input);
        return action;
      }
      case WT.Entity.Action.TASKFORCE_RAID_ANIMAL_DEN: {
        let action = new WTEARaidAnimalDen(ability, input);
        return action;
      }
      case WT.Entity.Action.TASKFORCE_TRAVEL: {
        let action = new WTEAMove(ability, input);
        action.type = WT.Entity.Action.TASKFORCE_TRAVEL;
        return action;
      }
      default:
        throw new Error("WTEAAFacotry WT.WTEActions type not found.");
    }
  }

  private static createWorldObjectAction(
    ability: WTEAbility,
    actionType: WT.Entity.Action,
    input?: WTEAInput
  ): WTEAction {
    switch (actionType) {
      case WT.Entity.Action.OBJECT_ACTION: {
        let action = new WTEAMove(ability, input); // TODO replace with other actions
        return action;
      }
      default:
        throw new Error("WTEAAFacotry WT.WTEActions type not found.");
    }
  }
}
