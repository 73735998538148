import TimeManager from "../managers/TimeManager";

/**
 * Class rolls events based on time probabilites since last occurence.
 * Gurantees sucess if enough time is passed.
 */
export default class WTRandom {
  successfulPerHour: number = 0;
  private ticksTillSuccess: number;
  successCounter: number = 0;
  rollCounter: number = 0;

  timeStarted: number = 0;

  limitedSuccesses: boolean = false;
  maxSuccesses: number = -1;

  lastSuccess: number = -1;
  lastRolled: number = -1;

  constructor(
    successfulPerHour: number,
    startTStamp: number,
    limitedSuccesses: boolean,
    maxSuccesses?: number
  ) {
    this.successfulPerHour = successfulPerHour;
    this.ticksTillSuccess = TimeManager.UNITS.HOUR / this.successfulPerHour;
    this.limitedSuccesses = limitedSuccesses;
    if (this.limitedSuccesses && maxSuccesses) this.maxSuccesses = maxSuccesses;
    // Start timestamp counts as initial roll for probablity
    this.timeStarted = startTStamp;
    this.lastSuccess = startTStamp;
  }

  /**
   * Rolls the probabilite based on the initial values and given timestamp.
   *
   * @param tStamp - Timestamp when the roll is done to adpated timed propability
   */
  roll(tStamp: number): boolean {
    this.lastRolled = tStamp;
    if (this.limitedSuccesses && this.maxSuccesses >= this.successCounter) {
      console.log(
        `WTRandom.roll() maxSuccess Rolles reached with ${
          this.maxSuccesses
        }. Last success at gameTime ${this.lastSuccess /
          TimeManager.UNITS.DAY} day`
      );
      return false;
    }
    if (tStamp < this.lastRolled) {
      // this should not happend during initial alpha
      console.warn("Time is rolling backwards for propabilites rolls");
    }

    let successDelta = tStamp - this.lastSuccess;

    let expectedSuccess = (tStamp - this.timeStarted) / this.ticksTillSuccess;
    let successAdaptionFactor =
      (expectedSuccess + 1) / (this.successCounter + 1);

    let rollPropability =
      (successDelta / this.ticksTillSuccess) * successAdaptionFactor;

    let roll = Math.random();
    this.rollCounter++;

    //console.log(`Try rollPropability ${rollPropability} (${successDelta} / ${this.ticksTillSuccess} * ${successAdaptionFactor}) with roll ${roll}`)

    if (rollPropability >= roll) {
      this.successCounter++;
      this.lastSuccess = tStamp;
      //console.log(`${this.successCounter}. roll successfull, expectation ${expectedSuccess}`)
      return true;
    }
    return false;
  }

  finished(): boolean {
    if (this.limitedSuccesses && this.maxSuccesses >= this.successCounter)
      return true;
    return false;
  }
}
