

























//v-on:click.right="{ mousedown: aCsViewportMouseClick($event), mouseup: aCsViewportMouseClick($event) }"
//v-on:mousemove="move($event)"

import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import Vue from "vue";

import WGSInput from "@/components/elements/WGSInput.vue";

export default Vue.extend({
  name: "CesiumViewer",
  components: { WGSInput },
  props: [],
  data: () => ({
    counter: 0,
    lastMouseMovement: 0,
    viewport: {
      x: 0,
      y: 0,
    },
  }),
  computed: {
    ...mapState({
      mouseLagTime: (state) => state.settings.UI.mouseMovementLagMillis,
    }),
    ...mapGetters(["getEditorIsOn"]),
  },
  methods: {
    ...mapActions([
      "aCsEditorViewportMouseClickEvent",
      "aCsViewportMouseClickEvent",
    ]),
    ...mapMutations([]),
    mouseMoveEvent(mouseEvent: MouseEvent) {
      //console.log("vue: viewport mouse movement");
      let now = Date.now();
      let delta = now - this.lastMouseMovement;
      if (delta < this.mouseLagTime) {
        //console.log("CesiumViewer.vue skipped... - ");
        //console.log("CesiumViewer.vue skipped... - " + delta + "ms");
        return;
      }
      this.lastMouseMovement = now;
      this.aCsViewportMouseClickEvent(mouseEvent);
    },
    vMouseClickHelper(mouseEvent: MouseEvent) {
      //console.log("Mouse LEFT click");
      if (this.getEditorIsOn) {
        //console.log("vue: editor viewport click");
        this.aCsEditorViewportMouseClickEvent(mouseEvent);
      } else {
        //console.log("vue : Play viewport click");
        this.aCsViewportMouseClickEvent(mouseEvent);
      }
    },
    vMouseDoubleClickHelper(mouseEvent: MouseEvent) {
      // also triggers single click. Need some serious refactoring to fix this here
      console.log("Clicking ähhh no it is DOUBLE Clicking" + mouseEvent.which);
    },
    vMouseRightClickHelper(mouseEvent: MouseEvent) {
      //console.log("Mouse RIGHT click.");
      if (this.getEditorIsOn) {
        //console.log("vue: editor viewport click");
        this.aCsEditorViewportMouseClickEvent(mouseEvent);
      } else {
        //console.log("vue : Play viewport click");
        this.aCsViewportMouseClickEvent(mouseEvent);
      }
    },
    keyDown(keyEvent: KeyboardEvent) {
      //console.log("keyDown: " + keyEvent.key);
      this.$store.dispatch("aKeyboardEvent", {
        keyEvent: keyEvent,
        isKeyDown: true,
      });
    },
    keyUp(keyEvent: KeyboardEvent) {
      //console.log("keyUp: " + keyEvent.key);
      this.$store.dispatch("aKeyboardEvent", {
        keyEvent: keyEvent,
        isKeyDown: false,
      });
    },
    onResize(resizeEvent: any) {
      if (resizeEvent) {
        this.viewport.x = resizeEvent.currentTarget.innerWidth;
        this.viewport.y = resizeEvent.currentTarget.innerHeight;
        //console.log(event);
        //console.log(`x: ${this.viewport.x} y: ${this.viewport.y}`);
      }
    },
  },
  created: function() {
    //lastMouseMovement = Date.now()
  },
  mounted: function() {},
});
