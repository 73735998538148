import Vue from "vue";
import Vuex from "vuex";
import { GetterTree, MutationTree, ActionTree } from "vuex";

// other libs
import Cesium from "@/plugins/cesium";

//------------ STORE MODULES ------------- //
import storeAccount from "@/store/storeAccount";
import storeCesium from "@/store/storeCesium";
import storeDataLoad from "@/store/storeDataLoad";
import storeEditor from "@/store/storeEditor";
import storeGraphics from "@/store/storeGraphics";
import storeInteraction from "@/store/storeInteraction";
import storel10n from "@/store/storel10n";
import storeSettings from "@/store/storeSettings";
import storeWorldtrix from "@/store/storeWorldtrix";

Vue.use(Vuex);
class State {
  // define a typesafe store.state.moduleName  for each module
  account!: typeof storeAccount.state;
  cesium!: typeof storeCesium.state;
  dataLoad!: typeof storeDataLoad.state;
  editor!: typeof storeEditor.state;
  gfx!: typeof storeGraphics.state;
  interaction!: typeof storeInteraction.state;
  l10n!: typeof storel10n.state;
  settings!: typeof storeSettings.state;
  wt!: typeof storeWorldtrix.state;

  wtInfo = {
    version: "0.1.0",
    release: "origins",
    build: "00004", //next bulid number 00004
  };
  testObj = {
    ojb: { id: 42, title: "test-Obj" },
    number: 42,
    string: "Test-String",
    bool: false,
    fn: function() {
      console.log("Test function called", this);
    },
  };
}

const getters = <GetterTree<State, any>>{
  l10n: (state) => state.l10n,
  wtVersion: (state) => {
    return `${state.wtInfo.release}-${state.wtInfo.version}-#${state.wtInfo.build}`;
  },
  settings: (state) => state.settings,
};

const actions = <ActionTree<State, any>>{};

const mutations = <MutationTree<State>>{
  mStoreTescht(state) {
    //console.log("Store test is working...");
    //worldtrix.startTime()
    //worldtrix.valueCalculation()
  },
};

export default new Vuex.Store({
  modules: {
    // sync names in root store get a typesafe store.state for each module
    account: storeAccount,
    cesium: storeCesium,
    dataLoad: storeDataLoad,
    editor: storeEditor,
    gfx: storeGraphics,
    interaction: storeInteraction,
    l10n: storel10n,
    settings: storeSettings,
    wt: storeWorldtrix,
  },
  state: new State(),
  getters: getters,
  actions: actions,
  mutations: mutations,
});
