





























































































































import router from "@/router";
import Vue from "vue";
import { mapState, mapGetters } from "vuex";

export default Vue.extend({
  name: "navigationDrawer",
  data: () => ({
    showSideMenue: false,
    subNavActive: false,
    mainlinks: [
      {
        text: "Worldtrix News",
        route: { name: "news" },
        icon: "public",
        shortkey: ["1"],
        uiTier: 0,
      },
      {
        text: "Play Worldtrix",
        route: { name: "play" },
        icon: "sports_esports",
        shortkey: ["2"],
        uiTier: 2,
      },
      {
        text: "Worldtrix Panorama",
        route: { name: "panorama" },
        icon: "mdi-panorama",
        shortkey: ["3"],
        uiTier: 0,
      },
      {
        text: "Worldtrix Game",
        route: { name: "game" },
        icon: "description",
        shortkey: [""],
        uiTier: 3,
      },
      {
        text: "Scores",
        route: { name: "scores" },
        icon: "mdi-counter",
        shortkey: ["4"],
        uiTier: 1,
      },
      {
        text: "Worldtrix Feedback",
        route: { name: "feedback" },
        icon: "feedback",
        shortkey: ["5"],
        uiTier: 3,
      },
    ],
    secondaryLinks: [
      {
        text: "World-Editor",
        route: { name: "editor" },
        icon: "edit_location",
        shortkey: [""],
        uiTier: 3,
      },
      {
        text: "Debug",
        route: { name: "debug" },
        icon: "mdi-bug",
        shortkey: ["0"],
        uiTier: 3,
      },
    ],
    accountLinks: [
      {
        text: "My Account",
        route: { name: "account" },
        icon: "account_circle",
        shortkey: [""],
        uiTier: 3,
      },
      //{ text: "My Subscriptions", route: "/contact", icon: "credit_card" },
      {
        text: "Game Time",
        route: { name: "time" },
        icon: "mdi-play-speed",
        shortkey: ["5"],
        uiTier: 2,
      },
      {
        text: "Game Settings",
        route: { name: "settings" },
        icon: "settings",
        shortkey: ["6"],
        uiTier: 0,
      },
      {
        text: "Support & Contact",
        route: { name: "contact" },
        icon: "contact_support",
        shortkey: ["9"],
        uiTier: 3,
      },
    ],
    baseLinks: [
      {
        text: "Donations",
        route: { name: "donations" },
        icon: "favorite",
        uiTier: 3,
      },

      {
        text: "Licenses used for WT",
        route: { name: "licenses" },
        icon: "code",
        uiTier: 0,
      },
      {
        text: "Data privacy & Cookies",
        route: { name: "datacookies" },
        icon: "security",
        uiTier: 0,
      },
      {
        text: "About & Impressum",
        route: { name: "about" },
        icon: "info",
        uiTier: 0,
      },
    ],
  }),
  computed: {
    ...mapState({}),
    ...mapGetters(["getEditorAllowed", "wtVersion", "sUiAccessTier"]),
    getMainLinks() {
      return this.mainlinks.filter(
        (uiItem) => uiItem.uiTier <= this.sUiAccessTier
      );
    },
    getSecondaryLinks() {
      return this.secondaryLinks.filter(
        (uiItem) => uiItem.uiTier <= this.sUiAccessTier
      );
    },
    getAccountLinks() {
      return this.accountLinks.filter(
        (uiItem) => uiItem.uiTier <= this.sUiAccessTier
      );
    },
    getBaseLinks() {
      return this.baseLinks.filter(
        (uiItem) => uiItem.uiTier <= this.sUiAccessTier
      );
    },
  },
  methods: {
    shortkeyRoute(link: any) {
      if (this.$route.name === link.route.name) return;
      router.push(link.route);
    },
  },
  mounted() {
    console.log("mapStateTescht: " + this.sUiAccessTier);
  },
});
