import TaskForce from "./TaskForce";
import Worldtrix from "../../Worldtrix";
import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEAbility from "../../interaction/WTEAbility";
import WTEntity from "../WTEntity";

export default class Hunters extends TaskForce {
  constructor(wt: Worldtrix, hData?: any) {
    super(wt, hData);
    this.wt.em.pm.hunters.push(this);
    //this.logw("constructed");
  }

  initAbilities(): WTEntity {
    this.addAbility(
      new WTEAbility(
        WT.Entity.Action.TASKFORCE_HUNT_WILDLIFE,
        this,
        "Hunt wildlife",
        "Hunt animals in the open terrain. Gives less food but is possible without danger."
      )
    );
    // this.addAbility(
    //   new WTEAbility(
    //     WT.Entity.Action.TASKFORCE_RAID_ANIMAL_DEN,
    //     this,
    //     "Raid animal den",
    //     "Raids a animal den and for food. Give much more resources but is also dangerous."
    //   )
    // );
    return this;
  }

  getAbilities(): WTEAbility[] {
    return this.abilities;
  }

  update(tStamp: number) {
    this.updateEntity(tStamp);
    this.updateTaskForce(tStamp);
    return this;
  }
}
