import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEntity from "../entities/WTEntity";

export default class WTHelpers {
  static checkMatchingOwner(
    failedConds: Array<WT.Interaction.PreCondtionType>,
    e1: WTEntity,
    e2: WTEntity
  ): boolean {
    if (e1.ownerID && e2.ownerID && e1.ownerID == e2.ownerID) return true;
    failedConds.push(WT.Interaction.PreCondtionType.OWNERS_NOT_MATCHING);
    return false;
  }

  static preConditionsToLog(
    conditions: Array<WT.Interaction.PreCondtionType>
  ): void {
    console.log("---interactionsPreConditionsToLog()---");
    let count = 0;
    for (let cond of conditions) {
      console.warn("[" + count++ + "] " + cond);
    }
    console.log("-----------------------------------END");
  }

  /**
   *
   * @param conditions true if no condition is violated
   */
  static checkPreConditionsWARNLog(
    conditions: Array<WT.Interaction.PreCondtionType>
  ): boolean {
    if (conditions.length > 0) {
      console.warn("interactionsPreConditionsCheck failed!");
      WTHelpers.preConditionsToLog(conditions);
      return false;
    }
    return true;
  }

  static checkPreConditions(
    conditions: Array<WT.Interaction.PreCondtionType>
  ): boolean {
    if (conditions.length > 0) {
      return false;
    }
    return true;
  }

  static checkForEnumActionMatch(
    compare: WT.Entity.Action,
    values: Array<WT.Entity.Action>
  ): boolean {
    for (let action of values) {
      if (compare == action) return true;
    }
    return false;
  }
}
