















































import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import Vue from "vue";

import WorldtrixTime from "@/components/elements/WorldtrixTime.vue";

export default Vue.extend({
  name: "Settings",
  components: { WorldtrixTime },
  data: () => ({}),
  computed: {
    ...mapGetters({
      gameEngineData: "gameEngineData",
      worldtrix: "wt",
    }),
    ...mapState({}),
  },
  methods: {
    ...mapActions(["awtGameStep", "awtStartGame", "aEngineInjectDevActions"]),
    ...mapMutations([]),
  },
  mounted: function() {},
});
