/**
 * Mocking client-server processing
 */
const callbackTime = 10;

//const JSONDataPath = "./JSON-dev/";
const JSONDataPath = "./JSON-prod/"; //WT-106 update prod files

//wtPlayerData
const wtPlayerData = require(JSONDataPath + "wtPlayerData.json");
const wtTechnologySystem = require(JSONDataPath + "wtTechnologySystem.json");

//wtWorldData
const wtWorldTerrain = require(JSONDataPath + "geojson/wtWorldTerrain.json");
const wtWorldTerrainObjects = require(JSONDataPath +
  "wtWorldTerrainObjects.json");
const wtWorldObjects = require(JSONDataPath + "wtWorldObjects.json");

const wtItemSystem = require(JSONDataPath + "wtItemSystem.json");

const wtLocalization = require("./localization/wtLocalization.json");

export const pi = 3.141593;

export default {
  getwtPlayerData(cb: any) {
    let allPlayerData = {};
    Object.assign(allPlayerData, wtPlayerData);
    Object.assign(allPlayerData, wtTechnologySystem);
    setTimeout(() => cb(allPlayerData), callbackTime);
  },

  getwtWorldData(cb: any) {
    let wtWorldData = { wtObj: "wtWorldData" };
    Object.assign(wtWorldData, wtWorldTerrain);
    Object.assign(wtWorldData, wtWorldTerrainObjects);
    Object.assign(wtWorldData, wtWorldObjects);
    setTimeout(() => cb(wtWorldData), callbackTime);
  },

  getwtLocalization(cb: any) {
    let localization = {};
    Object.assign(localization, wtLocalization);
    setTimeout(() => cb(localization), callbackTime);
  },

  getwtServerGameTime(cb: any) {
    setTimeout(() => cb(new Date().getTime()), callbackTime);
  },
};
