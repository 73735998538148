import TimeManager from "../../managers/TimeManager";

export default abstract class WTValue {
  tStart: number;
  tEnd!: number;

  /**
   * Flag for enabling errors in case of out of range timestamps
   */
  static TIMESTAMP_ERRORS = true;

  protected constructor(tStart: number, tEnd?: number) {
    this.tStart = tStart;
    if (tEnd) this.tEnd = tEnd;
  }

  /**
   * Abstract function for forcing the implementation of a timestamp based calculation
   * @param timestamp
   */
  abstract calculateValue(timestamp: number): number;

  /**
   * Abstract function for evaluting the required time until the value is reached the next time
   * @param targetValue
   * @param tStamp
   */

  abstract calculateTimeUntil(targetValue: number, tStamp: number): number;

  /**
   * Calculate the absolute time until the value is reached.
   * @param targetValue
   */
  abstract calculateTimeTotal(targetValue: number): number;

  /**
   * Calculate the relative functions time in relation to the given timestamp and start timestamp.
   *
   * @param tStamp timestamp in wt-time
   */
  functionTime(tStamp: number): number {
    this.timestampCheck(tStamp);
    return tStamp - this.tStart;
  }

  /**
   * Checks if the tStart <= tStamp <= tEnd (if defined)
   *
   * @throws Error if the TIMESTAMP_ERRORS == true
   * @param tStamp timestamp in wt-time
   */
  timestampCheck(tStamp: number): boolean {
    if (this.tEnd) {
      if (this.tStart <= tStamp && tStamp <= this.tEnd) {
        return true;
      }
    } else {
      if (this.tStart <= tStamp) {
        return true;
      }
    }
    if (WTValue.TIMESTAMP_ERRORS)
      throw new Error(
        `Timestamp not in boundaries: ${tStamp} not between ${this.tStart} - ${this.tEnd}`
      );
    return false;
  }

  logl(msg?: string): void {
    console.log(`WTValue.logl(): ${this.constructor.name} : ${msg}`);
  }

  logw(msg?: string): void {
    console.warn(`WTValue.logw(): ${this.constructor.name} : ${msg}`);
    console.warn(this);
  }
}
