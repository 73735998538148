





























import Vue from "vue";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

import WTEAbility from "@/ts/wt/interaction/WTEAbility";

export default Vue.extend({
  name: "AbilityListItem",
  components: {},
  props: {
    ability: { type: WTEAbility, required: true },
    aIndex: { type: Number, required: true },
  },
  data: (instance: { ability: WTEAbility }) => ({
    dataAbility: instance.ability,
  }),
  setup() {},
  computed: {
    ...mapState({
      l10n: (state) => state.l10n,
      createAction: (state) => state.interaction.createAction,
    }),
    ...mapGetters(["l10n", "settings"]),
    buildTitle() {
      return (
        (this.dataAbility as WTEAbility).title +
        " (" +
        (this.dataAbility as WTEAbility).index +
        ")"
      );
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    createActionClick() {
      let payload = {
        showOverlay: true,
        ability: this.ability,
        aIndex: this.aIndex,
      };
      this.$store.commit("mInteractionCreatedActionOverlay", payload);
    },
    actionClick() {
      console.log(
        "ABILITY clicked but do not know why... aIndex: " + this.aIndex
      );
    },
  },
  created: function() {
    //console.log(this)
  },
  mounted: function() {},
});
