import Vuex, { Store } from "vuex";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import store from "@/store";
import WTSelect from "@/ts/wt/interaction/WTSelect";
import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEAbility from "@/ts/wt/interaction/WTEAbility";
import WTEAction from "@/ts/wt/interaction/WTEAction";
import WTEAInput from "@/ts/wt/interaction/WTEAInput";

type RootState = typeof store.state;
class State {
  hover = {
    wtid: -1,
  };
  cursor = {
    wgs: [11, 48, 1500],
  };
  //wgsInput = [11, 48, 1500];
  wgsInput = [10.999, 47.999, 1500];
  //wgsInput = [10.7995, 47.7995, 1500];
  //wgsInput = [11.57248043851946, 48.18659162568845, 1500];
  objectsAdd = {
    onClick: false,
    gType: "no-model-set",
  };
  selection = {
    current: -1,
  };
  createAction = {
    overlayIsActive: false,
    ability: {},
    abillitySelection: 0,
    aIndex: -1,
  };
  wteaInput = new WTEAInput();
}

const getters = <GetterTree<State, any>>{
  getWTSelect(state) {
    return store.state.wt.wt.im.getCurrent();
  },
  getPreviousWTSelect(state) {
    return store.state.wt.wt.im.getPrevious();
  },
  getWTSelectIfEntity(state) {
    let wteSelect = store.state.wt.wt.im.getCurrent();
    if (wteSelect.type == WT.Interaction.SelectionType.ENTITY) {
      return wteSelect;
    }
    return undefined;
  },
  getWTSelectEntityType(state) {
    let wtselect = store.state.wt.wt.im.getCurrent();
    if (wtselect.type == WT.Interaction.SelectionType.ENTITY)
      return wtselect.entity.type;
    return undefined;
  },
  getWTSelectEntityAbilities(state) {
    let wtselect = store.state.wt.wt.im.getCurrent();
    if (wtselect.type == WT.Interaction.SelectionType.ENTITY)
      return wtselect.entity.getAbilities();
    return undefined;
  },
  createActionOverlayIsActive(state) {
    return state.createAction.overlayIsActive;
  },
};

const actions = <ActionTree<State, any>>{
  aEngineInjectDevActions({ commit }) {
    commit("mEngineInjectDevActions");
  },

  aEngineStartAllEntityActions({ commit }) {
    commit("mEngineStartAllEntityActions");
  },

  aEngineSetEntityActionAutostart({ commit }, value: boolean) {
    commit("mEngineSetEntityActionAutostart", value);
  },

  aRemoveActionFromEntityQueue({ commit }, value: boolean) {
    commit("mRemoveActionFromEntityQueue", value);
  },

  aInteractionCreateAction({ commit }, input: WTEAInput) {
    commit("mInteractionCreateAction", input);
  },
};

const mutations = <MutationTree<State>>{
  // ---------------------- interaction - game data ---------------------- //
  mGameInteractionSelectionPush(state, wtid: number) {
    let lastSelect = store.state.wt.wt.im.getCurrent();
    // remove selection event
    if (wtid === -1) {
      //prevent double deselects in the queue
      if (lastSelect.type === WT.Interaction.SelectionType.NONE) return;
      console.debug("Prevented double deslect in sHistory");
      store.state.wt.wt.im.push(new WTSelect());
    } else if (wtid === lastSelect.wtid) {
      console.debug("Prevented wtid double select in sHistory");
      return; // ignored multiple selections in history of the same object
    }

    store.state.wt.wt.im.add(wtid);
    // console.warn(
    //   "Interaction triggered: " + store.state.wt.wt.im.getCurrent().toString()
    // );

    // cesium silhouettes color changes
    // let colorSelection =
    //   store.state.settings.UI.csHighlightingSilhouettes.selection;
    // let colorHovering =
    //   store.state.settings.UI.csHighlightingSilhouettes.hovering;
    // let colorModels = store.state.settings.gfx.modelSilhouettes;
    // if (
    //   typeof previousSelection !== "undefined" &&
    //   previousSelection.wtObj == "wtWorldTerrain"
    // ) {
    //   CesiumStore.fCsSelectionSetTerrainSilhouette(
    //     state,
    //     previousSelection.wtid,
    //     colorModels
    //   );
    // }
    // if (selection.wtObj == "wtWorldTerrain") {
    //   fCsSelectionSetTerrainSilhouette(state, selection.wtid, colorSelection);
    // }
    //console.log(state.selection.player)
  },

  mEngineStartAllEntityActions(state) {
    //console.warn("mEngineStartAllEntityActions()")
    store.state.wt.wt.startAllEntityActionsDev();
  },

  mEngineSetEntityActionAutostart(state, value: boolean) {
    //console.warn("mEngineSetEntityActionAutostart()")
    store.state.wt.wt.setAllEntititiesActionAutostartDev(value);
  },

  mInteractionReset(state) {
    store.state.wt.wt.im.push(new WTSelect());
  },

  mInteractionCreatedActionOverlay(
    state,
    input: { showOverlay: boolean; ability: WTEAbility; aIndex: number }
  ) {
    if (input.showOverlay) {
      state.createAction.overlayIsActive = true;
      state.createAction.ability = input.ability;
      state.createAction.aIndex = input.aIndex;
      console.warn("set aIndex: " + state.createAction.aIndex);
      //console.warn("Create action for " + input.ability);
    } else {
      state.createAction.overlayIsActive = false;
    }
  },

  mInteractionCreateAction(state, input: WTEAInput) {
    input.ability.createAction(input);
    if (input.startNow) {
      input.entity.actionAutoStart = true;
    }
  },

  mRemoveActionFromEntityQueue(state, action: WTEAction) {
    store.state.wt.wt.em.removeActionFromEntityQueue(action);
  },

  mChangeLoopAction(
    state,
    input: { action: WTEAction; value: boolean; aIndex: number }
  ) {
    let entity = store.state.wt.wt.em.get(input.action.ability.entity.wtid);
    if (entity) {
      entity.actions[input.aIndex].input.loopAction = input.value;
      entity.actions[input.aIndex].loopAction = input.value;
      //console.log("loopAction: " + input.action.loopAction);
      console.log("loopAction: " + entity.actions[input.aIndex].loopAction);
    }
  },
};

const storeInteraction = {
  namespaced: false,
  modules: {},
  state: new State(),
  getters: getters,
  actions: actions,
  mutations: mutations,
};

export default storeInteraction;
