



































import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import Vue from "vue";

import DetailsComposables from "@/composables/DetailsComposables";
import Tribe from "@/ts/wt/entities/Tribe";
import AbilitiesList from "@/components/lists/AbilitiesList.vue";

export default Vue.extend({
  name: "DetailsTribe",
  components: { AbilitiesList },
  props: {
    wtEntity: { type: Tribe, required: true },
  },
  data: () => ({}),
  setup(wtEntity) {
    const { textComputed, flyTo } = DetailsComposables(
      wtEntity.wtEntity as Tribe
    );
    //console.warn((wtEntity.wtEntity as Tribe).title);
    return { textComputed, flyTo };
  },
  computed: {
    ...mapGetters([
      "l10n",
      "wt",
      "settings",
      "getWTSelectIfEntity",
      "getWTSelectEntityAbilities",
    ]),
    ...mapState({
      wtid: (state: any) => state.interaction.selection.last,
    }),
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    submitJson() {
      console.log("breakpoint " + event);
    },
  },
});
