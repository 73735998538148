import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    themes: {
      light: {
        primary: "#007a00",
        secondary: "#10DD00",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#0080FF",
        success: "#4CAF50",
        warning: "#FFC107",
      },
      dark: {
        primary: "#00ad00",
        secondary: "#10DD00",
        accent: "#82B1FF",
        error: "#FF2222",
        info: "#0080FF",
        success: "#00AA00",
        warning: "#FFC107",
      },
    },
  },
});
