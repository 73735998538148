import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEAbility from "../../WTEAbility";
import WTEAction from "../../WTEAction";
import WTEAInput from "../../WTEAInput";
import WTHelpers from "../../../lib/WTHelpers";
import TimeManager from "@/ts/wt/managers/TimeManager";
import WTValue from "@/ts/wt/lib/WTValue/WTValue";
import Tribe from "@/ts/wt/entities/Tribe";
import WGS from "@/ts/wt/lib/WGS";
import WTVLinear from "@/ts/wt/lib/WTValue/WTVLinear";
import Population from "@/ts/wt/entities/Population/Population";

export default class WTEASettleDown extends WTEAction {
  type: WT.Entity.Action;

  tribe!: Tribe;
  population!: Population;

  REQUIRED_STATE: WT.Tribe.State = WT.Tribe.State.WANDERING_TRIBE;
  TARGET_STATE: WT.Tribe.State = WT.Tribe.State.SETTELED_TRIBE;

  settleDownDurationValue = TimeManager.UNITS.HOUR;
  readonly settleDownWGSRange = 0.0005;
  settleDownWTValue!: WTValue;
  settleDownA1 = -1;

  // TODO: Insert/replace simulation variables here
  actionValue1: number = 0;
  actionValue2: number = 0;

  constructor(ability: WTEAbility, input?: WTEAInput) {
    super(ability, input);

    this.type = WT.Entity.Action.TRIBE_SETTLE_DOWN;
  }

  // ------------------------ START action functions ------------------------ \\
  init(input: WTEAInput): WTEAction {
    this.superInit();
    // assigne potential new input in case of re-init call
    this.input = input;
    this.tribe = this.entity as Tribe;
    this.population = this.entity.population;
    return this;
  }

  initPreConditions(): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  listPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  checkPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    let failedConds: Array<WT.Interaction.PreCondtionType> = [];
    // do additional pre condition checks here
    failedConds = failedConds.concat(
      this.entity.population.checkFoodSaturation(0)
    );
    failedConds = failedConds.concat(
      this.tribe.checkState(this.REQUIRED_STATE)
    );
    if (
      !WGS.checkMaxDistance(
        failedConds,
        this.entity,
        this.input.target,
        this.settleDownWGSRange
      )
    )
      console.warn("WGS Distance check not passed");
    return failedConds;
  }

  start(): boolean {
    let preCondditions = this.checkPreConditions();
    if (!WTHelpers.checkPreConditions(preCondditions)) {
      WTHelpers.preConditionsToLog(preCondditions);
      return false;
    } else {
      this.updateAttributes(this.input);
      this.settleDownWTValue = new WTVLinear(
        this.entity.wt.tm.now(),
        this.settleDownA1,
        0
      );
      this.timeBoundariesSet(this.isDoneIn());
      console.log("aSettleDownStart()");
      return true;
    }
  }

  update(tStamp: number): WTEAction {
    this.superUpdate();
    if (this.isDoneIn() <= 0) {
      this.tribe.changeState(WT.Tribe.State.SETTELED_TRIBE);
      this.finish();
    } else {
      // other updates
    }
    return this;
  }

  updateAttributes(input: WTEAInput): WTEAction {
    this.settleDownA1 = 1 / this.settleDownDurationValue;
    return this;
  }

  isDoneIn(): number {
    let rt = this.settleDownWTValue.calculateTimeUntil(1, this.tm.now());
    console.log("aSettleDownDoneIn: " + TimeManager.toHours(rt) + " hours");
    return rt;
  }

  finish(): boolean {
    let target = this.input.tEntity;
    console.log(
      "WTEASettleDown().finish(): " + target.toString() + ", " + target.type
    );
    this.entity.wt.em.remove(target.wtid);
    console.log(this.entity.wt.em.wm);
    this.superFinish();
    return this.finished;
  }

  cancel(): boolean {
    this.superCancel();
    throw new Error("Method not implemented.");
    return this.canceled;
  }

  // ---------------------------- action helpers ---------------------------- \\

  // ------------------------------ wt helpers ------------------------------ \\
}
