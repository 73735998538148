import WTSelect from "../interaction/WTSelect";
import Worldtrix from "../Worldtrix";

export default class InteractionManager {
  //init with two empty selects to prevent null-pointers for getters
  sHistory: Array<WTSelect> = [new WTSelect(), new WTSelect()];
  private sHistoryCurrentIndex = 1;

  wt: Worldtrix;

  constructor(wt: Worldtrix) {
    this.wt = wt;
  }

  add(wtid: number): InteractionManager {
    let wtSelect = new WTSelect(wtid, this.wt);
    this.sHistory.push(wtSelect);
    this.sHistoryCurrentIndex++;
    return this;
  }

  push(selection: WTSelect): InteractionManager {
    this.sHistory.push(selection);
    this.sHistoryCurrentIndex++;
    return this;
  }

  getCurrent(): WTSelect {
    return this.sHistory[this.sHistoryCurrentIndex];
  }

  getPrevious(): WTSelect {
    return this.sHistory[this.sHistoryCurrentIndex - 1];
  }
}
