










import TribeList from "@/components/lists/TribesList.vue";
import TaskForcesList from "@/components/lists/TaskForcesList.vue";
import ObjectsList from "@/components/lists/ObjectsList.vue";
import TerrainList from "@/components/lists/TerrainList.vue";
//import RessourcesList from "@/components/lists/RessourcesList.vue";

import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import Vue from "vue";

export default Vue.extend({
  name: "WorldPanel",
  components: {
    TribeList,
    TaskForcesList,
    ObjectsList,
    TerrainList,
  },
  props: {},
  data: () => ({
    target: {
      long: 11.0,
      lat: 48,
      height: 1500,
    },
  }),
  computed: {
    localComputed(): string {
      return "localComputed() string";
    },
    ...mapState([]),
    ...mapGetters(["em", "wm"]),
  },
  methods: {
    ...mapGetters([]),
    ...mapActions(["aCsInitRefresh"]),
    ...mapMutations([
      "mCsCameraFlyToTarget",
      "mCsDrawWorldObjects",
      "mCsDrawTest",
    ]),
    vCameraFlyToTargetHelper() {
      this.$store.commit("mCsCameraFlyToTarget", this.target);
    },
    vTribeClick(wtid: number) {
      console.log("tribe click: " + wtid);
    },
    vTroopClick(wtid: number) {
      console.log("troop click: " + wtid);
    },
    vObjectClick(wtid: number) {
      console.log("object click: " + wtid);
    },
    vTerrainClick(wtid: number) {
      console.log("terrain click: " + wtid);
    },
    vRessourceClick(wtid: number) {
      console.log("ressource click: " + wtid);
    },
  },
  created: function() {
    //this.$store.dispatch("awtInitWorld");
    //console.log(this)
  },
  mounted: function() {},
});
