import Worldtrix from "../Worldtrix";
import WTEntity from "../entities/WTEntity";
import WTEAction from "./WTEAction";
import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEAInput from "./WTEAInput";
import WTHelpers from "../lib/WTHelpers";
import WTEAAFactory from "./actions/WTEAAFactory";

export default class WTEAbility {
  title: string = "unnamed ability";
  description: string = "default ability description";

  entity!: WTEntity;
  index: number = -1;
  action!: WTEAction; //serves as helper to verfiy if an action is possible but is never exceuted
  actionType: WT.Entity.Action;

  preCondsRequired!: Array<WT.Interaction.PreCondtionType>;
  preCondsFailed!: Array<WT.Interaction.PreCondtionType>;

  constructor(
    actionType: WT.Entity.Action,
    entity?: WTEntity,
    title?: string,
    description?: string,
    input?: WTEAInput
  ) {
    this.actionType = actionType;
    if (actionType == WT.Entity.Action.NULL) return;
    else if (entity && title && description) {
      this.title = title;
      this.description = description;
      this.entity = entity;
      this.action = WTEAAFactory.generateActionForAbility(
        this,
        this.actionType,
        input
      );
    }
    // TODO [WT-170]
    // this.preCondsRequired = this.action.listPreConditions(input);
  }

  createAction(input: WTEAInput): WTEAction {
    let action = WTEAAFactory.generateActionForAbility(
      this,
      this.actionType,
      input
    );
    // console.log(
    //   `${this.action.entity.wtid}.${this.constructor.name}.createAction() -> ${
    //     action.constructor.name
    //   } Input: ${input.toString()}`
    //);
    console.log(this.entity.actions);
    return action;
  }

  checkPreConditions(input: WTEAInput): boolean {
    this.preCondsFailed = this.action.checkPreConditions(input);
    return WTHelpers.checkPreConditions(this.action.preCondsFailed);
  }

  getPreConditionsRequired(
    input?: WTEAInput
  ): Array<WT.Interaction.PreCondtionType> {
    this.preCondsRequired = this.action.listPreConditions(input);
    return this.preCondsRequired;
  }

  static compareTitle(a: WTEAbility, b: WTEAbility): number {
    if (a.title < b.title) return -1;
    else if (a.title > b.title) return 1;
    else return 0;
  }

  /****************************** wt-helpers ******************************/

  log(msg?: string): void {
    console.log(
      `Ability.log(): ${this.constructor.name} ${this.title} : ${msg}`
    );
  }

  logWarn(msg?: string): void {
    console.warn(
      `Ability.logWarn(): ${this.constructor.name} ${this.title} : ${msg}`
    );
    console.warn(this);
  }

  logError(msg?: string): void {
    console.error(
      `Ability.logError(): ${this.constructor.name} ${this.title} : ${msg}`
    );
    console.warn(this);
  }
}
