
























import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import Vue from "vue";
import WTSelect from "@/ts/wt/interaction/WTSelect";
import ActionListItem from "@/components/lists/items/ActionListItem.vue";

export default Vue.extend({
  name: "ActionList",
  components: { ActionListItem },
  props: {},
  data: () => ({
    benched: 0,
  }),
  computed: {
    ...mapGetters([
      "wt",
      "getWTSelect",
      "getWTSelectIfEntity",
      "getWTSelectEntityType",
    ]),
    ...mapState({}),
    wteActions() {
      let select = this.getWTSelect as WTSelect;
      //console.warn(select);
      if (select.entity) {
        return select.entity.actions;
      } else {
        return undefined;
      }
    },
    isDoneIn() {
      let value = (this.getWTSelect as WTSelect).entity
        .getCurrentAction()
        ?.isDoneIn();
      if (value && value > 0) {
        return "" + value;
      }
      return "unkown";
    },
    length() {
      return 7000;
    },
  },
  methods: {
    ...mapActions(["awtGameStep"]),
    ...mapMutations([]),
    templateFunction: function(event: any) {
      console.log("breakpoint " + event);
    },
  },
  created: function() {},
});
