

























import Vue from "vue";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

import ListItemComposables from "@/composables/ListItemComposables";
import Terrain from "@/ts/wt/entities/Terrain";

export default Vue.extend({
  name: "TerrainListItem",
  components: {},
  props: {
    wtTerrain: { type: Terrain, required: true },
  },
  data: () => ({}),
  setup(wtTerrain) {
    const { textComputed, iClick, iDoubleClick } = ListItemComposables(
      wtTerrain.wtTerrain as Terrain
    );
    return { textComputed, iClick, iDoubleClick };
  },
  computed: {
    composeDescription() {
      let type = this.wtTerrain.tType;
      let name = this.$store.getters.l10nTerrain(type);
      //console.log("TileContent:" + type + ": " + name);
      return name;
    },
    ...mapState({}),
    ...mapGetters(["l10n", "settings"]),
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    emptyFunction(wtid: number) {
      console.log("click: " + wtid);
    },
  },
  created: function() {
    //this.iDoubleClick(888);
    //console.log(this)
  },
  mounted: function() {},
});
