




























































import Vue from "vue";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default Vue.extend({
  name: "WGSInput",
  components: {},
  props: {
    shortkey: String,
  },
  data: () => ({}),
  computed: {
    ...mapState({ wgsInput: (state) => state.interaction.wgsInput }),
    ...mapGetters(["wt"]),
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    cameraFlyToTargetHelper: function() {
      this.$store.dispatch("aCsCameraFlyToLongLat", this.wgsInput);
    },
    clearLat() {},
  },
  created: function() {},
  mounted: function() {},
});
