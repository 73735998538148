import Vuex from "vuex";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import store from "@/store";

import Worldtrix from "@/ts/wt/Worldtrix";

type RootState = typeof store.state;

class State {
  gameEngineData = {
    gameLoopCounter: 0,
    gameLoopTime: 250,
    movingPoint: [48.0, 11.0, 0],
  };
  wt: Worldtrix = new Worldtrix();
}

// javascript variables when no vuex store-state is availabe e.g. for setInterval()
const wtDataJS = {
  gameLoopIntervalHandle: -1,
};

const getters = <GetterTree<State, any>>{
  getMovingPointCoors: (state) => {
    return state.gameEngineData.movingPoint;
  },
  gameEngineData: (state) => state.gameEngineData,
  wt: (state) => state.wt,
  tm: (state) => state.wt.tm,
  em: (state) => state.wt.em,
  pm: (state) => state.wt.pm,
  wm: (state) => state.wt.wm,
  im: (state) => state.wt.im,
};

const actions = <ActionTree<State, any>>{
  awtStartGame({ commit, dispatch, state }) {
    //GAME LOOP
    if (!state.wt.tm.running) {
      wtDataJS.gameLoopIntervalHandle = setInterval(function() {
        dispatch("awtGameStep");
      }, state.gameEngineData.gameLoopTime);
      commit("mWTSetGameStatus", true);
      //console.log("Game loop started...")
    } else {
      clearInterval(wtDataJS.gameLoopIntervalHandle);
      commit("mWTSetGameStatus", false);
      //console.log("Game loop stopped...")
    }
  },

  awtGameStep({ commit }) {
    commit("mEngineSimulationStep");
  },
};

const mutations = <MutationTree<State>>{
  mEngineInjectDevActions(state) {
    //console.warn("mEngineInjectDevActions()")
    state.wt.injectDevActions();
  },

  mEngineSimulationStep(state) {
    //console.warn("mEngineSimulationStep triggered")
    state.gameEngineData.gameLoopCounter++;
    state.wt.valueCalculation();
  },

  mWTSetGameStatus(state, ON: boolean) {
    if (ON) {
      state.wt.timeStart();
    } else {
      state.wt.timeStop();
    }
  },
};

const storeWorldtrix = {
  namespaced: false,
  modules: {},
  state: new State(),
  getters: getters,
  actions: actions,
  mutations: mutations,
};

export default storeWorldtrix;
