import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEAction from "../WTEAction";
import WTEAInput from "../WTEAInput";
import WTEAbility from "../WTEAbility";
import WGS from "../../lib/WGS";
import WTEntity from "../../entities/WTEntity";
import Inventory from "../../Inventory/Inventory";
import Population from "../../entities/Population/Population";

export default class WTEAFoodConsume extends WTEAction {
  preConditions: WT.Interaction.PreCondtionType[] = [];
  type: WT.Entity.Action;

  inventory!: Inventory;
  maxSaturation!: boolean;
  population!: Population;

  constructor(ability: WTEAbility, input?: WTEAInput) {
    super(ability, input);
    this.type = WT.Entity.Action.BASIC_FOOD_CONSUME;
  }

  // ------------------------ START action functions ------------------------ \\
  init(input: WTEAInput): WTEAction {
    this.superInit();
    this.input = input;
    this.inventory = this.input.entity.inventory;
    if (input.tEntity) this.inventory = this.input.tEntity.inventory;
    this.maxSaturation = this.input.maxSaturation;
    this.population = this.entity.population;
    return this;
  }

  initPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  listPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  checkPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    let failedConds: Array<WT.Interaction.PreCondtionType> = [];
    // if (
    //   !WGS.checkMaxDistanceForInput(
    //     failedConds,
    //     this.input,
    //     WTEntity.Attributes.FoodConsume.DISTANCE_MAX
    //   )
    // ) {
    //   console.warn("WGS Distance check not passed");
    // }
    return failedConds;
  }

  start(): boolean {
    this.superStart();
    if (!this.started) {
      return false;
    }
    console.warn("aFoodConsumeStart()");

    this.durationRemaining = WTEntity.Attributes.FoodConsume.DURATION;
    return true;
  }

  update(tStamp: number): WTEAction {
    this.superUpdate();
    this.durationRemaining -= this.updatedTimeDelta;
    if (this.durationRemaining <= 0) {
      this.entity.population.logSaturation();
      //console.error("blubb")
      let maxSaturation = this.maxSaturation;
      let foodItems = this.inventory.getItemsOfType(WT.Inventory.Type.FOOD);
      let foodAvailable = Inventory.sumItemAmount(foodItems);
      let foodReq = this.population.foodForSaturation(maxSaturation);
      //console.log(`Food-Before: for saturation: ${foodReq} MAX: ${this.population.foodForSaturation(true)} Remaining food: ${foodAvailable}`)
      if (foodAvailable >= foodReq) {
        let leftOver = this.population.eatFoodAmount(foodReq, maxSaturation);
        Inventory.subItemAmount(foodItems, foodReq);
        if (leftOver >= 0.000000000001)
          throw new Error(`Left over food ${leftOver} but 0 expected.`);
      } else {
        let leftOver = Inventory.subItemAmount(foodItems, foodReq);
        let feededAmount = foodReq - leftOver;
        this.population.eatFoodAmount(feededAmount, maxSaturation);
        console.log("Not enough food to feed the all...");
      }
      //console.log(
      //    `Food-After: for saturation: ${this.population.foodForSaturation(false)} MAX: ${this.population.foodForSaturation(true)}  Remaining food: ${Inventory.sumItemAmount(foodItems)}`)
      this.population.logSaturation();
      this.finish();
    }
    return this;
  }

  updateAttributes(input: WTEAInput): WTEAction {
    return this;
  }

  isDoneIn(): number {
    return this.durationRemaining;
  }

  finish(): boolean {
    this.superFinish();
    return this.finished;
  }

  cancel(): boolean {
    this.superCancel();
    return this.canceled;
  }
}
