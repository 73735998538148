import Human from "@/ts/wt/entities/Population/Human";
import TimeManager from "../../managers/TimeManager";
import * as WT from "@/ts/wt/declaration/WTEnums";
import Worldtrix from "../../Worldtrix";
import { DebugAppearance } from "cesium";
import WTEntity from "../WTEntity";

export default class Population {
  static readonly Attributes = {
    growFoodValue: 1.5,
  };

  humans: Array<Human>;
  femalesCount: number = -1;

  //time
  updatedTime: number;
  updatedPreviousTime: number;
  updatedTimeDelta: number = 0;

  entity: WTEntity;
  wt: Worldtrix;

  constructor(entity: WTEntity, populationData?: any) {
    this.entity = entity;
    this.wt = entity.wt;
    this.updatedTime = this.updatedPreviousTime = this.wt.tm.now();
    this.humans = [];
    if (populationData) {
      // build pop from JSON
      let sex = 0;
      // roll babys
      for (let i = 0; i < populationData.babys; i++) {
        let age = Human.calRndAgeBaby();
        this.humans.push(new Human(this.wt, age, sex++));
        sex = sex % 2;
      }
      //children
      for (let i = 0; i < populationData.children; i++) {
        let age = Human.calRndAgeChild();
        this.humans.push(new Human(this.wt, age, sex++));
        sex = sex % 2;
      }

      //adults
      for (let i = 0; i < populationData.adults; i++) {
        let age = Human.calRndAgeAdult();
        this.humans.push(
          new Human(this.wt, age, sex++).setChildrenCountForAge()
        );
        sex = sex % 2;
      }
    }
    this.femalesCountUpdate();
    this.sortHumans();

    //this.logw("constructed")
  }

  private sortHumans() {
    this.humans.sort(Human.compareAge);
    this.humans.reverse();
  }

  /**
   *
   * @param humans - the array which is changed in place
   * @returns the switched array in place
   */

  static switchArrayElements(array: Array<any>): void {
    for (let i = 1; i < array.length / 2; i = i + 2) {
      let v1 = array[i];
      let i2 = array.length - 1 - i;
      let v2 = array[i2];
      array[i] = v2;
      array[i2] = v1;
    }
  }

  private updateTimeHelper(): number {
    this.updatedPreviousTime = this.updatedTime;
    this.updatedTime = this.wt.tm.now();
    this.updatedTimeDelta = this.updatedTime - this.updatedPreviousTime;
    return this.updatedTimeDelta;
  }

  update() {
    //console.log("Population.update()")
    this.updateTimeHelper();
    for (let hum of this.humans) {
      hum.update(this.updatedTimeDelta);
      if (hum.pregnancyState !== WT.Human.PregnancyState.INFERTIL) {
        let pResult = hum.pregnancyUpdate(this.updatedTimeDelta);
        if (pResult === WT.Human.PregnancyState.BIRTH) {
          let baby = new Human(this.wt, 0);
          console.warn("BABY!!!");
          this.humans.push();
        }
      }
    }
    // let pregnantArray = this.humans.filter(female => female.pregnancyState === WT.Human.PregnancyState.YES)
    // console.log("Pregnant Count: " + pregnantArray.length)
  }

  eatFoodAmount(foodAmount: number, maxSaturation: boolean): number {
    let leftOver = foodAmount;
    this.humans = this.humans.sort(Human.compareSaturation);
    for (let hum of this.humans) {
      leftOver = hum.foodEatTillSaturation(leftOver, maxSaturation);
      if (leftOver <= 0) break;
    }
    return leftOver;
  }

  checkMinFoodLevel(
    tLevel: WT.Population.FoodLevel
  ): Array<WT.Interaction.PreCondtionType> {
    let fLevel = this.getFoodLevel();
    if (fLevel === WT.Population.FoodLevel.WELL_FEED) {
      return [];
    }
    if (tLevel == WT.Population.FoodLevel.SATURATED) {
      if (fLevel === WT.Population.FoodLevel.SATURATED) return [];
      return [WT.Interaction.PreCondtionType.FOOD_LEVEL_TO_LOW];
    } else if (tLevel === WT.Population.FoodLevel.HUNGRY) {
      if (
        fLevel === WT.Population.FoodLevel.SATURATED ||
        fLevel === WT.Population.FoodLevel.HUNGRY
      )
        return [];
      return [WT.Interaction.PreCondtionType.FOOD_LEVEL_TO_LOW];
    }
    return [WT.Interaction.PreCondtionType.FOOD_LEVEL_TO_LOW];
  }

  checkFoodSaturation(tValue: number): Array<WT.Interaction.PreCondtionType> {
    let satValue = this.getFoodSaturation();
    if (tValue <= satValue) return [];
    return [WT.Interaction.PreCondtionType.FOOD_LEVEL_TO_LOW];
  }

  getFoodLevel(): WT.Population.FoodLevel {
    let foodReq = this.foodForSaturation();
    if (foodReq <= 0) return WT.Population.FoodLevel.WELL_FEED;
    else if (this.getFoodSaturation() > 0)
      return WT.Population.FoodLevel.SATURATED;
    else return WT.Population.FoodLevel.HUNGRY;
  }

  getFoodSaturation(): number {
    let foodLevel = 0;
    for (let hum of this.humans) {
      foodLevel += hum.foodSaturation;
    }
    return foodLevel;
  }

  /**
   *
   * @param maxSaturation if true the value for oversaturation is calculated instead
   * @returns Get the missing of the population as float
   */
  foodForSaturation(maxSaturation?: boolean): number {
    let foodReq = 0;
    for (let hum of this.humans) {
      foodReq += hum.foodForSaturation(maxSaturation);
    }
    return foodReq;
  }

  split(splitCase: WT.Population.SplitType, amount?: number): Population {
    let rPop = new Population(this.entity);
    let newPop: Array<Human> = [];
    let oldPop: Array<Human> = [];
    let sAmount = Math.round(this.humans.length * 0.5);
    if (amount) sAmount = amount;
    this.sortHumans();
    Population.switchArrayElements(this.humans);
    //this.logw("switchArrayElement")
    switch (splitCase) {
      case WT.Population.SplitType.FULL:
        {
          newPop = this.humans.slice(0, sAmount);
          oldPop = this.humans.slice(sAmount, this.humans.length);
        }
        break;
      case WT.Population.SplitType.ADULTS:
        {
          let selectionArray: Array<Human> = this.humans
            .filter((hum) => hum.ageState == WT.Human.AgeState.ADULT)
            .sort(Human.compareAge);
          let noneSelection: Array<Human> = this.humans
            .filter((hum) => hum.ageState != WT.Human.AgeState.ADULT)
            .sort(Human.compareAge);
          if (selectionArray.length > sAmount) {
            newPop = selectionArray.slice(0, sAmount);
            oldPop = selectionArray
              .slice(sAmount, selectionArray.length)
              .concat(noneSelection);
          } else {
            newPop = selectionArray;
            oldPop = noneSelection;
          }
        }
        break;
      case WT.Population.SplitType.ADULTS_MALES:
        {
          let selectionArray: Array<Human> = this.humans.filter(
            (hum) => hum.age > Human.Attributes.AgeMax.CHILD && hum.sex == 0
          );
          let noneSelection: Array<Human> = this.humans.filter(
            (hum) => hum.age <= Human.Attributes.AgeMax.CHILD || hum.sex == 1
          );
          if (selectionArray.length > sAmount) {
            newPop = selectionArray.slice(0, sAmount);
            oldPop = selectionArray
              .slice(sAmount, selectionArray.length)
              .concat(noneSelection);
          } else {
            newPop = selectionArray;
            oldPop = noneSelection;
          }
        }
        break;
      case WT.Population.SplitType.ADULTS_FEMALES:
        {
          let selectionArray: Array<Human> = this.humans.filter(
            (hum) =>
              hum.ageState == WT.Human.AgeState.ADULT &&
              hum.sex == WT.Human.Sex.FEMALE
          );
          let noneSelection: Array<Human> = this.humans.filter(
            (hum) =>
              hum.age <= Human.Attributes.AgeMax.CHILD ||
              hum.sex == WT.Human.Sex.MALE
          );
          if (selectionArray.length > sAmount) {
            newPop = selectionArray.slice(0, sAmount);
            oldPop = selectionArray
              .slice(sAmount, selectionArray.length)
              .concat(noneSelection);
          } else {
            newPop = selectionArray;
            oldPop = noneSelection;
          }
        }
        break;
      case WT.Population.SplitType.ADULTS_CHILDREN:
        {
          let selectionArray: Array<Human> = this.humans.filter(
            (hum) => hum.age > Human.Attributes.AgeMax.BABY
          );
          let noneSelection: Array<Human> = this.humans.filter(
            (hum) => hum.age < Human.Attributes.AgeMax.BABY
          );
          if (selectionArray.length > sAmount) {
            newPop = selectionArray.slice(0, sAmount);
            oldPop = selectionArray
              .slice(sAmount, selectionArray.length)
              .concat(noneSelection);
          } else {
            newPop = selectionArray;
            oldPop = noneSelection;
          }
        }
        break;
      case WT.Population.SplitType.CHILDREN:
        {
          let selectionArray: Array<Human> = this.humans.filter(
            (hum) => hum.ageState == WT.Human.AgeState.CHILD
          );
          let noneSelection: Array<Human> = this.humans.filter(
            (hum) => hum.ageState != WT.Human.AgeState.CHILD
          );
          if (selectionArray.length > sAmount) {
            newPop = selectionArray.slice(0, sAmount);
            oldPop = selectionArray
              .slice(sAmount, selectionArray.length)
              .concat(noneSelection);
          } else {
            newPop = selectionArray;
            oldPop = noneSelection;
          }
        }
        break;
      default:
        throw new Error("Unkown Wt.Population.Split");
    }
    this.humans = oldPop;
    this.femalesCountUpdate();
    this.sortHumans();
    rPop.humans = newPop;
    rPop.femalesCountUpdate();
    rPop.sortHumans();
    //this.logw(`target of split()`)
    //rPop.logw(`created through split()`)
    return rPop;
  }

  merge(mergePop: Population): Population {
    mergePop.update();
    this.update();
    this.humans.concat(mergePop.humans);
    this.humans.sort(Human.compareAge);
    this.humans.reverse();
    this.femalesCountUpdate();
    this.update();
    return this;
  }

  protected femalesCountUpdate(): number {
    this.femalesCount = this.humans.filter(
      (human) => human.sex == WT.Human.Sex.FEMALE
    ).length;
    return this.femalesCount;
  }

  calculateInventoryBaseValue(): number {
    let iSize = 0;
    for (let human of this.humans) {
      switch (human.ageState) {
        case WT.Human.AgeState.ADULT:
          iSize += Human.Attributes.InventorySize.ADULT;
          break;
        case WT.Human.AgeState.CHILD:
          iSize += Human.Attributes.InventorySize.CHILD;
          break;
        default:
      }
    }
    return iSize;
  }

  size(): number {
    return this.humans.length;
  }

  toString(): string {
    return `${this.humans.length} humans`;
  }

  logw(msg?: string): void {
    if (msg) console.warn("Population.cLog(): " + msg);
    else console.warn("Population.cLog()");
    console.warn("----------------------------------");
    let count = 0;
    for (let human of this.humans) {
      human.logw("");
      count++;
    }
    console.warn("----------------------------------");
    console.warn(`count: ${count} humans, ${this.femalesCount} females`);
    console.warn("----------------------------------");
  }

  logSaturation() {
    console.log("-------------POPULATION-START-------------");
    console.log(`Satauration level for Poulation size ${this.size()}`);
    console.log(`sat need normal:  ${this.foodForSaturation(false)}`);
    let foodMax = this.foodForSaturation(true);
    console.log(
      `sat MAX cap:  ${foodMax *
        Human.Attributes
          .FoodSatutrationMaxEfficency} ->  max food need: ${foodMax}`
    );
    console.log("--------------POPULATION-END--------------");
  }
}
