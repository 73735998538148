import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEAction from "../../WTEAction";
import WTEAInput from "../../WTEAInput";
import WTEAbility from "../../WTEAbility";
import WGS from "../../../lib/WGS";
import WTHelpers from "../../../lib/WTHelpers";

export default class WTEAJoinTribe extends WTEAction {
  type: WT.Entity.Action;

  joinTribeDuration: number = 0;
  joinMergeDistance: number = 0.0005;

  constructor(ability: WTEAbility, input?: WTEAInput) {
    super(ability, input);
    this.type = WT.Entity.Action.TASKFORCE_JOIN_TRIBE;
  }

  // ------------------------ START action functions ------------------------ \\
  init(input: WTEAInput): WTEAction {
    this.superInit();
    // assigne potential new input in case of re-init call
    this.input = input;
    return this;
  }

  initPreConditions(): WT.Interaction.PreCondtionType[] {
    //Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  listPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    //Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  checkPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    let failedConds: Array<WT.Interaction.PreCondtionType> = [];
    if (
      WGS.distance(this.entity.wgs, this.input.tEntity.wgs) >
      this.joinMergeDistance
    ) {
      console.warn("Too far away from tribe to join.");
      failedConds.push(WT.Interaction.PreCondtionType.WGS_DISTANCE);
    }
    return failedConds;
  }

  start(): boolean {
    let preCondditions = this.checkPreConditions();
    if (!WTHelpers.checkPreConditionsWARNLog(preCondditions)) {
      WTHelpers.preConditionsToLog(preCondditions);
      return false;
    } else {
      this.joinTribeDuration = 0;
      console.log("TaskForce.aJoinTribeStart()");
      return true;
    }
  }

  update(tStamp: number): WTEAction {
    let tribe = this.input.tEntity;
    if (tribe) {
      tribe.population.merge(this.entity.population);
      tribe.inventory.merge(this.entity.inventory);
      this.entity.wt.em.remove(this.entity.wtid);
      this.finish();
      return this;
    }
    throw new Error(
      "This should really not happen. Not tribe entity to merge TaskForce"
    );
  }

  updateAttributes(input: WTEAInput): WTEAction {
    return this;
  }

  isDoneIn(): number {
    return this.joinTribeDuration;
  }

  finish(): boolean {
    this.superFinish();
    return this.finished;
  }

  cancel(): boolean {
    this.superCancel();
    return this.canceled;
  }

  // ---------------------------- action helpers ---------------------------- \\

  // ------------------------------ wt helpers ------------------------------ \\
}
