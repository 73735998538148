import Vuex from "vuex";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import store from "@/store";

type RootState = typeof store.state;

class State {
  editor = {
    allowed: true,
    isOn: false,
    terrain: {
      pointsStacking: {
        terrainsTargetCounter: 2,
        terrainsUseCounter: 0,
        terrainsAffected: [
          {
            wtid: -1,
            pointWGS: [-1, -1, -1],
            pointIndex: -1,
            wtidObj: null,
          },
        ],
        newPoint: [0],
      },
    },
  };
}

const getters = <GetterTree<State, any>>{
  getEditorIsOn: (state) => state.editor.isOn,
  getEditorAllowed: (state) => state.editor.allowed,
};

const actions = <ActionTree<State, any>>{};

const mutations = <MutationTree<State>>{
  mEditorSetIsOn(state, value) {
    console.log("Editor Mode: " + value);
    state.editor.isOn = value;
  },

  mEditorRemoveSelectedTerrain(state) {
    if (typeof store.state.interaction.selection.current == "undefined") return;
    let wtidSelected = store.state.interaction.selection.current;
    //console.log("mEditorRemoveSelectedTerrain called. Current selection: " + wtidSelected)
    if (wtidSelected == -1) return;
    let featuresArray =
      store.state.dataLoad.wtWorldData.wtWorldTerrain
        .wtWorldFeatureCollections[0].features;
    let selectedTerrain = featuresArray.find(
      (terrain) => terrain.wtid === wtidSelected
    );
    if (!selectedTerrain) return; // quit if something went wrong for finding the data
    //reset state to prevent errors
    store.state.interaction.selection.player = [];
    store.state.interaction.selection.current = -1;
    let filteredTerrain = featuresArray.filter(
      (terrain) => terrain.wtid !== wtidSelected
    );
    store.state.dataLoad.wtWorldData.wtWorldTerrain.wtWorldFeatureCollections[0].features = filteredTerrain;
    store.state.dataLoad.wtWorldData.wtWorldTerrainObjects.objectsDataArray = store.state.dataLoad.wtWorldData.wtWorldTerrainObjects.objectsDataArray.filter(
      (object) => object.pwtid !== wtidSelected
    );
  },

  mEditorTerrainPointStackTerrainStackInfoUpdate(state, stackInfo) {
    state.editor.terrain.pointsStacking.terrainsTargetCounter =
      stackInfo.terrainsTargetCounter;
  },

  mEditorTerrainPointStackPrecalculate(state) {
    //console.log("mEditorTerrainPointStackPrecalculate");
    let featuresArray =
      store.state.dataLoad.wtWorldData.wtWorldTerrain
        .wtWorldFeatureCollections[0].features;
    let lastClick = store.state.cesium.cursor.lastMouseClickPosition;
    let pSelection = store.state.interaction.selection.player;

    let targetStackNumber =
      state.editor.terrain.pointsStacking.terrainsTargetCounter;

    if (pSelection.length < targetStackNumber) {
      state.editor.terrain.pointsStacking.terrainsUseCounter = 0;
      return;
    }

    let terrainsAffected = [];
    let terrainsUseCounter = 0;
    for (
      let i = pSelection.length - 1;
      i >= pSelection.length - targetStackNumber;
      i--
    ) {
      let selectionEntry = pSelection[i];
      if (
        typeof selectionEntry !== "undefined" &&
        selectionEntry.wtObj == "wtWorldTerrain"
      ) {
        terrainsUseCounter++;
        //console.log("TESCHING");
        let sIndex = fwtEditorFindClosestPointIndex(
          selectionEntry.wtidObj.geometry.coordinates[0],
          lastClick
        );
        let terrainEntry = {
          wtid: selectionEntry.wtid,
          pointWGS: selectionEntry.wtidObj.geometry.coordinates[0][sIndex],
          pointIndex: sIndex,
          wtidObj: selectionEntry.wtidObj,
        };
        terrainsAffected.push(terrainEntry);
      }
    }
    let heightSum = 0;
    for (let i = 0; i < terrainsAffected.length; i++) {
      heightSum += terrainsAffected[i].pointWGS[2];
    }
    let newPoint = [
      lastClick.long,
      lastClick.lat,
      heightSum / terrainsUseCounter,
    ];
    state.editor.terrain.pointsStacking.terrainsUseCounter = terrainsUseCounter;
    state.editor.terrain.pointsStacking.terrainsAffected = terrainsAffected;
    state.editor.terrain.pointsStacking.newPoint = newPoint;
    //console.log(terrainsAffected);
  },

  mEditorTerrainPointStackCalculated(state) {
    let pointsStacking = state.editor.terrain.pointsStacking;
    //console.log("mEditorTerrainPointStackClosest with terrainsUseCounter " + pointsStacking.terrainsUseCounter);
    for (let i = 0; i < pointsStacking.terrainsAffected.length; i++) {
      let tEntry = pointsStacking.terrainsAffected[i];
      store.state.dataLoad.wtWorldData.wtWorldTerrain.wtWorldFeatureCollections[0].features.find(
        (terrain) => terrain.wtid === tEntry.wtid
      ).geometry.coordinates[0][tEntry.pointIndex] = pointsStacking.newPoint;
    }
  },
};

const storeEditor = {
  namespaced: false,
  modules: {},
  state: new State(),
  getters: getters,
  actions: actions,
  mutations: mutations,
};

//++++++++++++++++++++++++++++++++++ World Editor ++++++++++++++++++++++++++++++++++ //
function fwtEditorFindClosestPointIndex(
  coordinates: Array<Array<number>>,
  lastMouseClickPosition: { lat: number; long: number; height: number }
) {
  let long = lastMouseClickPosition.long;
  let lat = lastMouseClickPosition.lat;
  let distanceValues = [];
  for (let index in coordinates) {
    let coors = coordinates[index];
    let deltaLong = long - coors[0];
    let deltaLat = lat - coors[1];
    let dEntry = Math.sqrt(deltaLong * deltaLong + deltaLat * deltaLat);
    distanceValues.push(dEntry);
  }
  let closestIndex = 0;
  let closestValue = distanceValues[0];
  for (let i = 0; i < distanceValues.length; i++) {
    if (distanceValues[i] < closestValue) {
      closestIndex = i;
      closestValue = distanceValues[i];
    }
  }
  return closestIndex;
}

export default storeEditor;
