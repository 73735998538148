import Vuex from "vuex";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import store from "@/store";

class State {
  wtLocalization: any = {};
  icons = {
    location: "mdi-crosshairs-gps",
    population: "mdi-human-male-female",
    resources: "mdi-polymer",
    bug: "mdi-bug",
    tribe: "mdi-campfire",
    taskforce: "mdi-hammer-wrench",
    terrain: "mdi-terrain",
    objects: "mdi-earth",
    hammer: "mdi-hammer",
    clock: "mdi-clock",
    grass: "mdi-grass",
    wheet: "mdi-barley",
    createAction: "mdi-plus-box-multiple",
  };
}
const getters = <GetterTree<State, any>>{
  l10nTerrain: (state) => (terrainType: any) => {
    //return state.wtLocalization.wtTerrain["GFX_TEXTURE_FOREST_MIXED_1"];
    //console.log("getter-l10nTerrain(" + terrainType + ")")
    return state.wtLocalization.wtTerrain[terrainType];
  },
  l10nTerrainWtid: (state) => (wtid: number) => {
    //TODO: Dirty ressource burner - rework with WT-82
    const featuresArray =
      store.state.dataLoad.wtWorldData.wtWorldTerrain
        .wtWorldFeatureCollections[0].features;
    const selectedTerrain = featuresArray.find(
      (terrain: any) => terrain.wtid === wtid
    );
    return (
      wtid +
      ", " +
      state.wtLocalization.wtTerrain[selectedTerrain.properties.tType] +
      ", " +
      selectedTerrain.properties.name
    );
  },
  l10nMega: (state) => {
    return state.wtLocalization.wtTerrain["GFX_TEXTURE_FOREST_MIXED_1"];
  },
};

const actions = <ActionTree<State, any>>{};

const mutations = <MutationTree<State>>{};

const storel10n = {
  namespaced: false,
  modules: {},
  state: new State(),
  getters: getters,
  actions: actions,
  mutations: mutations,
};

export default storel10n;
