import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEntity from "../../entities/WTEntity";
import WGS from "../../lib/WGS";
import WTHelpers from "../../lib/WTHelpers";
import WTValue from "../../lib/WTValue/WTValue";
import TimeManager from "../../managers/TimeManager";
import WTEAbility from "../WTEAbility";
import WTEAction from "../WTEAction";
import WTEAInput from "../WTEAInput";

export default class WTEAItemExchange extends WTEAction {
  type: WT.Entity.Action;

  amount!: number;
  tEntity!: WTEntity;
  itemType!: WT.Inventory.Item;

  lootStockpileIsActive: boolean = false;
  lootStockpileDurationValue = 1 * TimeManager.UNITS.MINUTE;
  lootStockpileA1 = -1;
  readonly lootStockpileWGSRange = 0.0005;
  lootStockpileWTValue!: WTValue;
  lootStockpileTargetValue = 1;

  constructor(ability: WTEAbility, input?: WTEAInput) {
    super(ability, input);
    this.type = WT.Entity.Action.BASIC_ITEM_EXCHANGE;
  }

  init(input: WTEAInput): WTEAction {
    this.superInit();

    this.input = input;
    this.tEntity = this.input.target.tEntity;
    this.amount = this.input.targetAmount;
    this.itemType = this.input.itemType;

    //console.warn(`WTEAItemExchange.init() + ${this.toString()}`);
    return this;
  }

  initPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  listPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  checkPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    this.preCheckPassed = false;
    if (input) this.init(input);

    let failedConds: Array<WT.Interaction.PreCondtionType> = [];
    // do additional pre condition checks here
    if (this.amount >= this.tEntity.inventory.getSize()) {
      failedConds.push(WT.Interaction.PreCondtionType.INVENTORY_FULL);
    }
    WGS.checkMaxDistance(
      failedConds,
      this.entity,
      this.tEntity,
      this.lootStockpileWGSRange
    );
    WTHelpers.checkMatchingOwner(failedConds, this.entity, this.tEntity);

    this.preCheckPassed = WTHelpers.checkPreConditions(failedConds);
    return failedConds;
  }

  start(): boolean {
    this.superStart();
    if (!this.started) {
      return false;
    }

    this.durationRemaining = WTEntity.Attributes.ItemExchange.DURATION_BASE;
    return true;
  }

  update(tStamp: number): WTEAction {
    this.superUpdate();
    this.durationRemaining -= this.updatedTimeDelta;

    if (this.durationRemaining <= 0) {
      let sourceItem = this.entity.inventory.getOrCreateItem(this.itemType);
      let exchangeAmount = sourceItem.amount;

      this.entity.inventory.addItemAmount(this.itemType, -exchangeAmount);
      this.tEntity.inventory.addItemAmount(this.itemType, exchangeAmount);
      // console.warn(
      //   `Items (${this.itemType}) traded: ${exchangeAmount} leftOver: ${sourceItem.amount} ) now: `
      // );
      this.finish();
    }
    return this;
  }

  updateAttributes(input: WTEAInput): WTEAction {
    return this;
  }

  isDoneIn(): number {
    return this.durationRemaining;
  }

  finish(): boolean {
    this.superFinish();
    return this.finished;
  }

  cancel(): boolean {
    this.superCancel();
    return this.canceled;
  }
}
