

















































































































































import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import Vue from "vue";

export default Vue.extend({
  name: "EditorToolsPanel",
  data: () => ({
    tCreation: {
      polyDrawSelect: [
        {
          icon: "mdi-chart-timeline-variant",
          fCall: "LineString",
          tooltip: "Draw Lines",
        },
        {
          icon: "mdi-vector-polygon",
          fCall: "Polygon",
          tooltip: "Draw Polygons",
        },
      ],
      tcSelection: 0,
      tcItems: [
        {
          index: 0,
          text: "Forest Mixed",
          type: "GFX_TEXTURE_FOREST_MIXED_1",
          callback: () => {
            this.terrainCreateFunction();
          },
        },
        {
          index: 1,
          text: "Forest Coniferous",
          type: "GFX_TEXTURE_FOREST_CONIFEROUS_1",
          callback: () => {
            this.terrainCreateFunction();
          },
        },
        {
          index: 2,
          text: "Forest Leafs",
          type: "GFX_TEXTURE_FOREST_LEAVES_1",
          callback: () => {
            this.terrainCreateFunction();
          },
        },
        {
          index: 3,
          text: "Grasland",
          type: "GFX_TEXTURE_GRASLAND_1",
          callback: () => {
            this.terrainCreateFunction();
          },
        },
        {
          index: 4,
          text: "Steppe",
          type: "GFX_TEXTURE_STEPPLAND_1",
          callback: () => {
            this.terrainCreateFunction();
          },
        },
        {
          index: 5,
          text: "Sea",
          type: "GFX_TEXTURE_SEA_1",
          callback: () => {
            this.terrainCreateFunction();
          },
        },
        {
          index: 6,
          text: "Mountains",
          type: "GFX_TEXTURE_MOUNTAINS_1",
          callback: () => {
            this.terrainCreateFunction();
          },
        },
      ],
    },

    oCreation: {
      selectionCurrent: 0,
      selectionItems: [
        {
          aIndex: 0,
          text: "Coniferous tree",
          gType: "GFX_TREE_C1",
          url: "gfx/treeC1_h5m.gltf",
          callback: () => {
            this.objectCreateFunction();
          },
        },
        {
          aIndex: 1,
          text: "Deciduous tree",
          gType: "GFX_TREE_L1",
          url: "gfx/treeL1_h4m.gltf",
          callback: () => {
            this.objectCreateFunction();
          },
        },
        {
          aIndex: 2,
          text: "Bush",
          gType: "GFX_BUSH_1",
          url: "gfx/bush1_wl50cm.gltf",
          callback: () => {
            this.objectCreateFunction();
          },
        },
        {
          aIndex: 3,
          text: "Stone",
          gType: "GFX_STONE_1",
          url: "gfx/stone1_lw1m.gltf",
          callback: () => {
            this.objectCreateFunction();
          },
        },
        {
          aIndex: 4,
          text: "Cursor",
          gType: "GFX_CURSOR_360DEG_1S",
          url: "gfx/gfx/icocursor_6m_360deg_48f.gltf",
          callback: () => {
            this.objectCreateFunction();
          },
        },
        {
          aIndex: 5,
          text: "Fishing spot",
          gType: "GFX_FISHING_SPOT_1",
          url: "gfx/gfx/icocursor_6m_360deg_48f.gltf",
          callback: () => {
            this.objectCreateFunction();
          },
        },
        {
          aIndex: 6,
          text: "Hunting grounds",
          gType: "GFX_HUNTING_GROUNDS_1",
          url: "gfx/gfx/icocursor_6m_360deg_48f.gltf",
          callback: () => {
            this.objectCreateFunction();
          },
        },
        {
          aIndex: 7,
          text: "Ressource stockpile",
          gType: "GFX_STOCKPILE_1",
          url: "gfx/gfx/icocursor_6m_360deg_48f.gltf",
          callback: () => {
            this.objectCreateFunction();
          },
        },
      ],
    },

    target: {
      long: 11.0,
      lat: 48,
      height: 1500,
    },

    onClickSetting: {
      onClick: false,
      gType: "GFX_TREE_C1",
    },

    buttonsState: {
      polyDrawActive: false,
      polyDrawSelection: 1,
    },

    alertSuccess: {
      isActive: false,
      timeout: 1000,
      message: "failomatik",
    },

    alertFail: {
      isActive: false,
      timeout: 1000,
      message: "failomatik",
    },
  }),
  computed: {
    ...mapState({
      stateInteractionLast: (state) => state.interaction.selection.last,
      stateObjectsAdd: (state) => state.interaction.objectsAdd,
      stateLastClick: (state) => state.cesium.cursor.lastMouseClickPosition,
      stateWorldTerrainObjectsTypes: (state) =>
        state.gfx.data.gWorldTerrainObjectTypes,
    }),
    ...mapGetters([]),
  },
  methods: {
    ...mapActions(["aCsInitRefresh", "aInteractionObjectAdd"]),
    ...mapMutations([
      "mCsCameraFlyToTarget",
      "mCsPolyDrawChangeMode",
      "mCsPolyDrawStateUpdate",
      "mCsPolyDrawAddTerrain",
      "mEditorRemoveSelectedTerrain",
    ]),
    cameraFlyToTargetHelper: function() {
      this.$store.commit("mCsCameraFlyToTarget", this.target);
    },
    cesiumRefreshHelper: function() {
      if (this.buttonsState.polyDrawActive) {
        this.buttonsState.polyDrawActive = false;
        this.polyDrawInitHelper();
      }
      this.onClickSetting.onClick = false;
      this.$store.dispatch("aCsInitRefresh");
    },
    polyDrawShortcut: function() {
      this.buttonsState.polyDrawActive = !this.buttonsState.polyDrawActive;
      this.polyDrawInitHelper();
    },
    polyDrawInitHelper: function() {
      this.$store.commit(
        "mCsPolyDrawStateUpdate",
        this.buttonsState.polyDrawActive
      );
    },
    terrainCreateFunction: function() {
      let terrainType = this.tCreation.tcItems[this.tCreation.tcSelection].type;
      this.alertSuccess.isActive = true;
      this.alertSuccess.message = "Terrain " + terrainType + " added.";
      this.mCsPolyDrawAddTerrain(terrainType);
    },

    objectOnClickShortcut: function() {
      this.onClickSetting.onClick = !this.onClickSetting.onClick;
      this.objectAddUpdateSettings();
    },
    objectAddUpdateSettings: function() {
      this.onClickSetting.gType = this.oCreation.selectionItems[
        this.oCreation.selectionCurrent
      ].gType;
      this.$store.commit("mInteractionObjectsAddSettings", this.onClickSetting);
    },
    objectCreateFunction: function() {
      if (
        this.stateInteractionLast.wtid === -1 &&
        this.stateInteractionLast.tType !== "wtWorldTerrain"
      ) {
        this.alertFail.isActive = true;
        this.alertFail.message = "No terrain selected.";
        return;
      }
      let objectType = this.oCreation.selectionItems[
        this.oCreation.selectionCurrent
      ].gType;
      this.alertSuccess.isActive = true;
      this.alertSuccess.message =
        "wtid:" +
        this.stateInteractionLast.wtid +
        " - Object " +
        objectType +
        " added at (" +
        this.stateLastClick.long +
        ", " +
        this.stateLastClick.lat +
        ")";
      let objectInfo = {
        gType: objectType,
      };
      this.aInteractionObjectAdd(objectInfo);
    },
    templateFunction: function(event) {
      console.log("template breakpoint " + event);
    },
  },
  mounted() {
    this.onClickSetting.onClick = this.stateObjectsAdd.onClick;
    this.$store.commit("mCsPolyDrawStateUpdate", false);
  },
});
