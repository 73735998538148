import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEAbility from "../../WTEAbility";
import WTEAction from "../../WTEAction";
import WTEAInput from "../../WTEAInput";
import WTHelpers from "../../../lib/WTHelpers";
import WTVLinear from "@/ts/wt/lib/WTValue/WTVLinear";
import Population from "@/ts/wt/entities/Population/Population";
import Tribe from "@/ts/wt/entities/Tribe";
import TimeManager from "@/ts/wt/managers/TimeManager";
import WTValue from "@/ts/wt/lib/WTValue/WTValue";
import Inventory from "@/ts/wt/Inventory/Inventory";
import TaskForce from "@/ts/wt/entities/TaskForce/TaskForce";
import Hunters from "@/ts/wt/entities/TaskForce/Hunters";
import Gatherers from "@/ts/wt/entities/TaskForce/Gatherers";
import Fishermen from "@/ts/wt/entities/TaskForce/Fishermen";
import Scouts from "@/ts/wt/entities/TaskForce/Scouts";

export default class WTEACreateTaskForce extends WTEAction {
  type: WT.Entity.Action;
  static readonly WGS_RANGE = 0.0005;

  population!: Population;
  tribe!: Tribe;
  inventory!: Inventory;

  createTaskForceDurationValue = 0.25 * TimeManager.UNITS.HOUR;
  createTaskForceA1 = -1;
  createTaskForceWTValue!: WTValue;
  createTaskForceTargetValue = 1;
  taskForce!: TaskForce;

  constructor(ability: WTEAbility, input?: WTEAInput) {
    super(ability, input);
    this.type = WT.Entity.Action.TRIBE_CREATE_TASKFORCE;
  }

  // ------------------------ START action functions ------------------------ \\
  init(input: WTEAInput): WTEAction {
    // prevent loop because it is not required so often...
    this.input.loopAction = false;
    this.input.loopable = false;
    this.loopAction = false;
    this.superInit();
    // assigne potential new input in case of re-init call
    this.input = input;
    this.tribe = this.entity as Tribe;
    this.population = this.entity.population;
    this.inventory = this.entity.inventory;

    this.input.populationSplitAmount = 5;
    this.input.populationSplitType = WT.Population.SplitType.ADULTS_CHILDREN;
    if (this.entity.population.size() <= 6) {
      console.warn(
        "Create Taskforce canceled because popluation.size() = " +
          this.entity.population.size()
      );
      this.cancel();
    }

    if (
      this.input.taskForceType &&
      this.input.populationSplitType &&
      this.input.populationSplitAmount
    ) {
      return this;
    } else {
      throw new Error(
        `Input not valid(type: ${input.taskForceType}, popSplit: ${input.populationSplitType}, popSplitAmount: ${input.populationSplitAmount})`
      );
    }
  }

  initPreConditions(): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  listPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  checkPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    let failedConds: Array<WT.Interaction.PreCondtionType> = [];
    // do additional pre condition checks here
    let sAmount = this.input.populationSplitAmount;
    if (input && input.populationSplitAmount)
      sAmount = input.populationSplitAmount;
    if (this.population.size() <= sAmount)
      failedConds.push(WT.Interaction.PreCondtionType.POPULATION_TO_LOW);
    return failedConds;
  }

  start(): boolean {
    let preCondditions = this.checkPreConditions();
    if (!WTHelpers.checkPreConditions(preCondditions)) {
      WTHelpers.preConditionsToLog(preCondditions);
      return false;
    } else {
      this.updateAttributes(this.input);
      this.createTaskForceWTValue = new WTVLinear(
        this.tm.now(),
        this.createTaskForceA1,
        0
      );
      this.timeBoundariesSet(this.isDoneIn());
      console.log("aCreateTaskForceStart() - " + this.entity.toString());
      return true;
    }
  }

  update(tStamp: number): WTEAction {
    let tfType = this.input.taskForceType;
    let sType = this.input.populationSplitType;
    let sAmount = this.input.populationSplitAmount;
    let taskForce: TaskForce;
    switch (tfType) {
      case WT.Entity.Type.HUNTERS:
        taskForce = new Hunters(this.entity.wt);
        break;
      case WT.Entity.Type.GATHERERS:
        taskForce = new Gatherers(this.entity.wt);
        break;
      case WT.Entity.Type.FISHERMEN:
        taskForce = new Fishermen(this.entity.wt);
        break;
      case WT.Entity.Type.SCOUTS:
        taskForce = new Scouts(this.entity.wt);
        break;
      default:
        throw new Error("TaskForceType creation case not implemented!");
    }
    taskForce.title =
      this.input.taskForceType + " (Split from " + this.entity.title + ")";
    taskForce.population = this.population.split(sType, sAmount);
    taskForce.setWGS(this.entity.wgs);
    taskForce.tribe = this.tribe;
    this.population.logw("Reduced Tribe");
    console.log(taskForce);
    taskForce.population.logw("TaskForce population" + taskForce.toString());
    this.taskForce = taskForce;
    this.entity.wt.em.add(taskForce);
    this.finish();

    return this;
  }

  updateAttributes(input: WTEAInput): WTEAction {
    return this;
  }

  isDoneIn(): number {
    let t = this.tm.now();
    this.update(t);
    // let rt = this.createTaskForceWTValue.calculateTimeUntil(
    //   this.createTaskForceTargetValue,
    //   t
    // );
    // console.log(
    //   "aCreateTaskForceDoneIn: " + TimeManager.toHours(rt) + " hours"
    // );
    return 0;
  }

  finish(): boolean {
    this.superFinish();
    console.warn(
      "Tribe.createTaskForce().finish(), wtid:" + this.entity.toString()
    );
    return this.finished;
  }

  cancel(): boolean {
    this.superCancel();
    throw new Error("Method not implemented.");
    return this.canceled;
  }

  // ---------------------------- action helpers ---------------------------- \\

  // ------------------------------ wt helpers ------------------------------ \\
}
