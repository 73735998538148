import WTEntity from "./WTEntity";
import Worldtrix from "../Worldtrix";
import TerrainObjects from "../data/TerrainObjects";
import WTEAbility from "../interaction/WTEAbility";

export default class Terrain extends WTEntity {
  geoJsonFeature: any;
  terrainObjects: TerrainObjects;

  //terrain specific data
  tType: string;

  constructor(wt: Worldtrix, geoJsonFeature: any) {
    super(wt, geoJsonFeature.properties);
    this.type = this.constructor.name;
    this.geoJsonFeature = geoJsonFeature;
    let propData: any = geoJsonFeature.properties;
    this.tType = propData.tType;
    this.gModel = this.tType;
    wt.wm.terrain.push(this);

    this.terrainObjects = new TerrainObjects(this.wtid);
    wt.wm.terrainObjects.push(this.terrainObjects);
  }

  initAbilities(): WTEntity {
    return this;
  }

  getAbilities(): WTEAbility[] {
    return this.abilities;
  }

  addTerrainObjectsJsonData(data: any) {
    this.terrainObjects.addJsonData(data);
  }

  setTerrainInventory() {
    throw new Error("Not implemented yet!");
  }

  wgsContains(wgs: number[]): boolean {
    //TODO - implement lazy check agains polygons geometry
    return false;
  }

  wgsAlign(terrain: Terrain) {
    //TODO aligns boundary points
  }

  toGeoJSON(): any {
    let newFeature = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [[]],
      },
      wtid: this.wtid,
      properties: {
        wtid: this.wtid,
        name: this.title,
        tType: this.tType,
        wgsCenter: [this.wgs[0], this.wgs[1], this.wgs[2]],
      },
    };
    return newFeature;
  }

  update(timestamp: number): WTEntity {
    return this;
  }
}
