import WTEntity from "./WTEntity";
import Worldtrix from "../Worldtrix";
import * as WT from "@/ts/wt/declaration/WTEnums";
import TimeManager from "../managers/TimeManager";
import WTEAbility from "../interaction/WTEAbility";
import WTTarget from "../interaction/WTTarget";

export default abstract class WTEMoveable extends WTEntity {
  // global movement settings
  static MOVE_SPEED_DEFAULT: number = 0.005 / TimeManager.UNITS.HOUR;
  static MOVE_MIN_STOP_DISTANCE = 0.0005;

  // movement specific values for this ENTITY
  moveSpeed: number;
  movementCollection: boolean = true;

  // Terrain skill generate a random walk in a certain radius
  randomWalkWGSRange = 0.001;
  randomWalkWGSSpeedFactor = 1;
  randomWalkIsActive = false;
  randomWalkCenter = [0, 0, 0];
  moveVector: Array<number> = [0, 0, 0];
  randomWalkLogCount = 0;

  // log helpers
  static LOG_OUT_MOVEMENT_STEPS = 10;

  protected constructor(wt: Worldtrix, data?: any) {
    super(wt, data);
    this.initAbilitiesMoveable();
    this.moveSpeed = WTEMoveable.MOVE_SPEED_DEFAULT;
    let wgs = [0, 0, 0];
    if (data) {
      wgs = data.wgs;
    }
  }

  initAbilitiesMoveable(): void {
    this.addAbility(
      new WTEAbility(
        WT.Entity.Action.BASIC_MOVE,
        this,
        "Move to",
        "Move to the defined target coordinates."
      )
    );

    this.addAbility(
      new WTEAbility(
        WT.Entity.Action.BASIC_ITEM_EXCHANGE,
        this,
        "Exchange items",
        "Exchange items with another entity."
      )
    );

    // this.addAbility(
    //   // not sure if this is working as intended, but the classes should be reusable and the UI is checking / adding the preconditions for different abilities
    //   new WTEAbility(
    //     WT.Entity.Action.BASIC_LOOT_RESOURCES,
    //     this,
    //     "Loot resources",
    //     "Loot resources which are lying around ON THE GROUND."
    //   )
    // );

    this.addAbility(
      new WTEAbility(
        WT.Entity.Action.BASIC_FOOD_CONSUME,
        this,
        "Consume Food",
        "Consume as much food as possible."
      )
    );
  }

  updateMoveable(tStamp: number): WTEMoveable {
    if (this.randomWalkIsActive) {
      this.updateRandomWalk(tStamp);
    }
    return this;
  }

  // ---------------------------- random walk ---------------------------- \\

  startRandomWalk(target?: WTTarget): WTEMoveable {
    this.randomWalkIsActive = true;
    if (target) this.randomWalkCenter = target.wgs;
    else this.randomWalkCenter = [this.wgs[0], this.wgs[1], this.wgs[2]];
    return this;
  }

  stopRandomWalk(): WTEMoveable {
    this.randomWalkIsActive = false;
    return this;
  }

  updateRandomWalk(tStamp: number): WTEMoveable {
    if (this.randomWalkLogCount++ > 9) {
      this.randomWalkLogCount = 0;
      console.log(
        `${this.toString()}.updateRandomWalk(${TimeManager.toHours(tStamp)})`
      );
    }
    this.updateRandomWalkDirection();
    let mDistance =
      this.moveSpeed * this.updatedTimeDelta * this.randomWalkWGSSpeedFactor;
    let llRatio = Math.abs(this.moveVector[0]) + Math.abs(this.moveVector[1]);
    let longR = Math.abs(this.moveVector[0]) / llRatio;
    let latR = Math.abs(this.moveVector[1]) / llRatio;
    this.wgs[0] += longR * mDistance * Math.sign(this.moveVector[0]);
    this.wgs[1] += latR * mDistance * Math.sign(this.moveVector[1]);
    return this;
  }

  private updateRandomWalkDirection(): void {
    let rngJesus = Math.random();
    let longDir = Math.sign(Math.random() - 0.5) * rngJesus;
    let latDir = Math.sign(Math.random() - 0.5) * (1 - rngJesus);
    let hDir = 0;
    this.moveVector = [longDir, latDir, hDir];
  }
}
