































































import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "TerrainToolsPanel",
  data: () => ({
    stackInfo: {
      terrainsTargetCounter: 2,
    },
    response: {
      snackbarON: false,
      timeout: 750,
    },
  }),
  computed: {
    ...mapState({
      wtWorldFeatureCollections: (state) =>
        state.wtWorldData.wtWorldTerrain.wtWorldFeatureCollections,
      selection: (state) => state.interaction.selection,
      cesiumClick: (state) => state.cesium.cursor.lastMouseClickPosition,
      pointsStacking: (state) => state.editor.terrain.pointsStacking,
    }),
    ...mapGetters(["l10nTerrainWtid"]),
    composeMouseClickPos() {
      console.log("tescht");
      if (this.cesiumClick.flag == -1) {
        return "-";
      } else {
        return this.cesiumClick.long + ", " + this.cesiumClick.lat;
      }
    },
    composeTerrain1Text() {
      let terrainText = this.pointsStacking.terrain1;
      return (
        terrainText.wtid +
        ": " +
        terrainText.pointWGS[0] +
        ", " +
        terrainText.pointWGS[1]
      );
    },
    composeTerrain2Text() {
      let terrainText = this.pointsStacking.terrain2;
      return (
        terrainText.wtid +
        ": " +
        terrainText.pointWGS[0] +
        ", " +
        terrainText.pointWGS[1]
      );
    },
  },
  methods: {
    ...mapActions(["aCsCameraFlyToSelection"]),
    ...mapMutations([
      "mEditorTerrainPointStackCalculated",
      "mEditorTerrainPointStackTerrainStackInfoUpdate",
    ]),
    stackClosestPoints: function() {
      console.log("stackClosestPoints called");
      this.mEditorTerrainPointStackCalculated();
      this.response.snackbarON = true;
      //this.$store.commit("mEditorTerrainPointStackCalculated", this.target);
    },
    updateStore: function() {
      //console.log(this.terrainsUseCounter + " breakpoint");
      this.mEditorTerrainPointStackTerrainStackInfoUpdate(this.stackInfo);
    },
    templateFunction: function(event: any) {
      console.log("breakpoint " + event);
    },
  },
};
