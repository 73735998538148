import WTEntity from "../entities/WTEntity";
import TaskForce from "../entities/TaskForce/TaskForce";
import Tribe from "../entities/Tribe";
import Hunters from "../entities/TaskForce/Hunters";
import Worldtrix from "../Worldtrix";
import Gatherers from "../entities/TaskForce/Gatherers";
import Fishermen from "../entities/TaskForce/Fishermen";
import Scouts from "../entities/TaskForce/Scouts";
import WTEPlayerManager from "./WTEPlayerManager";
import WTEWorldManager from "./WTEWorldManager";
import WorldObject from "../entities/WorldObject/WorldObject";
import WTEAction from "../interaction/WTEAction";

export default class WTEntitiesManager {
  wt: Worldtrix;

  pm: WTEPlayerManager;
  wm: WTEWorldManager;

  // Master array with ALL entities, also those from WorldManager )
  entities: Array<WTEntity> = [];

  constructor(wt: Worldtrix) {
    this.wt = wt;
    console.log(this);
    this.pm = new WTEPlayerManager(this);
    this.wm = new WTEWorldManager(this);
    //this.logw(" created")
  }

  postLoadDataInit(): void {
    // update taskForce with tribe entities if this was missing
    for (let tf of this.pm.taskForces) {
      console.warn("call this: " + tf.wtid);
      if (!tf.tribe) {
        if (this.pm.tribes[0]) tf.tribe = this.pm.tribes[0];
      }
    }
  }

  updateAllEntities(): void {
    let tStamp = this.wt.tm.now();
    for (let entity of this.entities) {
      //console.log(entity.wtid + " entity.update()")
      entity.update(tStamp);
    }
  }

  add(entity: WTEntity): WTEntity {
    this.entities.push(entity);
    return entity;
  }

  createRandomWorldObject(): WTEntity {
    let wo = new WorldObject(this.wt);
    return wo;
  }

  get(wtid: number): WTEntity | undefined {
    let rValue = this.entities.find((element) => element.wtid == wtid);
    if (rValue) return rValue;
    else return undefined;
  }

  wtidNew(): number {
    //console.debug("wtidNew() end")
    let rWtid = -1;
    let existsCheck: boolean | undefined | WTEntity = true;
    let cCounter = 0; //prevent loop of death in case something goes wrong...
    while (existsCheck && cCounter < 5) {
      rWtid = Math.floor(Math.random() * 1000000000);
      existsCheck = this.get(rWtid);
      cCounter++;
      //console.debug(existsCheck)
    }
    //console.debug("wtidNew() end")
    return rWtid;
  }

  remove(wtid: number): boolean {
    let found = false;
    let index = this.entities.findIndex((entity) => entity.wtid == wtid);
    if (index !== -1) {
      found = true;
      let entity = this.entities[index];
      this.entities = this.entities.filter((e) => e.wtid !== wtid);
      if (entity instanceof Tribe) {
        this.pm.tribes = this.pm.tribes.filter((e) => e.wtid !== wtid);
      } else if (
        entity instanceof Gatherers ||
        entity instanceof Hunters ||
        entity instanceof Fishermen
      ) {
        this.pm.removeTaskForce(wtid);
        if (entity instanceof Gatherers) {
          this.pm.gatherers = this.pm.gatherers.filter((e) => e.wtid !== wtid);
        } else if (entity instanceof Hunters) {
          this.pm.hunters = this.pm.hunters.filter((e) => e.wtid !== wtid);
        } else if (entity instanceof Fishermen) {
          this.pm.fishermen = this.pm.fishermen.remove(entity);
        } else {
          throw new Error("EntitiesManager.remove() - Unkown TaskForce case");
        }
      } else if (entity instanceof WorldObject) {
        console.warn("remov-o-matik");
        this.wm.worldObjects = this.wm.worldObjects.remove(entity);
      } else {
        throw new Error("EntitiesManager.remove() - Unkown Entity Class");
      }
    }
    return found;
  }

  removeActionFromEntityQueue(action: WTEAction): boolean {
    let actions = action.entity.actions.filter(
      (ac) => ac.wtaid != action.wtaid
    );
    if (actions) {
      action.entity.actions = actions;
      return true;
    }
    return false;
  }
  logl(msg?: string): void {
    if (msg) console.log("EnitiesManager.logl(): " + msg);
    else console.log("EnitiesManager.logl()");
  }

  logw(msg?: string): void {
    if (msg) console.info("EnitiesManager.logw(): " + msg);
    else console.info("EnitiesManager.logw()");
    console.info("----------------------------------");
    let count = 0;
    for (let item of this.entities) {
      item.logWarn();
      count++;
    }
    console.info("----------------------------------");
    console.info(`count: ${count} entities`);
    console.info("----------------------------------");
    console.info(this);
    console.info("-------------------------------END-");
  }
}
