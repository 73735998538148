import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEAbility from "../../WTEAbility";
import WTEAction from "../../WTEAction";
import WTEAInput from "../../WTEAInput";
import WTHelpers from "../../../lib/WTHelpers";
import TaskForce from "@/ts/wt/entities/TaskForce/TaskForce";
import Inventory from "@/ts/wt/Inventory/Inventory";
import WTRandom from "@/ts/wt/lib/WTRandom";
import WTEMoveable from "@/ts/wt/entities/WTEMoveable";
import WGS from "@/ts/wt/lib/WGS";
import WTValue from "@/ts/wt/lib/WTValue/WTValue";
import WTVLinear from "@/ts/wt/lib/WTValue/WTVLinear";
import TimeManager from "@/ts/wt/managers/TimeManager";

export default class WTEAExplore extends WTEAction {
  type: WT.Entity.Action;

  taskForce!: TaskForce;
  inventory!: Inventory;

  static exploreWGSRange = 0.001;
  static exploreSpeedFactor = 1.0;
  static exploreVisionRange = 0.035;
  static exploreWorldDiscoveriesPerHour = 1 / 24;

  duration = 0;
  exploreRollEvent!: WTRandom;
  exploreA1: number = 0;
  exploreValue!: WTValue;

  exploreLogCount: number = 0;

  constructor(ability: WTEAbility, input?: WTEAInput) {
    super(ability, input);
    this.type = WT.Entity.Action.BASIC_MOVE;
  }

  // ------------------------ START action functions ------------------------ \\
  init(input: WTEAInput): WTEAction {
    this.superInit();
    // assigne potential new input in case of re-init call
    this.input = input;
    this.taskForce = this.input.entity as TaskForce;
    this.inventory = this.input.entity.inventory;
    if (!this.input.duration) throw new Error("Method not implemented.");
    this.duration = this.input.duration;
    return this;
  }

  initPreConditions(): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  listPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  checkPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    let failedConds: Array<WT.Interaction.PreCondtionType> = [];
    return failedConds;
  }

  start(): boolean {
    let preCondditions = this.checkPreConditions();
    if (!WTHelpers.checkPreConditions(preCondditions)) {
      WTHelpers.preConditionsToLog(preCondditions);
      return false;
    } else {
      this.exploreA1 = 1 / this.duration;
      this.updateAttributes(this.input);
      this.taskForce.startRandomWalk();
      this.exploreValue = new WTVLinear(this.tm.now(), this.exploreA1, 0);
      this.timeBoundariesSet(this.isDoneIn());
      this.exploreRollEvent = new WTRandom(
        WTEAExplore.exploreWorldDiscoveriesPerHour,
        this.tm.now(),
        false
      );
      console.log("WTEAExploreStart()");
      return true;
    }
  }

  update(tStamp: number): WTEAction {
    let target = this.input.target;
    let failedConds: Array<WT.Interaction.PreCondtionType> = [];
    WGS.checkMaxDistance(
      failedConds,
      this.entity,
      target,
      WTEAExplore.exploreWGSRange
    );
    if (WTHelpers.checkPreConditions(failedConds)) {
      this.finish();
    } else {
      // other updates
      if (this.exploreRollEvent.roll(tStamp)) {
        console.warn("World object found by Scouts");
        let wo = this.entity.wt.em.createRandomWorldObject();
        let wgsRoll = (Math.random() + 0.5) / 1.5;
        let woWGS = [
          this.entity.wgs[0] + wgsRoll * WTEAExplore.exploreVisionRange,
          this.entity.wgs[1] + wgsRoll * WTEAExplore.exploreVisionRange,
          0,
        ];
        wo.setWGS(woWGS);
      }
      if (this.exploreLogCount++ >= 10) {
        this.exploreLogCount = 0;
        this.isDoneIn();
      }
      if (this.isDoneIn() <= 0) {
        this.finish();
      }
    }
    return this;
  }

  updateAttributes(input: WTEAInput): WTEAction {
    this.taskForce.moveSpeed =
      WTEMoveable.MOVE_SPEED_DEFAULT * WTEAExplore.exploreSpeedFactor;

    return this;
  }

  isDoneIn(): number {
    let t = this.tm.now();
    let rt = this.exploreValue.calculateTimeUntil(1, t);
    console.log("aPackUpDoneIn: " + TimeManager.toHours(rt) + " hours");
    return rt;
  }

  finish(): boolean {
    this.taskForce.stopRandomWalk();
    this.taskForce.moveSpeed = WTEMoveable.MOVE_SPEED_DEFAULT;
    this.superFinish();
    return this.finished;
  }

  cancel(): boolean {
    this.superCancel();
    return this.canceled;
  }

  // ---------------------------- action helpers ---------------------------- \\

  // ------------------------------ wt helpers ------------------------------ \\
}
