export namespace Population {
  export const enum SplitType {
    FULL = 0,
    ADULTS,
    ADULTS_MALES,
    ADULTS_FEMALES,
    ADULTS_CHILDREN,
    CHILDREN,
  }

  export const enum FoodLevel {
    // WARNING: Food-Levels must be in increasing order for Population.checkMinFoodLevel() to work
    HUNGRY = "Hungry",
    SATURATED = "Saturated",
    WELL_FEED = "Well feed",
  }
}

export namespace WorldObject {
  export const enum Type {
    ANIMAL_DEN = "Animal den",
    FISH_SWARM = "Fish swarm",
    STOCKPILE = "Stockpile",
    CAVES = "Caves",
    LEDGE = "Ledge",
    ABANDONED_SHELTERS = "Abandoned Shelters",
  }
}

export namespace Tribe {
  export const enum State {
    WANDERING_TRIBE = 0,
    SETTELED_TRIBE,
  }
}

export namespace Human {
  export const enum AgeState {
    BABY = 0,
    CHILD,
    ADULT,
  }

  export const enum Sex {
    MALE = 0,
    FEMALE = 1,
  }

  export const enum PregnancyState {
    INFERTIL = 0,
    NO,
    YES,
    BIRTH_RECENTLY,
    BIRTH,
    MISCARRIGE,
    UNDER_AGE,
  }
}

export namespace Target {
  export const enum Type {
    WGS = 0,
    ENTITY_FIX,
    ENTITY_FOLLOW,
  }
}

export namespace Entity {
  export const enum State {
    MOVING = 0,
    ACTION,
  }

  export const enum Type {
    // HINT: Make sure to use classe names to ensure compatiblity with this.constructor.name
    TRIBES = "Tribe",
    SCOUTS = "Scouts",
    HUNTERS = "Hunters",
    GATHERERS = "Gatherers",
    FISHERMEN = "Fishermen",
    WORLD_OBJECT = "WorldObject",
    TERRAIN = "Terrain",
  }

  export const enum Action {
    // TODO: Change to string to improve debugging
    BASIC_MOVE = 0,
    BASIC_ITEM_EXCHANGE,
    BASIC_LOOT_RESOURCES,
    BASIC_FOOD_CONSUME,
    TRIBE_BUILD_SHELTERS,
    TRIBE_CREATE_TASKFORCE,
    TRIBE_GROW_TRIBE,
    TRIBE_LEAVE_SETTLEMENT,
    TRIBE_SETTLE_DOWN,
    TRIBE_SPLIT_TRIBE,

    TASKFORCE_JOIN_TRIBE,
    TASKFORCE_EXPLORE,
    TASKFORCE_TRAVEL,
    TASKFORCE_HUNT_WILDLIFE,
    TASKFORCE_HUNT_WILDLIFE_SUB,
    TASKFORCE_RAID_ANIMAL_DEN,
    TASKFORCE_GATHER,
    TASKFORCE_GATHER_SUB,
    TASKFORCE_FISH,

    OBJECT_ACTION,
    NULL,
  }
}

//---------------------------------Origins-Interaction------------------------------------//
export namespace Interaction {
  export const enum PreCondtionType {
    WTEA_INPUT_REQUIRED = "WTEAInput required for pre conditions evaluation",
    WGS_DISTANCE = "Too far away from target",
    TOOL_MISSING = "Tool missing",
    INVENTORY_FULL = "Inventory is full",
    RESSOURCE_NOT_EXISTS = "Ressource type does not exit in target",
    RESSOURCE_TO_LOW = "Not enough resources to execute actions",
    FOOD_LEVEL_TO_LOW = "Saturation requirements not fullfilled",
    ENTITY_STATE_WRONG = "Entity has wrong state",
    OWNERS_NOT_MATCHING = "Owner of interacting entities does not match",
    POPULATION_TO_LOW = "Not enough population to perform the action",
    WORLDOBJECT_TYPE_WRONG = "WorldObject type not matching",
  }

  export const enum SelectionType {
    NONE = "None",
    ENTITY = "WT-Entity",
  }
}

//-------------------------------------Origins-ITEMS---------------------------------------//

export namespace Inventory {
  export const enum Item {
    WATER = "Water",
    WATER_FRESH = "Water (fresh)",
    WATER_DIRTY = "Water (dirty)",
    NUTS = "Nuts",
    BERRIES = "Berries",
    ANIMAL = "Animals",
    FISH = "Fish",
    FISH_TRAP = "Fish trap",
    SPEAR_WOOD = "Spear (Wood)",
    KNIFE_STONE = "Knife (Stone)",
    KNIFE_BONE = "Knife (Bone)",
    BASKET = "Basket",
    MEAT = "Meat (Animal)",
    FUR = "Fur (Animal)",
    BONES = "Bones (Animal)",
    BRANCHES_WOOD = "Branches",
    LEAVES = "Leaves",
    GRASS_LONG = "Grass (Long)",
  } // Don't forget to also add new items in the Item Mapping Table

  export const enum Type {
    FOOD = "Food",
    FOOD_SPOILED = "Food (spoiled)",
    WATER = "Water",
    MATERIAL = "Material",
    TOOL = "Tool",
  }

  export const enum Store {
    GIVE = "Give",
    SPLIT_EVEN = "Split even",
    SPLIT_SCALED = "",
  }

  export const enum Take {
    HALF = "Take half",
  }
}
