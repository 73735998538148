import TimeManager from "@/ts/wt/managers/TimeManager";
import Worldtrix from "../../Worldtrix";
import * as WT from "@/ts/wt/declaration/WTEnums";

export default class Human {
  wt: Worldtrix;

  age: number;
  ageState: WT.Human.AgeState;
  sex: number = WT.Human.Sex.MALE;
  health: number = 1;
  childrenCount: number = 0;

  pregnancyState: WT.Human.PregnancyState;
  pregnancyDuration: number = 0;

  foodConsumptionRate = 1;
  foodSaturation: number = 0.35;

  public static readonly Attributes = {
    FoodSaturationMaxFactor: 1.5,
    FoodSatutrationMaxEfficency: 2 / 3, // this value reduced the food consuem efficiency above saturation of 1
    FoodSaturation: {
      BABY: 0.35,
      CHILD: 0.7,
      ADULT: 1,
    },
    FoodConsumptionRate: {
      // food consumption rates are the default level staturation
      BABY: 0.35 / TimeManager.UNITS.DAY,
      CHILD: 0.7 / TimeManager.UNITS.DAY,
      ADULT: 1 / TimeManager.UNITS.DAY,
    },
    AgeMax: {
      BABY: 4,
      CHILD: 14,
      ADULT: 60,
    },
    AgeStateDurations: {
      BABY: 4,
      CHILDHOOD: 14 - 4,
      ADULTHOOD: 60 - 14,
    },
    Female: {
      FERTILE_MIN_AGE: 15,
      FERTILE_MAX_AGE: 40,
      PREGNANCY_DURATION: 9 * TimeManager.UNITS.MONTH,
      PREGNANCY_BREAK: 6 * TimeManager.UNITS.MONTH,
      FOOD_PREGNANCY_CONSUMPTION_FACTOR: 1.3,
      PREGNANCY_MISCARRIAGE_CHANCE: 0.000005,
      PREGNANCY_CHANCE: 0.0003,
      FOOD_BOOST_FACTOR: 1.25,
    },
    InventorySize: {
      BABY: 0,
      CHILD: 6,
      ADULT: 12,
    },
  };

  constructor(wt: Worldtrix, age: number, sex?: WT.Human.Sex, health?: number) {
    this.age = age;
    if (typeof sex !== "undefined") this.sex = sex;
    else {
      let sexRnd = Math.round(Math.random());
      this.sex = sexRnd;
    }
    if (typeof health !== "undefined") this.health = health;
    this.ageState = this.ageStateCal();
    switch (this.ageState) {
      case WT.Human.AgeState.BABY:
        this.foodSaturation = Human.Attributes.FoodSaturation.BABY;
        break;
      case WT.Human.AgeState.CHILD:
        this.foodSaturation = Human.Attributes.FoodSaturation.CHILD;
        break;
      case WT.Human.AgeState.ADULT:
        this.foodSaturation = Human.Attributes.FoodSaturation.ADULT;
        break;
    }
    this.wt = wt;
    this.pregnancyState = this.pregnancyStateInit();
    this.foodConsumptionRateCal();
    //this.clog("constructed")
  }

  pregnancyStateInit(): WT.Human.PregnancyState {
    if (
      this.sex === WT.Human.Sex.FEMALE &&
      this.age <= Human.Attributes.Female.FERTILE_MAX_AGE
    ) {
      this.pregnancyState = WT.Human.PregnancyState.UNDER_AGE;
      return this.pregnancyState;
    } else this.pregnancyState = WT.Human.PregnancyState.INFERTIL;
    return this.pregnancyState;
  }

  update(updateDelta: number): Human {
    this.age += updateDelta / TimeManager.UNITS.YEAR;
    this.ageStateCal();
    this.foodSaturation -= updateDelta * this.foodConsumptionRateCal();
    //if (this.foodSaturation <= -1)
    //console.warn("Hungry...")
    return this;
  }

  foodEatTillSaturation(foodAmount: number, maxSaturation?: boolean): number {
    let foodReq = this.foodForSaturation(maxSaturation);
    let leftOver = foodAmount - foodReq;
    if (leftOver < 0) {
      foodReq = foodAmount;
      leftOver = 0;
    }
    this.feedOverMax(foodReq);
    return leftOver;
  }

  feedOverMax(amount: number): void {
    let satLevel = this.foodCapacityCal();
    if (this.foodSaturation < satLevel) {
      // below normal food levels
      let intialFeed = satLevel - this.foodSaturation;
      let remainingFood = amount - intialFeed;
      if (remainingFood > 0) {
        this.foodSaturation += intialFeed;
        this.foodSaturation +=
          remainingFood * Human.Attributes.FoodSatutrationMaxEfficency;
      } else {
        this.foodSaturation += amount;
      }
    } else {
      // already above the normal saturation level
      this.foodSaturation +=
        amount * Human.Attributes.FoodSatutrationMaxEfficency;
    }
  }

  /**
   *
   * @param maxSaturation select if the humans should be feed to the MAX limit
   * @returns amount of food requried till the desired level of saturation
   */
  foodForSaturation(maxSaturation?: boolean): number {
    let foodReq = 0;
    let satLevel = this.foodCapacityCal();
    if (maxSaturation) {
      let foodMaxCapacity = this.foodCapacityMaxCal();
      if (this.foodSaturation < satLevel) {
        foodReq = satLevel - this.foodSaturation;
        foodReq +=
          foodMaxCapacity / Human.Attributes.FoodSatutrationMaxEfficency;
      } else {
        foodReq =
          (satLevel + foodMaxCapacity - this.foodSaturation) /
          Human.Attributes.FoodSatutrationMaxEfficency;
      }
    } else {
      foodReq = satLevel - this.foodSaturation;
    }
    return foodReq;
  }

  /**
   * @returns The normal food capacity for this human based on age and pregnancy state.
   */
  foodCapacityCal(): number {
    let foodSat;
    switch (this.ageState) {
      case WT.Human.AgeState.BABY:
        foodSat = Human.Attributes.FoodSaturation.BABY;
        break;
      case WT.Human.AgeState.CHILD:
        foodSat = Human.Attributes.FoodSaturation.CHILD;
        break;
      case WT.Human.AgeState.ADULT:
        foodSat = Human.Attributes.FoodSaturation.ADULT;
        if (this.pregnancyState === WT.Human.PregnancyState.YES) {
          foodSat *= Human.Attributes.Female.FOOD_PREGNANCY_CONSUMPTION_FACTOR;
        }
        break;
      default:
        throw new Error("Invalid default case for Human.foodCapacityCal()");
    }
    return foodSat;
  }

  /**
   * @returns The additional food capacity for overfeeding for this human based on age and pregnancy state.
   */
  foodCapacityMaxCal(): number {
    let foodSat;
    switch (this.ageState) {
      case WT.Human.AgeState.BABY:
        foodSat = Human.Attributes.FoodSaturation.BABY;
        break;
      case WT.Human.AgeState.CHILD:
        foodSat = Human.Attributes.FoodSaturation.CHILD;
        break;
      case WT.Human.AgeState.ADULT:
        foodSat = Human.Attributes.FoodSaturation.ADULT;
        if (this.pregnancyState === WT.Human.PregnancyState.YES) {
          foodSat *= Human.Attributes.Female.FOOD_PREGNANCY_CONSUMPTION_FACTOR;
        }
        break;
      default:
        throw new Error("Invalid default case for Human.foodCapacityMaxCal()");
    }
    let maxLevel = (Human.Attributes.FoodSaturationMaxFactor - 1) * foodSat;
    return maxLevel;
  }

  foodConsumptionRateCal(): number {
    let consumRate = 1;
    switch (
      this.ageStateCal() // check case to prevent over staturation
    ) {
      case WT.Human.AgeState.ADULT:
        consumRate = Human.Attributes.FoodConsumptionRate.ADULT;
        if (this.pregnancyState === WT.Human.PregnancyState.YES)
          consumRate *
            Human.Attributes.Female.FOOD_PREGNANCY_CONSUMPTION_FACTOR;
        break;
      case WT.Human.AgeState.CHILD:
        consumRate = Human.Attributes.FoodConsumptionRate.CHILD;
        break;
      default:
        consumRate = Human.Attributes.FoodConsumptionRate.BABY;
        break;
    }
    this.foodConsumptionRate = consumRate;
    return this.foodConsumptionRate;
  }

  pregnancyUpdate(udpateDelta: number): WT.Human.PregnancyState {
    let foodBoost =
      this.foodConsumptionRate < this.foodSaturation
        ? Human.Attributes.Female.FOOD_BOOST_FACTOR
        : 1;
    switch (this.pregnancyState) {
      case WT.Human.PregnancyState.YES:
        this.pregnancyDuration += udpateDelta;
        if (
          this.pregnancyDuration >= Human.Attributes.Female.PREGNANCY_DURATION
        ) {
          console.log("Giving Birth...");
          this.pregnancyState = WT.Human.PregnancyState.BIRTH_RECENTLY;
          this.pregnancyDuration = 0;
          this.childrenCount++;
          return WT.Human.PregnancyState.BIRTH;
        }
        if (
          udpateDelta >= 500 &&
          Math.random() >
            1 - Human.Attributes.Female.PREGNANCY_MISCARRIAGE_CHANCE / foodBoost
        ) {
          console.log("Baby miscarriage...");
          this.pregnancyDuration = 0;
          this.pregnancyState = WT.Human.PregnancyState.NO;
          return WT.Human.PregnancyState.MISCARRIGE;
        }
        break;
      case WT.Human.PregnancyState.NO:
        if (
          Math.random() >=
          1 - Human.Attributes.Female.PREGNANCY_CHANCE * foodBoost
        ) {
          console.log("Baby on the way...");
          this.pregnancyDuration = 0;
          this.pregnancyState = WT.Human.PregnancyState.YES;
        }
        if (this.age >= Human.Attributes.Female.FERTILE_MAX_AGE)
          this.pregnancyState = WT.Human.PregnancyState.INFERTIL;
        break;
      case WT.Human.PregnancyState.BIRTH:
        this.pregnancyState = WT.Human.PregnancyState.BIRTH_RECENTLY;
        break;
      case WT.Human.PregnancyState.BIRTH_RECENTLY:
        this.pregnancyDuration += udpateDelta;
        if (
          this.pregnancyDuration >=
          (Human.Attributes.Female.PREGNANCY_BREAK * (this.childrenCount + 1)) /
            foodBoost
        ) {
          this.pregnancyDuration = 0;
          this.pregnancyState = WT.Human.PregnancyState.NO;
          break;
        }
        break;
      case WT.Human.PregnancyState.UNDER_AGE:
        if (this.age >= Human.Attributes.Female.FERTILE_MIN_AGE)
          this.pregnancyState = WT.Human.PregnancyState.NO;
        break;
      case WT.Human.PregnancyState.INFERTIL:
      default:
        this.pregnancyState = WT.Human.PregnancyState.INFERTIL;
    }
    return this.pregnancyState;
  }

  /**
   * Calculates & updates the ageState based on age value and human attributes
   */
  ageStateCal(): WT.Human.AgeState {
    if (this.age >= Human.Attributes.AgeMax.CHILD)
      this.ageState = WT.Human.AgeState.ADULT;
    else if (this.age >= Human.Attributes.AgeMax.BABY)
      this.ageState = WT.Human.AgeState.CHILD;
    else this.ageState = WT.Human.AgeState.BABY;
    return this.ageState;
  }

  setChildrenCountForAge(): Human {
    if (this.age > 35) {
      this.childrenCount = 4;
    } else if (this.age > 30) {
      this.childrenCount = 3;
    } else if (this.age > 25) {
      this.childrenCount = 2;
    } else if (this.age > 20) {
      this.childrenCount = 1;
    }
    return this;
  }

  setRndAgeBaby(): Human {
    this.age = Human.calRndAgeBaby();
    this.ageState = this.ageStateCal();
    return this;
  }

  setRndAgeChild(): Human {
    this.age = Human.calRndAgeChild();
    this.ageState = this.ageStateCal();
    return this;
  }

  setRndAgeAdult(): Human {
    this.age = Human.calRndAgeAdult();
    this.ageState = this.ageStateCal();
    return this;
  }

  static calRndAgeBaby(): number {
    return Human.Attributes.AgeMax.BABY * Math.random();
  }

  static calRndAgeChild(): number {
    return (
      Human.Attributes.AgeMax.BABY +
      Math.random() * Human.Attributes.AgeStateDurations.CHILDHOOD
    );
  }

  static calRndAgeAdult(): number {
    return (
      Human.Attributes.AgeMax.CHILD +
      Math.random() * Human.Attributes.AgeStateDurations.ADULTHOOD
    );
  }

  static compareSaturation(a: Human, b: Human): number {
    if (a.foodSaturation < b.foodSaturation) {
      return -1;
    }
    if (a.foodSaturation > b.foodSaturation) {
      return 1;
    }
    return 0;
  }
  /**
   * Returns 1 if human A is older than human B and so on...
   *
   * @param a
   * @param b
   */
  static compareAge(a: Human, b: Human): number {
    if (a.age < b.age) return -1;
    else if (a.age > b.age) return 1;
    else return 0;
  }

  logw(msg?: string): Human {
    console.warn(
      `Human.clog(): Age ${this.age}, female ${this.sex}, health ${this.health}, childrenCount ${this.childrenCount} - ${msg}`
    );
    return this;
  }

  logSaturation() {
    console.log("----------------HUMAN-START-------------------");
    console.log(`Food saturation values level for age ${this.age}`);
    console.log(`saturation value:  ${this.foodSaturation}`);
    console.log(
      `sat normal need: ${this.foodForSaturation()}, rate ${
        this.foodConsumptionRate
      }`
    );
    let foodMax = this.foodForSaturation(true);
    console.log(
      `sat MAX cap:  ${foodMax *
        Human.Attributes.FoodSatutrationMaxEfficency} ->  food need: ${foodMax}`
    );
    console.log("----------------HUMAN-END-------------------");
  }
}
