import Population from "./Population/Population";
import Worldtrix from "../Worldtrix";
import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEAbility from "../interaction/WTEAbility";
import TaskForce from "./TaskForce/TaskForce";
import Hunters from "./TaskForce/Hunters";
import Gatherers from "./TaskForce/Gatherers";
import Fishermen from "./TaskForce/Fishermen";
import TimeManager from "../managers/TimeManager";
import WorldObject from "./WorldObject/WorldObject";
import WTEAction from "../interaction/WTEAction";
import WGS from "../lib/WGS";
import WTValue from "../lib/WTValue/WTValue";
import WTHelpers from "../lib/WTHelpers";
import WTTarget from "../interaction/WTTarget";
import WTEntity from "./WTEntity";
import WTVLinear from "../lib/WTValue/WTVLinear";
import Inventory from "../Inventory/Inventory";
import Scouts from "./TaskForce/Scouts";
import WTEMoveable from "./WTEMoveable";

export default class Tribe extends WTEMoveable {
  static readonly Attributes: any = {
    wanderingState: {
      movementSpeed: 0.002,
      movementCollection: true,
    },
    settledState: {
      movementSpeed: 0,
      movementCollection: false,
    },
  };

  state: WT.Tribe.State = WT.Tribe.State.WANDERING_TRIBE;
  abilitiesWandering: Array<WTEAbility> = [];
  abilitiesSettled: Array<WTEAbility> = [];

  constructor(wt: Worldtrix, tData?: any) {
    super(wt, tData);
    if (tData) {
      this.population = new Population(this, tData.population);
      this.state = tData.tribe.state;
    } else {
      this.population = new Population(this);
    }
    this.inventory.isTribe = true;
    this.inventory.getSizeMax();
    this.buildAbilitiesArrays();

    wt.em.pm.tribes.push(this);
    //this.logw("constructed")
  }

  initAbilities(): WTEntity {
    let ability;
    // this.addAbility(
    //   new WTEAbility(
    //     WT.Entity.Action.TRIBE_BUILD_SHELTERS,
    //     this,
    //     "Build shelters",
    //     "The tribe builds shelters and will settle down at this location."
    //   )
    // );
    this.addAbility(
      new WTEAbility(
        WT.Entity.Action.TRIBE_CREATE_TASKFORCE,
        this,
        "Create task force",
        "Creates a specified task force next to the tribe."
      )
    );
    this.addAbility(
      new WTEAbility(
        WT.Entity.Action.TRIBE_GROW_TRIBE,
        this,
        "Grow tribe",
        "The population will consume as much food as possible which increases the fertility. "
      )
    );
    // this.addAbility(
    //   new WTEAbility(
    //     WT.Entity.Action.TRIBE_LEAVE_SETTLEMENT,
    //     this,
    //     "Leave settlement",
    //     "The tribe will pack up all the things and will be able to move again."
    //     // TODO: [WT-171]
    //   )
    // );
    // this.addAbility(
    //   new WTEAbility(
    //     WT.Entity.Action.TRIBE_SETTLE_DOWN,
    //     this,
    //     "Settle down",
    //     "Tribe settles down into an left settlement, caves or ledge."
    //   )
    // );
    this.addAbility(
      new WTEAbility(
        WT.Entity.Action.TRIBE_SPLIT_TRIBE,
        this,
        "Split tribes",
        "Split this tribe into two new tribes. One will stay one will leave."
      )
    );
    return this;
  }

  private buildAbilitiesArrays(): void {
    for (let abi of this.abilities) {
      switch (abi.actionType) {
        case WT.Entity.Action.BASIC_MOVE:
        case WT.Entity.Action.BASIC_LOOT_RESOURCES:
        case WT.Entity.Action.TRIBE_BUILD_SHELTERS:
        case WT.Entity.Action.TRIBE_SETTLE_DOWN:
          this.abilitiesWandering.push(abi);
          break;
        case WT.Entity.Action.TRIBE_GROW_TRIBE:
        case WT.Entity.Action.TRIBE_SPLIT_TRIBE:
        case WT.Entity.Action.TRIBE_LEAVE_SETTLEMENT:
          this.abilitiesSettled.push(abi);
          break;
        case WT.Entity.Action.BASIC_FOOD_CONSUME:
        case WT.Entity.Action.TRIBE_CREATE_TASKFORCE:
          this.abilitiesWandering.push(abi);
          this.abilitiesSettled.push(abi);
          break;
        default:
          // do nothing here but ne abilities must be added to be accessable
          break;
      }
    }
  }

  getAbilities(): WTEAbility[] {
    return this.abilities;
    // if (this.state === WT.Tribe.State.WANDERING_TRIBE) {
    //   return this.abilitiesWandering;
    // } else {
    //   return this.abilitiesSettled;
    // }
  }

  update(tStamp: number): Tribe {
    this.updateEntity(tStamp);
    return this;
  }

  //----------------------------Basic Helpers -------------------------//

  //----------------------------origins-0.1.x tribe helpers-------------------------//

  changeState(tState: WT.Tribe.State) {
    this.state = tState;
    switch (tState) {
      default:
      case WT.Tribe.State.WANDERING_TRIBE:
        this.moveSpeed = Tribe.Attributes.wanderingState.movementSpeed;
        this.movementCollection =
          Tribe.Attributes.wanderingState.movementCollection;
        break;
      case WT.Tribe.State.SETTELED_TRIBE:
        this.moveSpeed = Tribe.Attributes.settledState.movementSpeed;
        this.movementCollection =
          Tribe.Attributes.settledState.movementCollection;
        break;
    }
    console.log(`Tribe.changeState(${tState}) called.`);
  }

  checkState(tState: WT.Tribe.State): Array<WT.Interaction.PreCondtionType> {
    if (this.state === tState) return [];
    return [WT.Interaction.PreCondtionType.ENTITY_STATE_WRONG];
  }
}
