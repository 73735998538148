import WTEntity from "../entities/WTEntity";
import * as WT from "@/ts/wt/declaration/WTEnums";
import Tribe from "../entities/Tribe";
import WTEMoveable from "../entities/WTEMoveable";
import WGS from "../lib/WGS";

export default class WTTarget {
  type: WT.Target.Type;

  tEntity!: WTEntity;

  wgs: Array<number> = [0, 0, 0];

  constructor(
    wgs: Array<number>,
    targetEntity?: WTEntity,
    targetType?: WT.Target.Type
  ) {
    if (wgs && targetEntity) {
      this.type = WT.Target.Type.ENTITY_FIX;
      this.wgs[0] = targetEntity.wgs[0];
      this.wgs[1] = targetEntity.wgs[1];
      this.wgs[2] = targetEntity.wgs[2];
    } else if (wgs) {
      this.type = WT.Target.Type.WGS;
      this.wgs[0] = wgs[0];
      this.wgs[1] = wgs[1];
      this.wgs[2] = wgs[2];
    } else {
      throw new Error("Undefined new WTTarget() call.");
    }
    if (targetEntity) {
      this.tEntity = targetEntity;
    }
    if (targetType) {
      this.type = targetType;
    }
  }

  updateDistance() {
    if (this.tEntity) {
      this.type = WT.Target.Type.ENTITY_FIX;
      this.wgs[0] = this.tEntity.wgs[0];
      this.wgs[1] = this.tEntity.wgs[1];
      this.wgs[2] = this.tEntity.wgs[2];
    }
  }

  getDistance(entity: WTEntity): number {
    return WGS.distance(this.wgs, entity.wgs);
  }
}
