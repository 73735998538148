import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEAbility from "../../WTEAbility";
import WTEAction from "../../WTEAction";
import WTEAInput from "../../WTEAInput";
import WTHelpers from "../../../lib/WTHelpers";
import TaskForce from "@/ts/wt/entities/TaskForce/TaskForce";
import Inventory from "@/ts/wt/Inventory/Inventory";
import TimeManager from "@/ts/wt/managers/TimeManager";
import WTValue from "@/ts/wt/lib/WTValue/WTValue";
import WGS from "@/ts/wt/lib/WGS";
import WTVLinear from "@/ts/wt/lib/WTValue/WTVLinear";

export default class WTEAGatherSub extends WTEAction {
  type: WT.Entity.Action;

  taskForce!: TaskForce;
  inventory!: Inventory;

  static gatherWGSRange = 0.0005;
  static gatherItems: Array<WT.Inventory.Item> = [WT.Inventory.Item.BERRIES];

  gatherIsActive = false;
  gatherDurationValue = (1 * 0.4) / TimeManager.UNITS.HOUR;
  gatherTargetValue = -1;
  gatherA1 = -1;
  gatherWTValue!: WTValue;

  constructor(ability?: WTEAbility, input?: WTEAInput) {
    super(ability, input);
    this.type = WT.Entity.Action.TASKFORCE_GATHER_SUB;
  }

  // ------------------------ START action functions ------------------------ \\
  init(input: WTEAInput): WTEAction {
    this.entity = input.entity;
    this.superInit();
    // assigne potential new input in case of re-init call
    this.input = input;
    this.taskForce = this.input.entity as TaskForce;
    this.inventory = this.entity.inventory;
    return this;
  }

  initPreConditions(): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  listPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  checkPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    let failedConds: Array<WT.Interaction.PreCondtionType> = [];
    // do additional pre condition checks here
    WGS.checkMaxDistance(
      failedConds,
      this.entity,
      this.input.target,
      WTEAGatherSub.gatherWGSRange
    );
    return failedConds;
  }

  start(): boolean {
    this.superStart();
    if (!this.started) {
      return false;
    }

    this.gatherTargetValue = this.inventory.getFreeSpace();
    if (this.input.targetAmount)
      this.gatherTargetValue = this.input.targetAmount;
    this.updateAttributes(this.input);
    this.gatherWTValue = new WTVLinear(this.tm.now(), this.gatherA1, 0);
    this.timeBoundariesSet(this.isDoneIn());
    this.gatherIsActive = true;
    console.log(
      "WTEAGatherSub.start() for free Space " + this.inventory.getFreeSpace()
    );
    return true;
  }

  update(tStamp: number): WTEAction {
    if (this.isDoneIn() <= 0) {
      let value = this.gatherWTValue.calculateValue(tStamp);
      for (let i = 0; i < WTEAGatherSub.gatherItems.length; i++) {
        let addValue = value;
        this.inventory.addItemAmount(WTEAGatherSub.gatherItems[i], addValue);
      }
      this.finish();
      console.log(this.inventory);
    } else {
      // console.log("WildlifeHuntinging: " + value)
    }
    return this;
  }

  updateAttributes(input: WTEAInput): WTEAction {
    this.gatherA1 = this.gatherDurationValue * this.entity.population.size();
    return this;
  }

  isDoneIn(): number {
    let t = this.tm.now();
    let rt = this.gatherWTValue.calculateTimeUntil(this.gatherTargetValue, t);
    console.log("aGatherDoneIn: " + TimeManager.toHours(rt) + " hours");
    return rt;
  }

  finish(): boolean {
    this.superFinish();
    return this.finished;
  }

  cancel(): boolean {
    this.superCancel();
    return this.canceled;
  }

  // ---------------------------- action helpers ---------------------------- \\

  // ------------------------------ wt helpers ------------------------------ \\
}
