import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEAbility from "../../WTEAbility";
import WTEAction from "../../WTEAction";
import WTEAInput from "../../WTEAInput";
import WTHelpers from "../../../lib/WTHelpers";
import TaskForce from "@/ts/wt/entities/TaskForce/TaskForce";
import Inventory from "@/ts/wt/Inventory/Inventory";
import TimeManager from "@/ts/wt/managers/TimeManager";
import WorldObject from "@/ts/wt/entities/WorldObject/WorldObject";
import WTItem from "@/ts/wt/Inventory/Item/WTItem";
import WGS from "@/ts/wt/lib/WGS";

export default class WTEAFish extends WTEAction {
  type: WT.Entity.Action;

  taskForce!: TaskForce;
  inventory!: Inventory;

  static fishPerPersonPerHour: number = 3;
  static fishPerPersonPerTick: number =
    WTEAFish.fishPerPersonPerHour / TimeManager.UNITS.HOUR;
  static fishingWGSRange: number = 0.0005;

  fishingSpeed: number = 4.0;
  fishingTarget!: WorldObject;
  fishingTargetItem!: WTItem;
  fishingTargetAmount: number = -1;
  fishingInventoryItem!: WTItem;

  constructor(ability: WTEAbility, input?: WTEAInput) {
    super(ability, input);
    this.type = WT.Entity.Action.BASIC_MOVE;
  }

  // ------------------------ START action functions ------------------------ \\
  init(input: WTEAInput): WTEAction {
    this.superInit();
    // assigne potential new input in case of re-init call
    this.input = input;
    this.taskForce = this.input.entity as TaskForce;
    this.inventory = this.input.entity.inventory;
    this.fishingTarget = this.input.tEntity as WorldObject;
    throw new Error("Method not implemented.");
  }

  initPreConditions(): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  listPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  checkPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    let failedConds: Array<WT.Interaction.PreCondtionType> = [];
    let tf = this.fishingTarget.inventory.getExistingItem(
      WT.Inventory.Item.FISH
    );
    if (this.fishingTarget.woType !== WT.WorldObject.Type.FISH_SWARM) {
      failedConds.push(WT.Interaction.PreCondtionType.WORLDOBJECT_TYPE_WRONG);
    }
    if (this.inventory.checkIsFull()) {
      failedConds.push(WT.Interaction.PreCondtionType.INVENTORY_FULL);
    }
    if (!tf || tf.amount <= 0) {
      console.warn("Target ist all out of fish");
      failedConds.push(WT.Interaction.PreCondtionType.RESSOURCE_TO_LOW);
    }
    WGS.checkMaxDistance(
      failedConds,
      this.entity,
      this.fishingTarget,
      WTEAFish.fishingWGSRange
    );
    return failedConds;
  }

  start(): boolean {
    console.log("Wh0t does the fish say: Blubb!");
    this.updateAttributes(this.input);

    if (this.input.targetAmount) {
      this.fishingTargetAmount = this.input.targetAmount;
    } else {
      this.fishingTargetAmount = this.inventory.getFreeSpace();
    }

    if (
      !WTHelpers.checkPreConditionsWARNLog(this.checkPreConditions(this.input))
    ) {
      return true;
    }

    this.fishingInventoryItem = this.inventory.getOrCreateItem(
      WT.Inventory.Item.FISH
    );
    let tf = this.fishingTarget.inventory.getExistingItem(
      WT.Inventory.Item.FISH
    );

    //cast should be no issue, because checked by precondition
    this.fishingTargetItem = tf as WTItem;
    return true;
  }

  update(tStamp: number): WTEAction {
    this.updateAttributes(this.input);
    if (this.fishingTargetItem.amount <= 0) {
      console.log("Target all out of fish - stop to fish");
      this.finish();
    } else {
      let fishDelta =
        this.fishingSpeed *
        this.updatedTimeDelta *
        WTEAFish.fishPerPersonPerTick;
      this.fishingTargetItem.amount -= fishDelta;
      this.fishingInventoryItem.amount += fishDelta;
      console.log(
        `actionFishing():  ${fishDelta} (${this.fishingSpeed *
          WTEAFish.fishPerPersonPerHour}/h) -> ${
          this.fishingInventoryItem.amount
        } / ${this.fishingTargetAmount}, target: ${
          this.fishingTargetItem.amount
        }`
      );

      if (this.fishingTargetAmount <= this.fishingInventoryItem.amount) {
        this.fishingInventoryItem.amount = this.fishingTargetAmount;
        this.finish();
      }
    }
    return this;
  }

  updateAttributes(input: WTEAInput): WTEAction {
    let popSize = this.entity.population.size();
    this.fishingSpeed = popSize;
    let spears = this.inventory.getExistingItem(WT.Inventory.Item.SPEAR_WOOD);
    if (spears)
      this.fishingSpeed +=
        0.75 * Math.min(this.entity.population.size(), spears.amount);
    return this;
  }

  isDoneIn(): number {
    this.updateAttributes(this.input);
    //console.log("No one knows how long the fish is...")
    return 42;
  }

  finish(): boolean {
    this.superFinish();
    return this.finished;
  }

  cancel(): boolean {
    this.superCancel();
    return this.canceled;
  }

  // ---------------------------- action helpers ---------------------------- \\

  // ------------------------------ wt helpers ------------------------------ \\
}
