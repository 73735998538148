import * as WT from "@/ts/wt/declaration/WTEnums";
import Worldtrix from "./Worldtrix";
import Tribe from "./entities/Tribe";
import Hunters from "./entities/TaskForce/Hunters";
import Gatherers from "./entities/TaskForce/Gatherers";
import Fishermen from "./entities/TaskForce/Fishermen";
import TerrainObjects from "@/ts/wt/data/TerrainObjects";
import Terrain from "./entities/Terrain";
import WorldObject from "./entities/WorldObject/WorldObject";
import Scouts from "./entities/TaskForce/Scouts";
import WTEntity from "./entities/WTEntity";
import WTSelect from "./interaction/WTSelect";

export default class WTJsonFactory {
  static readonly timeScale: any = {
    second: 1000,
    minute: 1000 * 60,
    hour: 1000 * 3600,
    day: 1000 * 3600 * 24,
    month: 1000 * 3600 * 24 * 30,
    year: 1000 * 3600 * 24 * 360,
  };

  static buildPlayerEntities(wt: Worldtrix, wtpObjects: any) {
    for (let data of wtpObjects) {
      switch (data.type) {
        case WT.Entity.Type.TRIBES:
          new Tribe(wt, data);
          break;
        case WT.Entity.Type.HUNTERS:
          new Hunters(wt, data);
          break;
        case WT.Entity.Type.GATHERERS:
          new Gatherers(wt, data);
          break;
        case WT.Entity.Type.FISHERMEN:
          new Fishermen(wt, data);
          break;
        case WT.Entity.Type.SCOUTS:
          new Scouts(wt, data);
          break;
        default:
          throw new Error(data.type + " - TaskForce type does not exist");
          break;
      }
    }
  }

  static buildWorldTerrain(wt: Worldtrix, wtWorldTerrain: any) {
    this.checkJsonData(
      wtWorldTerrain,
      "wtWorldTerrain",
      "wtWorldGeoJSON-v3-origins"
    );

    for (let geojsonCollection of wtWorldTerrain.wtWorldFeatureCollections) {
      for (let geojsonFeature of geojsonCollection.features) {
        new Terrain(wt, geojsonFeature);
      }
    }
    //console.log(wt.wm.terrain)
  }

  static buildWorldTerrainObjects(wt: Worldtrix, wtWorldTerrainObjects: any) {
    this.checkJsonData(
      wtWorldTerrainObjects,
      "wtWorldTerrainObjects",
      "wtWorldTerrainObjectsJSON-v4-origins"
    );

    for (let objectsData of wtWorldTerrainObjects.objectsDataArray) {
      let terrain = wt.wm.terrain.find(
        (terrain) => terrain.wtid === objectsData.twtid
      );
      if (terrain) {
        terrain.addTerrainObjectsJsonData(objectsData);
      }
    }
    //wt.wm.logw()
  }

  static buildWorldObjects(wt: Worldtrix, wtWorldObjects: any) {
    this.checkJsonData(
      wtWorldObjects,
      "wtWorldObjects",
      "wtWorldObjectsJSON-v2-origins"
    );

    for (let worldObjectData of wtWorldObjects.objects) {
      new WorldObject(wt, worldObjectData);
    }
  }

  private static checkJsonData(
    json: any,
    jsonType: string,
    codeVersion: string
  ): boolean {
    if (json.wtObj !== jsonType)
      throw new Error(`Wrong jsonType: ${json.wtObj} expected ${jsonType}`);
    let dataVersion = json.wtJsonFormat;
    if (codeVersion !== dataVersion)
      throw new Error(
        `Wrong ${json.wtObj} version: ${dataVersion} expected ${codeVersion}`
      );
    return true;
  }
}
