import * as WT from "@/ts/wt/declaration/WTEnums";
import WTEAbility from "../../WTEAbility";
import WTEAction from "../../WTEAction";
import WTEAInput from "../../WTEAInput";
import WTHelpers from "../../../lib/WTHelpers";
import TimeManager from "@/ts/wt/managers/TimeManager";
import WTValue from "@/ts/wt/lib/WTValue/WTValue";
import WGS from "@/ts/wt/lib/WGS";
import Population from "@/ts/wt/entities/Population/Population";
import Tribe from "@/ts/wt/entities/Tribe";
import WTVLinear from "@/ts/wt/lib/WTValue/WTVLinear";

export default class WTEABuildShelters extends WTEAction {
  type: WT.Entity.Action;
  static readonly WGS_RANGE = 0.0005;
  BASE_DURATION = 4 * TimeManager.UNITS.HOUR;
  RESOURCE_REQUIRMENTS = {
    WOOD: 1,
    LEAVES: 4,
  };

  population!: Population;
  tribe!: Tribe;

  buildSheltersA1 = -1;
  buildSheltersWTValue!: WTValue;

  constructor(ability: WTEAbility, input?: WTEAInput) {
    super(ability, input);
    this.type = WT.Entity.Action.TRIBE_BUILD_SHELTERS;
  }

  // ------------------------ START action functions ------------------------ \\
  init(input: WTEAInput): WTEAction {
    this.superInit();
    // assigne potential new input in case of re-init call
    this.input = input;
    this.tribe = this.entity as Tribe;
    this.population = this.entity.population;
    return this;
  }

  initPreConditions(): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  listPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    // Will be implemented in WT-170
    throw new Error("Method not implemented.");
  }

  checkPreConditions(input?: WTEAInput): WT.Interaction.PreCondtionType[] {
    let failedConds: Array<WT.Interaction.PreCondtionType> = [];
    // do additional pre condition checks here
    if (
      !WGS.checkMaxDistance(
        failedConds,
        this.entity,
        this.input.tEntity,
        WTEABuildShelters.WGS_RANGE
      )
    )
      console.warn("WGS Distance check not passed");
    failedConds = failedConds.concat(this.population.checkFoodSaturation(0));
    failedConds = failedConds.concat(
      this.tribe.checkState(WT.Tribe.State.WANDERING_TRIBE)
    );
    let people = this.population.size();
    let woodReq = this.RESOURCE_REQUIRMENTS.WOOD * people;
    let leavesReq = this.RESOURCE_REQUIRMENTS.LEAVES * people;
    if (
      !this.tribe.inventory.checkItemAmount(
        WT.Inventory.Item.BRANCHES_WOOD,
        woodReq
      ) ||
      !this.tribe.inventory.checkItemAmount(WT.Inventory.Item.LEAVES, leavesReq)
    )
      failedConds = failedConds.concat(
        WT.Interaction.PreCondtionType.RESSOURCE_TO_LOW
      );
    return failedConds;
  }

  start(): boolean {
    let preCondditions = this.checkPreConditions();
    if (!WTHelpers.checkPreConditions(preCondditions)) {
      WTHelpers.preConditionsToLog(preCondditions);
      return false;
    } else {
      this.updateAttributes(this.input);
      this.buildSheltersWTValue = new WTVLinear(
        this.tm.now(),
        this.buildSheltersA1,
        0
      );
      this.timeBoundariesSet(this.isDoneIn());
      console.log("aBuildSheltersStart()");
      return true;
    }
  }

  update(tStamp: number): WTEAction {
    if (this.isDoneIn() <= 0) {
      this.tribe.changeState(WT.Tribe.State.SETTELED_TRIBE);
      let people = this.population.size();
      let woodReq = this.RESOURCE_REQUIRMENTS.WOOD * people;
      let leavesReq = this.RESOURCE_REQUIRMENTS.LEAVES * people;
      this.tribe.inventory.addItemAmount(
        WT.Inventory.Item.BRANCHES_WOOD,
        -woodReq
      );
      this.tribe.inventory.addItemAmount(WT.Inventory.Item.LEAVES, -leavesReq);
      this.finish();
    } else {
      // other updates
    }
    return this;
  }

  updateAttributes(input: WTEAInput): WTEAction {
    this.buildSheltersA1 = 1 / this.BASE_DURATION;
    return this;
  }

  isDoneIn(): number {
    let t = this.tm.now();
    let rt = this.buildSheltersWTValue.calculateTimeUntil(1, t);
    console.log("aBuildSheltersDoneIn: " + TimeManager.toHours(rt) + " hours");
    return rt;
  }

  finish(): boolean {
    this.superFinish();
    return this.finished;
  }

  cancel(): boolean {
    this.superCancel();
    throw new Error("Method not implemented.");
    return this.canceled;
  }

  // ---------------------------- action helpers ---------------------------- \\

  // ------------------------------ wt helpers ------------------------------ \\
}
