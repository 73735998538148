

























import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default {
  name: "JsonPanel",
  data: () => ({
    jsonSelection: "wtWorldTerrain",
    jsonSelectorData: [
      {
        text: "wtWorldTerrain",
      },
      {
        text: "wtWorldTerrainObjects",
      },
      {
        text: "wtWorldObjects",
      },
      {
        text: "wtWorldRessources",
      },
      {
        text: "wtPlayerData",
      },
      {
        text: "wtGameEngineData",
      },
    ],
    stringToBinding: "storeData",
  }),
  computed: {
    ...mapState({
      storeData: (state) => state,
    }),
    jsonText() {
      console.log("jsonSelection " + this.jsonSelection);
      switch (this.jsonSelection) {
        case "wtWorldTerrain":
          return JSON.stringify(this.storeData.wtWorldData.wtWorldTerrain);
        case "wtWorldTerrainObjects":
          return JSON.stringify(
            this.storeData.wtWorldData.wtWorldTerrainObjects
          );
        case "wtWorldObjects":
          return JSON.stringify(this.storeData.wtWorldData.wtWorldObjects);
        case "wtWorldRessources":
          return JSON.stringify(this.storeData.wtWorldData.wtWorldRessources);
        case "wtPlayerData":
          return JSON.stringify(this.storeData.wtPlayerData);
        case "wtGameEngineData":
          return JSON.stringify(this.storeData.gameEngineData);
      }
    },
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    exportJSONtoText(data, filename) {
      let file = new Blob([data], { type: "json" });
      let a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename + ".json";
      document.body.appendChild(a);
      a.click();
      setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    },
    exportAllJsonHelper() {
      this.exportJSONtoText(
        JSON.stringify(this.storeData.wtWorldData.wtWorldTerrain),
        "wtWorldTerrain"
      );
      this.exportJSONtoText(
        JSON.stringify(this.storeData.wtWorldData.wtWorldTerrainObjects),
        "wtWorldTerrainObjects"
      );
      this.exportJSONtoText(
        JSON.stringify(this.storeData.wtWorldData.wtWorldObjects),
        "wtWorldObjects"
      );
      this.exportJSONtoText(
        JSON.stringify(this.storeData.wtWorldData.wtWorldRessources),
        "wtWorldRessources"
      );
      this.exportJSONtoText(
        JSON.stringify(this.storeData.wtPlayerData),
        "wtPlayerData"
      );
      this.exportJSONtoText(
        JSON.stringify(this.storeData.gameEngineData),
        "wtGameEngineData"
      );
    },
    templateFunction: function(event: any) {
      console.log("breakpoint " + event);
    },
  },
};
