import TerrainObject from './TerrainObject';

export default class TerrainObjects {
    readonly twtid: number
    gScale: number = 1.0
    objects: Array<TerrainObject> = []

    constructor(twtid: number, data?: any) {
        this.twtid = twtid
        if (data) {
            this.addJsonData(data)
        }
    }

    addJsonData(data: any) {
        if (!data.twtid) {

            throw new Error("new TerrainObjects() : twiwtObj missing")
        }
        this.gScale = data.gScale

        //fill objects
        for (let object of data.objects) {
            this.objects.push(new TerrainObject(object.gModel, object.gScale, object.wgs))
        }
        //console.log(this)
    }
} 