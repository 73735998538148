import Vuex from "vuex";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import store from "@/store";

import Cesium from "@/plugins/cesium";

type RootState = typeof store.state;
class State {
  UI = {
    showWGS: true,
    darkTheme: false,
    csCamera: {
      flyDuration: 0.5,
      targetHeight: 500,
      keyboardRotationSpeed: 0.04,
      keyboardMovementSpeed: 1 / 80.0,
    },
    csHighlightingSilhouettes: {
      hovering: {
        color: Cesium.Color.ORANGE,
        size: 3,
      },
      selection: {
        color: Cesium.Color.RED,
        size: 3,
      },
    },
    mouseMovementLagMillis: 25,
  };
  gfx = {
    fpsShow: true,
    fpsTarget: 60,
    animateWater: true,
    modelSilhouettes: {
      color: Cesium.Color.BLACK,
      size: 2,
    },
    naturalEarthResolutions: {
      world: 1,
      spectral: 0,
    },
    layers: {
      grid: {
        active: false,
      },
    },
  };
  keyboard = {
    cameraMovement: {
      moveForward: "x",
      moveBackward: "y",
      moveUp: "w",
      moveDown: "s",
      moveLeft: "a",
      moveRight: "d",
      rotateLeft: "q",
      rotateRight: "e",
    },
  };
  debug = {
    isActive: false,
  };
}

const getters = <GetterTree<State, any>>{};

const actions = <ActionTree<State, any>>{};

const mutations = <MutationTree<State>>{
  mSettingsCesiumNaturalEarthResolutionWorld(state, indexValue: number) {
    store.state.settings.gfx.naturalEarthResolutions.world = indexValue;
  },

  mSettingsCesiumNaturalEarthResolutionSpectral(state, indexValue: number) {
    store.state.settings.gfx.naturalEarthResolutions.spectral = indexValue;
  },

  mSettingsSetDebugStatus(state, newStatus: boolean) {
    state.debug.isActive = newStatus;
  },

  mSettingsSetShowWGS(state, newStatus: boolean) {
    state.UI.showWGS = newStatus;
  },

  mSettingsGfxSetAnimateWater(state, newStatus: boolean) {
    state.gfx.animateWater = newStatus;
  },

  mSettingsGfxSetFpsTarget(state, newValue: number) {
    state.gfx.fpsTarget = newValue;
  },

  mSettingsGfxSetFpsShow(state, newStatus: boolean) {
    state.gfx.fpsShow = newStatus;
  },

  mSettingsUISetDarkTheme(state, newStatus: boolean) {
    state.UI.darkTheme = newStatus;
  },
};

const storeSettings = {
  namespaced: false,
  modules: {},
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
};

export default storeSettings;
