import { ref, reactive, computed, toRefs } from "@vue/composition-api";
import store from "@/store";
import WTEntity from "@/ts/wt/entities/WTEntity";

export default function fileCompositionOne(entity: WTEntity) {
  const textComputed: any = reactive({
    debug: computed(() => entity.toStringDebug()),
    location: computed(() => entity.getWGSString()),
    inventory: computed(() => entity.inventory.toString()),
    inventoryList: computed(() => entity.inventory.toStringItemList()),
    population: computed(() => entity.population.toString()),
  });

  function flyTo(wtid: number) {
    console.log("fly to: " + wtid);
    store.dispatch("aCsCameraFlyToSelection", wtid);
  }

  return { textComputed, flyTo };
}
